<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="application_root_template">
    <application-screen></application-screen>
    <application-overlay></application-overlay>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"
    
    /**
     * Этот класс описывает кастомный элемент <application-root>.
     */
    class ApplicationRoot
        extends Templated( Component, "application_root_template" )
    {}
    // Определение элемента:
    globalThis.customElements.define( "application-root", ApplicationRoot )
</script>