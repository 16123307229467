<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_chat_template">
    <button class="chat__mobbtn">
        <template-event for-parent name="click" listener="onMobileChatButtonClick"></template-event>
        <p class="chat__mobbtn-value" data-ref-chat-messages-count>0</p>
        <picture>
            <source srcset="../assets/images-compressed/chat_mobbtn.webp" type="image/webp">
            <img src="../assets/images-compressed/chat_mobbtn.png" alt="">
        </picture>
    </button>
    <div class="chat" data-ref-chat-container>
        <div class="chat__head">
            <template-event name="click" listener="onChatButtonClick">
                <div class="chat__btn" data-ref-chat-button>Свернуть чат</div>
            </template-event>
            <div class="chat__counter" data-ref-chat-messages-count>0</div>
        </div>
        <div class="chat__body" data-ref-chat-messages>
        </div>
        <form class="chat__form"
              action="/room_chat/addMessage"
              method="POST"
              data-request-disable="initiator"
              data-request-clear
              data-request-json
              >
            <input type="hidden" data-ref-chat-room-id-input name="room_id" value="">
            <div class="chat__smiles"></div>
            <input type="text" class="chat__input" name="message" placeholder="Введите текст сообщения ...">
            <button type="submit" class="chat__submit"></button>
        </form>
    </div>
</template>
<!-- Шаблон сообщения: -->
<template id="chat_message_template">
    <div class="chat-message">
        <div class="chat-message__head">
            <div class="chat-message__name" data-ref-chat-message-author></div>
            |
            <div class="chat-message__date" data-ref-chat-message-date></div>
        </div>
        <p class="chat-message__text" data-ref-chat-message-text></p>
    </div>
</template
<!-- Модуль компонента: -->
<script type="module">
    // Использовать полифилл для плавной прокрутки:
    import smoothscroll from "smoothscroll-polyfill"
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"
    // Применить полифилл для плавной прокрутки:
    smoothscroll.polyfill()

    /**
     * Этот класс описывает компонент <room-chat>.
     */
    class RoomChat
        extends Templated( Component, "room_chat_template" )
    {
        /** @property {MediaQueryList}          Объект запроса изменения ширины окна. */
        #mediaQueryWidth1420 = globalThis.matchMedia( "(max-width: 1420px)" )
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomId", "chatMessages", "chatMessages.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени свойства:
            switch ( transaction.name ) {
                // Если изменен идентификатор комнаты:
                case "roomId":
                    // Установка содержания поля ввода:
                    this.#setRoomId( transaction.value )
                    break
                // Добавление всех сообщений:
                case "chatMessages":
                    // Скрыть текущие сообщения:
                    this.#clearChat()
                    // Для всех сообщений чата:
                    for ( const message of transaction.value.values() ) {
                        // Получить параметры сообщения:
                        const { username, messageText, date } = message
                        // Добавить сообщение чата:
                        this.#addChatMessage( username, date, messageText )
                    }
                    break
                // Добавление сообщения:
                case "chatMessages.add":
                    // Получить параметры сообщения:
                    const { username, messageText, date } = transaction.arguments[ 0 ]
                    // Добавить сообщение чата:
                    this.#addChatMessage( username, date, messageText )
                    break
            }
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Установка обработчика запроса:
            this.#mediaQueryWidth1420.addEventListener(
                "change"
              , event => this.onWindowWidthChanges( event )
            )
            // Вызвать функцию при запуске:
            this.onWindowWidthChanges( this.#mediaQueryWidth1420 )
        }
        /**
         * Этот метод выполняется при отключении шаблонизированного элемента от тела документа.
         * 
         * @returns undefined
         */
        templateDisconnectedCallback() {
            // Удалить запрос изменения ширины окна:
            this.#mediaQueryWidth1420 = undefined
        }
        /**
         * Этот метод устанавливает идентификатор текущей комнаты.
         * 
         * @param {String} id           Идентификатор (номер) комнаты.
         * @returns undefined
         */
        #setRoomId( id ) {
            // Установка содержания поля ввода:
            this.querySelector( "[data-ref-chat-room-id-input]" ).value = id
        }
        /**
         * Этот метод устанавливает количество сообщений текущего чата.
         * 
         * @param {Number} count            Количество сообщений чата.
         * @returns undefined
         */
        #setChatMessagesCount( count ) {
            // Получить элемент количества сообщений:
            const chatMessagesCountElements = this.querySelectorAll( "[data-ref-chat-messages-count]" )
            // Для всех найденных элементов:
            for ( const chatMessagesCountElement of chatMessagesCountElements ) {
                // Установить содержание узла:
                chatMessagesCountElement.innerHTML = count
            }
        }
        /**
         * Этот метод сбрасывает сообщения чата.
         * 
         * @returns undefined
         */
        #clearChat( author, date, message ) {
            // Сбросить количество сообщений:
            this.#setChatMessagesCount( 0 )
            // Получить контейнер сообщений чата:
            const messagesContainer = this.querySelector( "[data-ref-chat-messages]" )
            // Сбросить содержание контейнера:
            messagesContainer.innerHTML = ""
        }
        /**
         * Этот метод добавляет сообщение в текущий чат.
         * 
         * @param {String} author           Автор сообщения.
         * @param {String} date             Дата сообщения.
         * @param {String} message          Текст сообщения.
         * @returns undefined
         */
        #addChatMessage( author, date, message ) {
            // Получить контейнер сообщений чата:
            const messagesContainer = this.querySelector( "[data-ref-chat-messages]" )
            // Получить элемент количества сообщений:
            const chatMessagesCountElements = this.querySelectorAll( "[data-ref-chat-messages-count]" )
            // Получить объект шаблона сообщения:
            const messageTemplate = globalThis.document.getElementById( "chat_message_template" )
            // Получить копию элемента сообщения:
            const messageElement = messageTemplate.content.cloneNode( true )
            // Получить параметры сообщения:
            const messageAuthor = messageElement.querySelector( "[data-ref-chat-message-author]" )
                , messageDate   = messageElement.querySelector( "[data-ref-chat-message-date]" )
                , messageText   = messageElement.querySelector( "[data-ref-chat-message-text]" )
            // Установка содержания сообщения:
            messageAuthor.innerHTML = author
            messageDate.innerHTML   = date
            messageText.innerHTML   = message
            // Получить текущую позицию прокрутки чата:
            const maxScrollTop     = messagesContainer.scrollHeight - messagesContainer.offsetHeight
                , scrolledToBottom = Math.abs( messagesContainer.scrollTop - maxScrollTop ) < 10
            // Вставить сообщение:
            messagesContainer.appendChild( messageElement )
            // Получить значение счетчика сообщений:
            let messagesCounter = Number( chatMessagesCountElements[ 0 ].innerText.trim() )
            // Установить новое значение счетчика:
            this.#setChatMessagesCount( messagesCounter + 1 )
            // Если элемент был прокручен до низа:
            if ( scrolledToBottom == true ) {
                // Выполнить плавную прокрутку к последнему сообщению:
                this.#scrollToLastMessage()
            }
        }
        /**
         * Этот метод прокручивает контейнер к самому последнему сообщению.
         * 
         * @returns undefined
         */
        #scrollToLastMessage() {
            // Получить контейнер сообщений чата:
            const messagesContainer = this.querySelector( "[data-ref-chat-messages]" )
            // Получить максимальную высоту позиции прокрутки:
            const maxScrollTop = messagesContainer.scrollHeight - messagesContainer.offsetHeight
            // Прокрутить элемент до нужной позиции:
            messagesContainer.scrollTo( { top: maxScrollTop, behavior: "smooth" } )
        }
        /**
         * Этот метод обрабатывает событие клика на чат-кнопку для мобильных устройств.
         * 
         * @param {Object} event            Объект события.
         * @returns undefined
         */
        onMobileChatButtonClick( event ) {
            // Получить блок чата:
            const chatElement = this.querySelector( "[data-ref-chat-container]" )
            // Переключить класс скрытия чата:
            chatElement.classList.toggle( "is-hidden" )
            // Получить кнопку чата:
            const chatButtonElement = this.querySelector( "[data-ref-chat-button]" )
            // Сбросить текст кнопки:
            chatButtonElement.textContent = "Свернуть чат"
        }
        /**
         * Этот метод обрабатывает событие клика на чат-кнопку.
         * 
         * @param {Object} event            Объект события.
         * @returns undefined
         */
        onChatButtonClick( event ) {
            // Получить блок чата:
            const chatElement = this.querySelector( "[data-ref-chat-container]" )
            // Получить кнопку чата:
            const chatButtonElement = this.querySelector( "[data-ref-chat-button]" )
            // Переключить класс скрытия чата:
            chatElement.classList.toggle( "is-hidden" )
            // Обновить текст кнопки:
            chatButtonElement.textContent = chatElement.classList.contains( "is-hidden" ) 
                                          ? "Открыть чат"
                                          : "Свернуть чат"
        }
        /**
         * Этот метод обрабатывает событие изменения ширины игрового окна.
         * 
         * @param {Object} event            Объект события.
         * @returns undefined
         */
        onWindowWidthChanges( event ) {
            // Получить блок чата:
            const chatElement = this.querySelector( "[data-ref-chat-container]" )
            // Если медиа запрос выполнен:
            if ( event.matches ) {
                // Скрыть чат:
                chatElement.classList.add( "is-hidden" )
            }
            // Если медиа запрос не выполняется:
            else {
                // Показать чат:
                chatElement.classList.remove( "is-hidden" )
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-chat", RoomChat )
</script>