/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс исходящих сообщений:
import Outgoing from "../outgoing.mjs"

/**
 * Этот класс описывает сообщение авторизации сокета.
 */
export default class Authorize
    extends Outgoing
{
    /** @property {String}          Описание команды. */
    command = "authorize"
    /** @property {String}          Токен доступа. */
    token   = undefined
    /**
     * Конструктор объекта класса Authorize.
     * 
     * @param {String} token        Токен доступа.
     */
    constructor( token ) {
        super()
        // Установка значений переменных объекта:
        this.token = token
    }
}