/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать плагин "create-conical-gradient":
import "create-conical-gradient"
// Использовать общий класс компонентов:
import Component from "../component.mjs"

/**
 * Этот класс описывает компонент, имеющий анимированный таймер (враг/текущий игрок).
 */
export default class AnimatedTimer
    extends Component
{
    /** @property {Number}                  Время начала активности игрока. */
    #startupTimestamp     = undefined
    /** @property {Number}                  Активное время текущего игрока. */
    #activeTimestamp      = undefined
    /** @property {Boolean}                 Статус активности таймера. */
    #timerActive          = false
    /** @property {Number}                  Текущее значение таймера. */
    #timerAmount          = undefined
    /** @property {HTMLElement}             Канва арки. */
    #timerArcCanvas       = undefined
    /** @property {Number}                  Ширина канвы арки. */
    #timerCanvasWidth     = 0
    /** @property {Number}                  Высота канвы арки. */
    #timerCanvasHeight    = 0
    /** @property {HTMLElement}             Канва искры. */
    #timerSparkCanvas     = undefined
    /** @property {HTMLElement}             Канва партиклей. */
    #timerParticlesCanvas = undefined
    /**
     * Этот метод инициализирует анимированный таймер для компонента.
     * 
     * @param {HTMLElement} timerArcCanvas          Элемент канвы арки.
     * @param {HTMLElement} timerSparkCanvas        Элемент канвы искры.
     * @param {HTMLElement} timerParticlesCanvas    Элемент канвы партиклей.
     * @returns undefined
     */
    initializeAnimatedTimer( timerArcCanvas, timerSparkCanvas, timerParticlesCanvas ) {
        this.#timerArcCanvas       = timerArcCanvas
        this.#timerSparkCanvas     = timerSparkCanvas
        this.#timerParticlesCanvas = timerParticlesCanvas
    }
    /**
     * Этот метод конфигурирует размеры канвы анимированного таймера.
     * 
     * @param {Number} timerCanvasWidth      Ширина канвы анимированного таймера.
     * @param {Number} timerCanvasHeight     Высота канвы анимированного таймера.
     */
    resizeAnimatedTimer( timerCanvasWidth, timerCanvasHeight ) {
        this.#timerCanvasWidth  = timerCanvasWidth
        this.#timerCanvasHeight = timerCanvasHeight
    }
    /**
     * Этот метод деинициализирует анимированный таймер для компонента.
     * 
     * @returns undefined
     */
    deinitializeAnimatedTimer() {
        this.#timerArcCanvas       = undefined
        this.#timerSparkCanvas     = undefined
        this.#timerParticlesCanvas = undefined
    }
    /**
     * Этот метод запускает анимированный таймер.
     * 
     * @param {Number} activeTimeout            Текущее значение таймаута.
     * @param {Number} [startupTimestamp]       Время начала анимации.
     * @returns undefined
     */
    startAnimatedTimer( activeTimeout, startupTimestamp = new Date().getTime() ) {
        // Установить статус вывода таймера:
        this.#timerActive = true
        // Установить время текущего таймера:
        this.#timerAmount = activeTimeout
        // Установить текущую дату начала анимации:
        this.#startupTimestamp = startupTimestamp
        this.#activeTimestamp  = new Date().getTime()
        // Запуск отображения анимации:
        this.#startTimer( activeTimeout )
        this.#startSparkTimer( activeTimeout )
        this.#startParticlesAnimation( activeTimeout )
    }
    /**
     * Этот метод останавливает анимированный таймер.
     * 
     * @returns undefined
     */
    stopAnimatedTimer() {
        // Сбросить статус вывода таймера:
        this.#timerActive = false
        // Сбросить параметры таймера:
        this.#timerAmount      = undefined
        this.#startupTimestamp = undefined
        this.#activeTimestamp  = undefined
    }
    /**
     * Этот метод обрабатывает вывод партиклей таймера для игрока.
     * 
     * @param {Number} activeTimeout            Текущее значение таймаута.
     * @returns undefined
     */
    #startParticlesAnimation( activeTimeout ) {
        const refTimer = this.#timerParticlesCanvas;
        const ctx = refTimer.getContext("2d");

        const particleEveryMs = 120;
        let particles = [];
        const particlesSpeed = 1;
        const sizeChangingSpeed = -0.005;
        const particleAppearSpeed = 1;
        const opacityChangeSpeed = -0.025;
        
        ctx.canvas.width = this.#timerCanvasWidth + 30;
        ctx.canvas.height = this.#timerCanvasWidth + 30;

        let outerSparkRadius = 0;

        outerSparkRadius = ctx.canvas.width / 2 - 22;

        const timeLeftAfterPageLoaded =
            activeTimeout * 1000 -
            (this.#startupTimestamp - this.#activeTimestamp);

        // если после перезагрузки страницы activeTimeout > 0,
        // иначе таймер включился во время действия страницы
        const animationTotalMS =
            activeTimeout > 0
                ? timeLeftAfterPageLoaded
                : this.#timerAmount * 1000;

        let animationStart = 0;
        let animationProgress = 0;

        const startAngle = -Math.PI / 2;
        let currentAngle = startAngle;

        let willParticleAppear = 0;
        let timestampStartForParticles = performance.now();
        let headLightsCount = 15;
        const angleOffsetStep = Math.PI / 6 / headLightsCount;
        let currentAngleOffset = -Math.PI / 5 + Math.PI / 18;
        let currentOpacity = 0.1;
        let currentSize = 2;
        const opacityStep = (1 - 0.1) / headLightsCount;
        const sizeStep = (8 - 0.1) / headLightsCount;

        //-------------------------------------
        // Yellow lights
        particles.push({
            x: 0,
            y: 0,
            currentX: 0,
            currentY: 0,
            directionX: 0,
            directionY: 0,
            size: Math.random() * 1,
            isAlive: true,
            isPermanent: true,
            isYellowLight: true,
            color: "#fff",
            angleOffset: currentAngleOffset + 6 * angleOffsetStep,
            farFromCenter: outerSparkRadius - 4,
            opacity: 0.35 * Math.random() + 0.25,
            opacityChangeSpeed: opacityChangeSpeed,
            maxSize: 35,
            shadow: false,
            shadowBlur: 1 + 15 * Math.random(),
            sizeChangeSpeed: 0.05,
        });

        particles.push({
            x: 0,
            y: 0,
            currentX: 0,
            currentY: 0,
            directionX: 0,
            directionY: 0,
            size: Math.random() * 4,
            isAlive: true,
            isPermanent: true,
            isYellowLight: true,
            color: "#fff",
            angleOffset: currentAngleOffset + angleOffsetStep * 13,
            farFromCenter: outerSparkRadius + 10,
            opacity: 0.35 * Math.random() + 0.25,
            opacityChangeSpeed: opacityChangeSpeed,
            maxSize: 25,
            shadow: false,
            shadowBlur: 1 + 15 * Math.random(),
            sizeChangeSpeed: 0.05,
        });

        //-------------------------------------------

        while (headLightsCount > 0) {
            headLightsCount -= 1;
            particles.push({
                x: 0,
                y: 0,
                currentX: 0,
                currentY: 0,
                directionX: 0,
                directionY: 0,
                size: currentSize,
                isAlive: true,
                isPermanent: true,
                angleOffset: currentAngleOffset,
                farFromCenter: outerSparkRadius,
                opacity: currentOpacity,
                opacityChangeSpeed: 0,
                shadow: false,
                shadowBlur: 1 + 15 * Math.random(),
            });
            currentAngleOffset += angleOffsetStep;
            currentOpacity += opacityStep;
            currentSize += sizeStep;
        }

        let canvasAppeared = false;
        const drawParticle = () => {
            if (animationStart === 0) {
                animationStart = performance.now();
            }

            willParticleAppear =
                performance.now() - timestampStartForParticles;

            if (
                true ||
                willParticleAppear >
                    particleEveryMs -
                        (particleEveryMs - 100) *
                            (animationProgress / animationTotalMS)
            ) {
                willParticleAppear = 0;
                timestampStartForParticles = performance.now();
                let randoxXDirection = Math.random() > 0.5 ? -1 : 1;
                let randoxYDirection = Math.random() > 0.5 ? -1 : 1;
                let randomSize = 1 + 3 * Math.random();
                randoxXDirection =
                    Math.random() > 0.5
                        ? -1 * Math.random()
                        : 1 * Math.random();
                randoxYDirection =
                    Math.random() > 0.5
                        ? -1 * Math.random()
                        : 1 * Math.random();

                particles.push({
                    x: 0,
                    y: 0,
                    currentX: 0,
                    currentY: 0,
                    directionX: randoxXDirection * particlesSpeed,
                    directionY: randoxYDirection * particlesSpeed,
                    size: randomSize,
                    maxMoveDistance: randomSize > 3 ? 5 : 30,
                    isAlive: true,
                    isPermanent: false,
                    angleOffset:
                        (((Math.random() > 0.5 ? -1 : 1) * Math.PI) /
                            (30 +
                                (Math.random() > 0.5 ? -1 : 1) *
                                    Math.random() *
                                    20)) *
                        Math.random(),
                    farFromCenter: outerSparkRadius,
                    opacity: 1,
                    opacityChangeSpeed: opacityChangeSpeed,
                });
            }

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            animationProgress = performance.now() - animationStart;

            currentAngle =
                startAngle +
                2 * Math.PI * (animationProgress / animationTotalMS);
            if (currentAngle > -Math.PI / 4 && !canvasAppeared) {
                canvasAppeared = true;
                refTimer.classList.add("appeared");
            }
            particles.forEach((particle) => {
                const pathLength = Math.sqrt(
                    Math.pow(particle.currentX - particle.x, 2) +
                        Math.pow(particle.currentY - particle.y, 2)
                );
                if (
                    pathLength >= particle.maxMoveDistance &&
                    !particle.isPermanent
                ) {
                    particle.isAlive = false;
                }
                if (particle.isAlive) {
                    if (
                        (particle.currentX === 0 &&
                            particle.currentY === 0) ||
                        particle.isPermanent
                    ) {
                        const Yval =
                            particle.farFromCenter *
                            Math.sin(currentAngle + particle.angleOffset);
                        const Xval =
                            particle.farFromCenter *
                            Math.cos(currentAngle + particle.angleOffset);
                        particle.currentX = Xval;
                        particle.currentY = Yval;
                        particle.x = Xval;
                        particle.y = Yval;
                    }
                    if (particle.color) {
                        if (
                            particle.opacity < 0.75 ||
                            particle.opacity > 1
                        ) {
                            if (particle.opacity < 0.75) {
                                particle.opacity = 0.75;
                                particle.opacityChangeSpeed =
                                    -1 * opacityChangeSpeed;
                            }
                            if (particle.opacity > 1) {
                                particle.opacity = 1;
                                particle.opacityChangeSpeed =
                                    1 * opacityChangeSpeed;
                            }
                        }
                        particle.size +=
                            particle.size < particle.maxSize
                                ? particle.sizeChangeSpeed
                                    ? particle.sizeChangeSpeed
                                    : -sizeChangingSpeed *
                                        (Math.random() * 3)
                                : 0;
                        particle.opacity += !particle.isYellowLight
                            ? particle.opacityChangeSpeed
                            : 0;
                    } else {
                        particle.opacity += particle.opacityChangeSpeed;
                    }
                    ctx.save();

                    // ctx.globalAlpha = particle.opacity;
                    ctx.translate(
                        ctx.canvas.width / 2,
                        ctx.canvas.height / 2
                    );
                    ctx.shadowColor = particle.shadow ? "#fff" : "";
                    ctx.shadowBlur = particle.shadow
                        ? particle.shadowBlur
                        : "";
                    ctx.beginPath();

                    ctx.globalAlpha = particle.color
                        ? particle.opacity < 0
                            ? 0
                            : particle.opacity
                        : 1;

                    let radialSpark = ctx.createRadialGradient(
                        particle.currentX,
                        particle.currentY,
                        particle.size,
                        particle.currentX,
                        particle.currentY,
                        0
                    );
                    radialSpark.addColorStop(
                        0,
                        "rgba(255, 255, 255, 0.01)"
                    );
                    radialSpark.addColorStop(
                        1,
                        `rgba(255, 255, 255, ${particle.opacity})`
                    );

                    if (!particle.isYellowLight) {
                        particle.fillStyle = radialSpark;
                    } else {
                        radialSpark = ctx.createRadialGradient(
                            particle.currentX,
                            particle.currentY,
                            particle.size,
                            particle.currentX,
                            particle.currentY,
                            0
                        );
                        radialSpark.addColorStop(
                            0,
                            "rgba(255, 212, 126, 0.01)"
                        );
                        radialSpark.addColorStop(
                            1,
                            `rgba(255, 212, 126, ${particle.opacity})`
                        );
                        particle.fillStyle = radialSpark;
                    }

                    ctx.fillStyle = particle.fillStyle;

                    ctx.arc(
                        particle.currentX,
                        particle.currentY,
                        particle.size,
                        0,
                        2 * Math.PI
                    );
                    ctx.fill();
                    ctx.restore();
                    particle.currentX += particle.directionX;
                    particle.currentY += particle.directionY;
                }
            });

            particles = particles.filter((particle) => particle.isAlive);

            if (animationProgress <= animationTotalMS && this.#timerActive) {
                requestAnimationFrame(drawParticle);
            }
        };
        requestAnimationFrame(drawParticle);
    }
    /**
     * Этот метод обрабатывает вывод искры таймера для игрока.
     * 
     * @param {Number} activeTimeout            Текущее значение таймаута.
     * @returns undefined
     */
    #startSparkTimer( activeTimeout ) {
        const refTimer = this.#timerSparkCanvas;
        const ctx = refTimer.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        ctx.canvas.width = this.#timerCanvasWidth;
        ctx.canvas.height = this.#timerCanvasHeight;

        let outerSparkRadius = 0;

        outerSparkRadius = ctx.canvas.width / 2 - 8;

        const timeLeftAfterPageLoaded =
            activeTimeout * 1000 -
            (new Date().getTime() - this.#activeTimestamp);

        // если после перезагрузки страницы activeTimeout > 0,
        // иначе таймер включился во время действия страницы
        const animationTotalMS =
            activeTimeout > 0
                ? timeLeftAfterPageLoaded
                : this.#timerAmount * 1000;
        let animationStart = 0;
        let animationProgress = 0;

        const startAngle = 0;
        let currentAngle = startAngle;

        const drawSpark = () => {
            if (animationStart === 0) {
                animationStart = performance.now();
            }

            animationProgress = performance.now() - animationStart;
            currentAngle =
                startAngle +
                2 * Math.PI * (animationProgress / animationTotalMS);

            let progressMultiplicator = 0;
            let shadowLineWidth = 0;
            
            if (currentAngle > Math.PI / 4) {
                ctx.save();
                ctx.globalAlpha = 0.8;
                ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
                ctx.rotate(currentAngle);
                ctx.beginPath();
                ctx.moveTo(0, -outerSparkRadius);
                ctx.shadowColor = "#FFFFbb";
                ctx.shadowBlur = 7;
                ctx.fillStyle = "rgba(255, 221, 135, 0.01)";
                progressMultiplicator = 3;
                shadowLineWidth =
                    5 *
                        ((animationProgress / animationTotalMS - 0.12) *
                            progressMultiplicator) <=
                    5
                        ? 5 *
                            ((animationProgress / animationTotalMS - 0.12) *
                                progressMultiplicator)
                        : 5;
                ctx.arc(
                    0,
                    -outerSparkRadius,
                    shadowLineWidth,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
                ctx.restore();

                ctx.globalAlpha = 1;
                ctx.save();
                ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
                ctx.rotate(currentAngle);
                ctx.beginPath();
                ctx.moveTo(0, -outerSparkRadius);
                // line

                ctx.shadowBlur = 5;
                ctx.fillStyle = "rgba(255, 221, 135, 0.01)";
                progressMultiplicator = 5;
                ctx.shadowColor = "#ffffff";

                if (
                    (animationProgress / animationTotalMS - 0.12) *
                        progressMultiplicator >
                    0.8
                ) {
                    ctx.shadowColor = "#ffe780";
                }
                shadowLineWidth =
                    3 *
                        ((animationProgress / animationTotalMS - 0.12) *
                            progressMultiplicator) <=
                    3
                        ? 3 *
                            ((animationProgress / animationTotalMS - 0.12) *
                                progressMultiplicator)
                        : 3;
                ctx.arc(
                    0,
                    -outerSparkRadius,
                    shadowLineWidth,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
                ctx.restore();

                ctx.save();
                ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
                ctx.rotate(currentAngle);
                ctx.beginPath();
                ctx.moveTo(0, -outerSparkRadius);
                ctx.fillStyle = `rgba(255, 221, 135, ${
                    0.01 + animationProgress / animationTotalMS - 0.12
                })`;
                ctx.arc(0, -outerSparkRadius, 1, 0, 2 * Math.PI);
                ctx.fill();
                ctx.restore();
            }

            if (animationProgress <= animationTotalMS && this.#timerActive) {
                requestAnimationFrame(drawSpark);
            }
        };
        requestAnimationFrame(drawSpark);
    }
    /**
     * Этот метод обрабатывает вывод таймера (арки) для игрока.
     * 
     * @param {Number} activeTimeout            Текущее значение таймаута.
     * @returns undefined
     */
    #startTimer( activeTimeout ) {
        const refTimer = this.#timerArcCanvas
        const ctx = refTimer.getContext("2d");

        ctx.canvas.width = this.#timerCanvasWidth;
        ctx.canvas.height = this.#timerCanvasHeight;

        let arrowLength = 0;
        let contourRadius = 0;
        let circleRadius = 0;
        let outerSparkRadius = 0;

        contourRadius = ctx.canvas.width / 2 - 36;
        circleRadius = ctx.canvas.width / 2 - 37;
        outerSparkRadius = ctx.canvas.width / 2;
        arrowLength = outerSparkRadius - circleRadius;

        const conicalGradient = ctx.createConicalGradient(
            ctx.canvas.width / 2,
            ctx.canvas.height / 2,
            -Math.PI + Math.PI / 2,
            Math.PI + Math.PI / 2
        );
        conicalGradient.addColorStop(0, "#ffe780");
        conicalGradient.addColorStop(0.5, "#dfa924");
        conicalGradient.addColorStop(1, "#ff0000");

        const contour = ctx.createConicalGradient(
            ctx.canvas.width / 2,
            ctx.canvas.height / 2,
            -Math.PI + Math.PI / 2,
            Math.PI + Math.PI / 2
        );
        contour.addColorStop(0, "rgba(123, 123, 123, 0.01)");
        contour.addColorStop(1, "rgba(255, 255, 255, 1)");

        const outerSparkBase = ctx.createConicalGradient(
            ctx.canvas.width / 2,
            ctx.canvas.height / 2,
            -Math.PI + Math.PI / 2,
            Math.PI + Math.PI / 2
        );
        outerSparkBase.addColorStop(0, "rgba(255, 200, 111, 0.01)");
        outerSparkBase.addColorStop(0.4, "rgba(255, 230, 127, 1)");
        outerSparkBase.addColorStop(1, "rgba(255, 255, 255, 1)");

        const timeLeftAfterPageLoaded =
            activeTimeout * 1000 -
            (this.#startupTimestamp - this.#activeTimestamp);

        // если после перезагрузки страницы activeTimeout > 0,
        // иначе таймер включился во время действия страницы
        const animationTotalMS =
            activeTimeout > 0
                ? timeLeftAfterPageLoaded
                : this.#timerAmount * 1000;

        let animationStart = 0;
        let animationProgress = 0;

        const startAngle = -Math.PI / 2;
        let currentAngle = startAngle;

        const arrow = (p1, p2, size) => {
            let hyp = Math.sqrt(
                (p2.x - p1.x) * (p2.x - p1.x) +
                    (p2.y - p1.y) * (p2.y - p1.y)
            );

            // line
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.lineTo(hyp - size, 0);
            ctx.strokeStyle = "#fff";
            ctx.stroke();

            // triangle
            ctx.fillStyle = "#fff";
            ctx.beginPath();
            ctx.lineTo(hyp - size, size);
            ctx.lineTo(hyp, 0);
            ctx.lineTo(hyp - size, -size);
            ctx.fill();
        };

        const drawCircle = () => {
            if (animationStart === 0) {
                animationStart = performance.now();
            }
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            animationProgress = performance.now() - animationStart;
            this.currentTimerValue = Math.floor(
                (animationTotalMS - animationProgress) / 1000
            );
            currentAngle =
                startAngle +
                2 * Math.PI * (animationProgress / animationTotalMS);
            this.currentAngle = currentAngle;
            ctx.beginPath();
            ctx.moveTo(ctx.canvas.width / 2, ctx.canvas.height / 2);

            ctx.arc(
                ctx.canvas.width / 2,
                ctx.canvas.height / 2,
                circleRadius,
                startAngle,
                currentAngle,
                false
            );
            ctx.fillStyle = conicalGradient.pattern;
            ctx.fill();

            ctx.save();
            ctx.globalAlpha = 1;
            ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
            ctx.rotate(currentAngle);
            arrow(
                { x: ctx.canvas.width / 2, y: ctx.canvas.height / 2 },
                {
                    x: ctx.canvas.width / 2,
                    y: ctx.canvas.height - arrowLength,
                },
                5
            );
            ctx.restore();

            ctx.beginPath();
            ctx.arc(
                ctx.canvas.width / 2,
                ctx.canvas.height / 2,
                contourRadius,
                startAngle,
                currentAngle,
                false
            );

            ctx.lineWidth = 1;
            ctx.strokeStyle = contour.pattern;
            ctx.stroke();

            ctx.globalAlpha = 0.7;
            if (animationProgress <= animationTotalMS && this.#timerActive) {
                requestAnimationFrame(drawCircle);
            } else {
                this.currentTimerValue = 0;
            }
        };

        requestAnimationFrame(drawCircle);
    }
}