// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../assets/sound/cards.mp3", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../assets/sound/chips1.mp3", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../assets/sound/chips2.mp3", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../assets/sound/reminder1.mp3", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("../assets/sound/reminder2.mp3", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("../assets/sound/next.mp3", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "\n\n<div class=\"sounds-container\">\n    <audio data-ref-sound-cards=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"></audio>\n    <audio data-ref-sound-chips1=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"></audio>\n    <audio data-ref-sound-chips2=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"></audio>\n    <audio data-ref-sound-reminder1=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"></audio>\n    <audio data-ref-sound-reminder2=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\"></audio>\n    <audio data-ref-sound-next=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\"></audio>\n</div>\n\n\n\n";
// Exports
export default code;