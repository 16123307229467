/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать объект событий:
import Evented from "./evented.mjs"

/** @export {Map}                           Карта мутабельности объектов. */
export const mutationsMap = new Map( [
    [ Map, new Set( [ "set", "delete", "clear" ] ) ]
  , [ Set, new Set( [ "add", "delete", "clear" ] ) ]
  , [ Array, new Set( [ "push", "pop", "shift", "unshift", "splice" ] ) ]
] )
/** @export {Symbol}                        Символ ссылки на объект State. */
export const STATE_PROPERTY = Symbol( "state proxy property" )
/**
 * Эта функция упрощает установку обработчика события на событие проксированного объекта.
 * 
 * @param {Proxy}    instance               Проксированный объект с состоянием.
 * @param {String}   eventName              Имя устанавливаемого события.
 * @param {Function} listener               Функция-отклик на событие.
 * @returns undefined
 */
export function addStateEventListener( instance, eventName, listener ) {
    // Если целевой объект имеет объект State:
    if ( instance[ STATE_PROPERTY ] instanceof State == true ) {
        // Получить объект состояния:
        const state = instance[ STATE_PROPERTY ]
        // Установить обработчик события:
        state.addEventListener( eventName, listener )
    }
}
/**
 * Эта функция упрощает удаление обработчика события с события проксированного объекта.
 * 
 * @param {Proxy}    instance               Проксированный объект с состоянием.
 * @param {String}   eventName              Имя удаляемого события.
 * @param {Function} listener               Функция-отклик на событие.
 * @returns undefined
 */
export function removeStateEventListener( instance, eventName, listener ) {
    // Если целевой объект имеет объект State:
    if ( instance[ STATE_PROPERTY ] instanceof State == true ) {
        // Получить объект состояния:
        const state = instance[ STATE_PROPERTY ]
        // Удалить обработчик события:
        state.removeEventListener( eventName, listener )
    }
}
/**
 * Эта функция обрабатывает событие состояния и отображает отладочные сообщения.
 * 
 * @param {Object} eventData        Данные события состояния.
 * @returns undefined
 */
export function loggerEventHandler( { transaction } ) {
    // Если произошел вызов функции:
    if ( transaction.invocation == true ) {
        // Вывести сообщение в лог:
        console.log( `# state.${ transaction.name }(`, ...transaction.arguments, ")" )
    }
    // Если изменилось значение свойства:
    else {
        // Вывести сообщение в лог:
        console.log( `# state.${ transaction.name } =`, transaction.oldValue, "->", transaction.value )
    }
}
/**
 * Этот объект описывает специальный объект, который может быть использован в качестве
 * описания работы Proxy для создания объектов, изменения свойств которых, как и запуск
 * методов, сигнализируют о мутации своего состояния при помощи событий.
 */
export default class State
    extends Evented
{
    /** @property {State}                   Родительский объект State. */
    #parent     = undefined
    /** @property {String}                  Текущее имя проксированного свойства. */
    #name       = undefined
    /** @property {Array}                   Список аргументов текущего объекта. */
    #parameters = []
    /**
     * Этот статичный метод может быть использован для создания нового объекта прокси.
     * 
     * @returns {Proxy}
     */
    static create( origin = {} ) {
        return new Proxy( origin, new State() )
    }
    /**
     * Конструктор объекта класса State.
     * 
     * @param {State}  [parent]             Родительский объект State.
     * @param {String} [name]               Текущее имя проксированного свойства.
     * @param {Array}  [parameters]          Аргументы получения текущего состояния.
     */
    constructor( parent, name, parameters = [] ) {
        super()
        // Установка значений переменных объекта:
        this.#parent     = parent
        this.#name       = name
        this.#parameters = parameters
        // Если родитель установлен:
        if ( parent !== undefined ) {
            // Установить подписчика:
            this.subscribeEventedObject( parent )
        }
    }
    /**
     * Этот геттер возвращает родительский объект прокси.
     * 
     * @returns {State}
     */
    get parent() {
        return this.#parent
    }
    /**
     * Этот геттер возвращает верхний родительский объект прокси.
     * 
     * @returns {State}
     */
    get topParent() {
        // Объявить переменную текущего объекта:
        let current = this
        // Обработать родителей:
        while ( ( current = current.parent ) !== undefined ) {
            // Если в текущем объекте есть родитель:
            if ( current.parent == undefined ) {
                // Вернуть найденный объект:
                return current
            }
        }
        // Иначе вернуть undefined:
        return undefined
    }
    /**
     * Этот геттер возвращает текущий путь проксированных объектов.
     * 
     * @returns {Array}
     */
    get path() {
        // Если у текущего объекта есть родитель:
        if ( this.parent !== undefined ) {
            // Объявить переменную пути объектов:
            const path = [ this ]
            // Объявить переменную текущего объекта:
            let current = this
            // Обработать родителей:
            while ( ( current = current.parent ) !== undefined ) {
                // Если в текущем объекте есть родитель:
                if ( current.parent !== undefined ) {
                    // Добавить значение в путь:
                    path.unshift( current )
                }
            }
            // Вернуть итоговый путь:
            return path
        }
        // Иначе вернуть пустой массив:
        return []
    }
    /**
     * Этот геттер возвращает имя текущего проксированного свойства.
     * 
     * @returns {String}
     */
    get name() {
        return this.#name
    }
    /**
     * Этот геттер возвращает полное имя проксированного свойства перечисленное через
     * знак точки.
     * 
     * @returns {String}
     */
    get fullName() {
        // Вернуть полное имя свойства:
        return this.path.map( state => state.name ).join( "." )
    }
    /**
     * Этот геттер возвращает первое имя проксированного свойства для цепочки вызовов
     * текущего. Это будет имя свойства в самом верхнем проксированном объекте, который
     * использовал State.
     * 
     * @returns {String}
     */
    get topName() {
        // Если у текущего объекта есть родитель:
        if ( this.parent !== undefined ) {
            // Вернуть верхнее имя:
            return this.path[ 0 ].name
        }
        // Иначе вернуть undefined:
        return undefined
    }
    /**
     * Этот геттер возвращает аргументы получения текущего проксированного свойства.
     * 
     * @returns {String}
     */
    get parameters() {
        return this.#parameters
    }
    /**
     * Этот геттер возвращает аргументы получения текущего проксированного свойства.
     * 
     * @returns {String}
     */
    get allParameters() {
        // Создать контейнер полных аргументов:
        let allParameters = []
        // Обработать весь путь текущего состояния:
        for ( const state of this.path ) {
            // Если были аргументы:
            if ( state.parameters.length > 0 ) {
                // Добавить аргументы в контейнер:
                allParameters.splice( allParameters.length, 0, ...state.parameters )
            }
        }
        // Вернуть итоговый список аргументов:
        return allParameters
    }
    /**
     * Этот метод обрабатывает получение значения свойства из проксированного объекта. Он
     * заменяет результат на прокси с использованием связанного объекта прокси, который будет
     * передавать события изменения свойств и запуска функций на родительские объекты.
     * 
     * @param {Object} object           Проксируемый объект.
     * @param {String} property         Имя получаемного свойства.
     * @param {Proxy}  origin           Объект прокси.
     * @returns {Object}
     */
    get( object, property, origin ) {
        // Если запрошено свойство по символу STATE_PROPERTY:
        if ( property === STATE_PROPERTY ) {
            // Вернуть текущий инстанс:
            return this
        }
        // Получить свойство:
        const propertyValue = object[ property ]
        // Переключение по типу свойства:
        switch ( true ) {
            // Если значение не опередлено:
            case propertyValue === undefined:
            case propertyValue === null:
            // Если запрошено примитивное значение:
            case propertyValue.constructor === String:
            case propertyValue.constructor === Number:
            case propertyValue.constructor === Boolean:
                // Вернуть по значению:
                return propertyValue
            // Если запрошен метод:
            case propertyValue instanceof Function:
                // Перехватить текущий объект:
                const currentState = this
                // Создать новую функцию для перехвата вызова:
                const handler = function ( ...argumentsList ) {
                    // Проверить, есть ли конструктор среди мутабельных объектов:
                    if ( mutationsMap.has( object.constructor ) == true ) {
                        // Проверить есть ли метод среди мутабельных:
                        if ( mutationsMap.get( object.constructor ).has( property ) == true ) {
                            // Создать объект состояния для мутации:
                            const state = new State( currentState
                                                   , property.toString()
                                                   )
                            // Создать объект транзакции:
                            const transaction = new StateTransaction(
                                state
                              , origin
                              , property
                              , undefined
                              , undefined
                              , argumentsList
                            )
                            // Прикрепить цепочку событий:
                            transaction.subscribeEventedObject( this )
                            // Обработать мутабельность объекта:
                            state.emit( "mutate", { transaction } )
                        }
                    }
                    // Передать вызов в целевой метод:
                    const result = propertyValue.apply( object, argumentsList )
                    // Если результат может быть проксирован:
                    if ( ( result !== undefined )
                      && ( result !== null )
                      && ( result.constructor !== String )
                      && ( result.constructor !== Number )
                      && ( result.constructor !== Boolean )
                       ) {
                        // Создать объект состояния для результата:
                        const resultState = new State(
                            currentState
                          , `${ property.toString() }(${ " * ".repeat( argumentsList.length ).trim() })`
                          , argumentsList
                        )
                        // Вернуть более глубокое проксирование:
                        return new Proxy( result, resultState )
                    }
                    // Если результат не может быть проксирован:
                    else {
                        // Вернуть сам результат:
                        return result
                    }
                }
                // Создать проксированное значение:
                return new Proxy( handler, this )
            // Если запрошено любое другое значение:
            default:
                // Создать новый инстанс объекта прокси состояния:
                const state = new State( this, property.toString() )
                // Вернуть проксированное значение:
                return new Proxy( propertyValue, state )
        }
    }
    /**
     * Этот метод обрабатывает установку значения свойства для проксированного объекта. Он 
     * сообщает об изменениях в виде событий текущего и родительских объектов State.
     * 
     * @param {Object} object           Проксируемый объект.
     * @param {String} property         Имя изменяемого свойства.
     * @param {Object} value            Устанавливаемое значение.
     * @param {Proxy}  origin           Объект прокси.
     * @returns {Boolean}
     */
    set( object, property, value, origin ) {
        // Получить прежнее значение:
        const oldValue = object[ property ]
        // Установить значение:
        object[ property ] = value
        // Если значение изменено:
        if ( oldValue !== value ) {
            // Создать объект состояния для присвоения:
            const state = new State( this, property.toString() )
            // Создать объект транзакции:
            const transaction = new StateTransaction(
                state
              , origin
              , property
              , value
              , oldValue
            )
            // Прикрепить цепочку событий:
            transaction.subscribeEventedObject( this )
            // Вызвать обработчик события:
            state.emit( "mutate", { transaction } )
        }
        // Вернуть true:
        return true
    }
    /**
     * Этот метод обрабатывает удаление значения свойства для проксированного объекта. Он 
     * сообщает об изменениях в виде событий текущего и родительских объектов State.
     * 
     * @param {Object} object           Проксируемый объект.
     * @param {String} property         Имя удаляемого свойства.
     * @param {Proxy}  origin           Объект прокси.
     * @returns {Boolean}
     */
    deleteProperty( object, property, origin ) {
        // Получить прежнее значение:
        const oldValue = object[ property ]
        // Удалить значение:
        delete object[ property ]
        // Создать объект состояния для удаления:
        const state = new State( this, property.toString() )
        // Создать объект транзакции:
        const transaction = new StateTransaction(
            state
          , origin
          , property
          , undefined
          , oldValue
        )
        // Прикрепить цепочку событий:
        transaction.subscribeEventedObject( this )
        // Вызвать обработчик события:
        state.emit( "mutate", { transaction } )
        // Вернуть true:
        return true
    }
}
/**
 * Этот класс описывает объект транзакции изменения состояния.
 */
export class StateTransaction
    extends Evented
{
    /** @property {State}                   Объект состояния. */
    #state      = undefined
    /** @property {Proxy}                   Целевой объект прокси. */
    #origin     = undefined
    /** @property {String}                  Имя модифицированного свойства или имя метода. */
    #property   = undefined
    /** @property {Object}                  Новое установленное значение. */
    #value      = undefined
    /** @property {Object}                  Старое значение. */
    #oldValue   = undefined
    /** @property {Array}                   Значения аргументов вызова. */
    #arguments  = undefined
    /** @property {Boolean}                 Флаг вызова метода. */
    #invocation = false
    /**
     * Этот метод создает транзакцию установки нового свойства переданного объекта состояния.
     * 
     * @param {Proxy}  origin               Целевой объект.
     * @param {String} property             Имя свойства.
     * @returns {StateTransaction}
     */
    static create( origin, property ) {
        // Если есть состояние в объекте:
        if ( origin[ STATE_PROPERTY ] !== undefined ) {
            // Получить родительское состояние:
            const parentState = origin[ STATE_PROPERTY ]
            // Создать новый объект состояния:
            const state = new State(
                parentState
              , property
            )
            // Создать объект транзакции:
            return new StateTransaction(
                state
              , origin
              , property
              , origin[ property ]
            )
        }
        // Иначе вернуть undefined:
        return undefined
    }
    /**
     * Конструктор объекта класса StateTransaction.
     * 
     * @param {State}  state                Объект состояния.
     * @param {Proxy}  origin               Целевой объект прокси.
     * @param {String} property             Имя модифицированного свойства или имя метода.
     * @param {Object} value                Новое установленное значение.
     * @param {Object} oldValue             Старое значение.
     * @param {Array}  argumentsList        Значения аргументов вызова.
     */
    constructor( state, origin, property, value, oldValue, argumentsList ) {
        super()
        // Установка значений переменных объекта:
        this.#state    = state
        this.#origin   = origin
        this.#property = property
        this.#value    = value
        this.#oldValue = oldValue
        // Если переданы аргументы:
        if ( argumentsList !== undefined ) {
            // Установить аргументы и статус вызова:
            this.#arguments  = argumentsList
            this.#invocation = true
        }
        // Если аргументы не переданы:
        else {
            this.#arguments  = []
            this.#invocation = false
        }
    }
    /**
     * Этот геттер возвращает объект State соответствующий текущей транзакции.
     * 
     * @returns {State}
     */
    get state() {
        return this.#state
    }
    /**
     * Этот геттер возвращает параметры вызова методов в транзакции.
     * 
     * @returns {Proxy}
     */
    get origin() {
        return this.#origin
    }
    /**
     * Этот геттер возвращает имя свойства (или метода) относительно родителя.
     * 
     * @returns {String}
     */
    get property() {
        return this.#property
    }
    /**
     * Этот геттер возвращает полное имя свойства относительно объекта состояния.
     * 
     * @returns {String}
     */
    get name() {
        return this.#state.fullName
    }
    /**
     * Этот геттер возвращает текущее значение измененного свойства.
     * 
     * @returns {Object}
     */
    get value() {
        return this.#value
    }
    /**
     * Этот геттер возвращает старое значение измененного свойства.
     * 
     * @returns {Object}
     */
    get oldValue() {
        return this.#oldValue
    }
    /**
     * Этот геттер возвращает аргументы вызова метода в транзакции.
     * 
     * @returns {Array}
     */
    get arguments() {
        return this.#arguments
    }
    /**
     * Этот геттер возвращает true если это транзакция вызова.
     * 
     * @returns {Boolean}
     */
    get invocation() {
        return this.#invocation
    }
    /**
     * Этот геттер возвращает параметры вызова методов в транзакции.
     * 
     * @returns {Array}
     */
    get parameters() {
        return this.#state.allParameters
    }
    /**
     * Этот метод возвращает true если текущая транзакция имеет совместимое с одним из переданных
     * аргументов имя, либо соответствует описанному в аргументе паттерну.
     * 
     * @param {String} ...patterns      Имена и паттерны для проверки.
     * @returns {Boolean}
     */
    matches( ...patterns ) {
        // Получить текущее полное имя:
        const name = this.name
        // Проверка на точное совпадение:
        if ( patterns.includes( name ) == true ) {
            // Вернуть true:
            return true
        }
        // Перебрать все паттерны:
        for ( const pattern of patterns ) {
            // Если паттерн кончается на ".*":
            if ( pattern.endsWith( ".*" ) == true ) {
                // Получить префикс для этого паттерна:
                const prefix = pattern.slice( 0, -1 )
                // Проверить, что имя начинается с этого префикса:
                if ( name.startsWith( prefix ) == true ) {
                    // Если так, то вернуть true:
                    return true
                }
            }
        }
        // Иначе вернуть false:
        return false
    }
    /**
     * Этот метод публикует события так, как будто были вызваны методы на его объекте.
     * 
     * @param {String} methodName           Имя метода.
     * @param {Array}  [argumentSources]    Источники аргументов.
     * @returns {Array}
     */
    expandCalls( name, argumentSources = [ ( key, value ) => key, ( key, value ) => value ] ) {
        // Создать переменную возврата:
        const transactions = []
        // Обойти все элементы текущего значения:
        for ( const [ entryName, entryValue ] of this.#value ) {
            // Объявить список аргументов:
            const argumentsList = []
            // Обойти отклики аргументов:
            for ( const argumentSource of argumentSources ) {
                // Если значение является функцией:
                if ( argumentSource instanceof Function == true ) {
                    // Добавить результат вызова:
                    argumentsList.push( argumentSource( entryName, entryValue ) )
                }
                // Если не является функцией:
                else {
                    // Добавить по значению:
                    argumentsList.push( argumentSource )
                }
            }
            // Создать объект состояния:
            const state = new State( this.#state, name )
            // Создать объект транзакции:
            const transaction = new StateTransaction(
                state
              , this.#origin
              , name
              , undefined
              , undefined
              , argumentsList
            )
            // Прикрепить цепочку событий:
            transaction.subscribeEventedObject( this )
            // Обработать мутабельность объекта:
            transaction.emit( "mutate", { transaction } )
            // Запомнить транзакцию:
            transactions.push( transaction )
        }
        // Вернуть транзакции:
        return transactions
    }
    /**
     * Этот метод публикует события так, как будто были вызваны методы на его объекте.
     * 
     * @param {String}   methodName             Имя метода.
     * @param {Array}    [parameterSources]     Источники параметров.
     * @param {Function} [valueSource]          Источник результата.
     * @returns {Array}
     */
    expandCallResults( name, parameterSources = [ key => key ], valueSource = value => value ) {
        // Создать переменную возврата:
        const transactions = []
        // Обойти все элементы текущего значения:
        for ( const [ entryName, entryValue ] of this.#value ) {
            // Объявить список аргументов:
            const parameters = []
            // Обойти отклики аргументов:
            for ( const parameterSource of parameterSources ) {
                // Если значение является функцией:
                if ( parameterSource instanceof Function == true ) {
                    // Добавить результат вызова:
                    parameters.push( parameterSource( entryName, entryValue ) )
                }
                // Если не является функцией:
                else {
                    // Добавить по значению:
                    parameters.push( parameterSource )
                }
            }
            // Установить результат:
            let result
            // Если значение является функцией:
            if ( valueSource instanceof Function == true ) {
                // Добавить результат вызова:
                result = valueSource( entryValue, entryName )
            }
            // Если не является функцией:
            else {
                // Добавить по значению:
                result = valueSource
            }
            // Создать объект состояния:
            const state = new State( this.#state, name, parameters )
            // Создать прокси состояния:
            const proxy = new Proxy( result, state )
            // Получить все перебираемые свойства текущего объекта:
            for ( const [ entryName, entryValue ] of Object.entries( result ) ) {
                // Создать объект состояния:
                const propertyState = new State( state, entryName )
                // Создать объект транзакции:
                const transaction = new StateTransaction(
                    propertyState
                  , proxy
                  , entryName
                  , entryValue
                )
                // Прикрепить цепочку событий:
                transaction.subscribeEventedObject( this )
                // Вызвать обработчик события:
                transaction.emit( "mutate", { transaction } )
                // Запомнить транзакцию:
                transactions.push( transaction )
            }
        }
        // Вернуть транзакции:
        return transactions
    }
    /**
     * Этот метод публикует события мутации объекта состояния на всех внутренних свойствах.
     * 
     * @returns {Array}
     */
    expand() {
        // Создать переменную возврата:
        const transactions = []
        // Получить целевой объект:
        const targetProxy = this.#origin[ this.#property ]
        // Получить все перебираемые свойства текущего объекта:
        for ( const property of Object.keys( targetProxy ) ) {
            // Создать объект транзакции:
            const transaction = StateTransaction.create( targetProxy, property )
            // Прикрепить цепочку событий:
            transaction.subscribeEventedObject( this )
            // Вызвать обработчик события:
            transaction.emit( "mutate", { transaction } )
            // Запомнить транзакцию:
            transactions.push( transaction )
        }
        // Вернуть транзакции:
        return transactions
    }
}