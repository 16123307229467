/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/session/regenerate".
 */
export default class SessionRegenerate
    extends Request
{
    /**
     * Конструктор объекта класса SessionRegenerate.
     * 
     * @param {String} token            Токен обновления.
     */
    constructor( token ) {
        super( "/session/regenerate", "POST" )
        // Установка значений переменных объекта:
        this.body = { token }
    }
}