/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/room/attack".
 */
export default class RoomAttackRequest
    extends Request
{
    /**
     * Конструктор объекта класса RoomAttackRequest.
     * 
     * @param {Number} roomId           Идентификатор комнаты.
     * @param {String} card             Карта атаки.
     */
    constructor( roomId, card ) {
        super( "/room/attack", "POST" )
        // Установка значений переменных объекта:
        this.body = { "room_id": roomId
                    , "card": card
                    }
    }
}