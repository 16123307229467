<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Подключение зависимостей изображений карт: -->
<div class="cards-container">
    <img id="card_2_c_webp" src="../assets/images-compressed/gold_2_c.webp">
    <img id="card_3_c_webp" src="../assets/images-compressed/gold_3_c.webp">
    <img id="card_4_c_webp" src="../assets/images-compressed/gold_4_c.webp">
    <img id="card_5_c_webp" src="../assets/images-compressed/gold_5_c.webp">
    <img id="card_6_c_webp" src="../assets/images-compressed/gold_6_c.webp">
    <img id="card_7_c_webp" src="../assets/images-compressed/gold_7_c.webp">
    <img id="card_8_c_webp" src="../assets/images-compressed/gold_8_c.webp">
    <img id="card_9_c_webp" src="../assets/images-compressed/gold_9_c.webp">
    <img id="card_T_c_webp" src="../assets/images-compressed/gold_T_c.webp">
    <img id="card_J_c_webp" src="../assets/images-compressed/gold_J_c.webp">
    <img id="card_Q_c_webp" src="../assets/images-compressed/gold_Q_c.webp">
    <img id="card_K_c_webp" src="../assets/images-compressed/gold_K_c.webp">
    <img id="card_A_c_webp" src="../assets/images-compressed/gold_A_c.webp">
    <img id="card_2_d_webp" src="../assets/images-compressed/gold_2_d.webp">
    <img id="card_3_d_webp" src="../assets/images-compressed/gold_3_d.webp">
    <img id="card_4_d_webp" src="../assets/images-compressed/gold_4_d.webp">
    <img id="card_5_d_webp" src="../assets/images-compressed/gold_5_d.webp">
    <img id="card_6_d_webp" src="../assets/images-compressed/gold_6_d.webp">
    <img id="card_7_d_webp" src="../assets/images-compressed/gold_7_d.webp">
    <img id="card_8_d_webp" src="../assets/images-compressed/gold_8_d.webp">
    <img id="card_9_d_webp" src="../assets/images-compressed/gold_9_d.webp">
    <img id="card_T_d_webp" src="../assets/images-compressed/gold_T_d.webp">
    <img id="card_J_d_webp" src="../assets/images-compressed/gold_J_d.webp">
    <img id="card_Q_d_webp" src="../assets/images-compressed/gold_Q_d.webp">
    <img id="card_K_d_webp" src="../assets/images-compressed/gold_K_d.webp">
    <img id="card_A_d_webp" src="../assets/images-compressed/gold_A_d.webp">
    <img id="card_2_h_webp" src="../assets/images-compressed/gold_2_h.webp">
    <img id="card_3_h_webp" src="../assets/images-compressed/gold_3_h.webp">
    <img id="card_4_h_webp" src="../assets/images-compressed/gold_4_h.webp">
    <img id="card_5_h_webp" src="../assets/images-compressed/gold_5_h.webp">
    <img id="card_6_h_webp" src="../assets/images-compressed/gold_6_h.webp">
    <img id="card_7_h_webp" src="../assets/images-compressed/gold_7_h.webp">
    <img id="card_8_h_webp" src="../assets/images-compressed/gold_8_h.webp">
    <img id="card_9_h_webp" src="../assets/images-compressed/gold_9_h.webp">
    <img id="card_T_h_webp" src="../assets/images-compressed/gold_T_h.webp">
    <img id="card_J_h_webp" src="../assets/images-compressed/gold_J_h.webp">
    <img id="card_Q_h_webp" src="../assets/images-compressed/gold_Q_h.webp">
    <img id="card_K_h_webp" src="../assets/images-compressed/gold_K_h.webp">
    <img id="card_A_h_webp" src="../assets/images-compressed/gold_A_h.webp">
    <img id="card_2_s_webp" src="../assets/images-compressed/gold_2_s.webp">
    <img id="card_3_s_webp" src="../assets/images-compressed/gold_3_s.webp">
    <img id="card_4_s_webp" src="../assets/images-compressed/gold_4_s.webp">
    <img id="card_5_s_webp" src="../assets/images-compressed/gold_5_s.webp">
    <img id="card_6_s_webp" src="../assets/images-compressed/gold_6_s.webp">
    <img id="card_7_s_webp" src="../assets/images-compressed/gold_7_s.webp">
    <img id="card_8_s_webp" src="../assets/images-compressed/gold_8_s.webp">
    <img id="card_9_s_webp" src="../assets/images-compressed/gold_9_s.webp">
    <img id="card_T_s_webp" src="../assets/images-compressed/gold_T_s.webp">
    <img id="card_J_s_webp" src="../assets/images-compressed/gold_J_s.webp">
    <img id="card_Q_s_webp" src="../assets/images-compressed/gold_Q_s.webp">
    <img id="card_K_s_webp" src="../assets/images-compressed/gold_K_s.webp">
    <img id="card_A_s_webp" src="../assets/images-compressed/gold_A_s.webp">
    <img id="card_2_c_png" src="../assets/images-compressed/gold_2_c.png">
    <img id="card_3_c_png" src="../assets/images-compressed/gold_3_c.png">
    <img id="card_4_c_png" src="../assets/images-compressed/gold_4_c.png">
    <img id="card_5_c_png" src="../assets/images-compressed/gold_5_c.png">
    <img id="card_6_c_png" src="../assets/images-compressed/gold_6_c.png">
    <img id="card_7_c_png" src="../assets/images-compressed/gold_7_c.png">
    <img id="card_8_c_png" src="../assets/images-compressed/gold_8_c.png">
    <img id="card_9_c_png" src="../assets/images-compressed/gold_9_c.png">
    <img id="card_T_c_png" src="../assets/images-compressed/gold_T_c.png">
    <img id="card_J_c_png" src="../assets/images-compressed/gold_J_c.png">
    <img id="card_Q_c_png" src="../assets/images-compressed/gold_Q_c.png">
    <img id="card_K_c_png" src="../assets/images-compressed/gold_K_c.png">
    <img id="card_A_c_png" src="../assets/images-compressed/gold_A_c.png">
    <img id="card_2_d_png" src="../assets/images-compressed/gold_2_d.png">
    <img id="card_3_d_png" src="../assets/images-compressed/gold_3_d.png">
    <img id="card_4_d_png" src="../assets/images-compressed/gold_4_d.png">
    <img id="card_5_d_png" src="../assets/images-compressed/gold_5_d.png">
    <img id="card_6_d_png" src="../assets/images-compressed/gold_6_d.png">
    <img id="card_7_d_png" src="../assets/images-compressed/gold_7_d.png">
    <img id="card_8_d_png" src="../assets/images-compressed/gold_8_d.png">
    <img id="card_9_d_png" src="../assets/images-compressed/gold_9_d.png">
    <img id="card_T_d_png" src="../assets/images-compressed/gold_T_d.png">
    <img id="card_J_d_png" src="../assets/images-compressed/gold_J_d.png">
    <img id="card_Q_d_png" src="../assets/images-compressed/gold_Q_d.png">
    <img id="card_K_d_png" src="../assets/images-compressed/gold_K_d.png">
    <img id="card_A_d_png" src="../assets/images-compressed/gold_A_d.png">
    <img id="card_2_h_png" src="../assets/images-compressed/gold_2_h.png">
    <img id="card_3_h_png" src="../assets/images-compressed/gold_3_h.png">
    <img id="card_4_h_png" src="../assets/images-compressed/gold_4_h.png">
    <img id="card_5_h_png" src="../assets/images-compressed/gold_5_h.png">
    <img id="card_6_h_png" src="../assets/images-compressed/gold_6_h.png">
    <img id="card_7_h_png" src="../assets/images-compressed/gold_7_h.png">
    <img id="card_8_h_png" src="../assets/images-compressed/gold_8_h.png">
    <img id="card_9_h_png" src="../assets/images-compressed/gold_9_h.png">
    <img id="card_T_h_png" src="../assets/images-compressed/gold_T_h.png">
    <img id="card_J_h_png" src="../assets/images-compressed/gold_J_h.png">
    <img id="card_Q_h_png" src="../assets/images-compressed/gold_Q_h.png">
    <img id="card_K_h_png" src="../assets/images-compressed/gold_K_h.png">
    <img id="card_A_h_png" src="../assets/images-compressed/gold_A_h.png">
    <img id="card_2_s_png" src="../assets/images-compressed/gold_2_s.png">
    <img id="card_3_s_png" src="../assets/images-compressed/gold_3_s.png">
    <img id="card_4_s_png" src="../assets/images-compressed/gold_4_s.png">
    <img id="card_5_s_png" src="../assets/images-compressed/gold_5_s.png">
    <img id="card_6_s_png" src="../assets/images-compressed/gold_6_s.png">
    <img id="card_7_s_png" src="../assets/images-compressed/gold_7_s.png">
    <img id="card_8_s_png" src="../assets/images-compressed/gold_8_s.png">
    <img id="card_9_s_png" src="../assets/images-compressed/gold_9_s.png">
    <img id="card_T_s_png" src="../assets/images-compressed/gold_T_s.png">
    <img id="card_J_s_png" src="../assets/images-compressed/gold_J_s.png">
    <img id="card_Q_s_png" src="../assets/images-compressed/gold_Q_s.png">
    <img id="card_K_s_png" src="../assets/images-compressed/gold_K_s.png">
    <img id="card_A_s_png" src="../assets/images-compressed/gold_A_s.png">
</div>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать компонент таскаемого элемента:
    import DragAndDrop from "../modules/component/draganddrop.mjs"

    /**
     * Этот класс описывает компонент <room-card>.
     */
    class RoomCard
        extends DragAndDrop
    {
        /**
         * Этот геттер определяет атрибуты за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedAttributes() {
            return [ "data-active", "data-ghost", "data-highlight" ]
        }
        /**
         * Этот метод выполняется при подключении элемента в тело документа.
         * 
         * @returns undefined
         */
        connectedCallback() {
            // Обработать родительский каллбек:
            super.connectedCallback()
            // Получить имя класса карты:
            const additionalClassName = this.dataset.class
            // Получить статус активности:
            const isActive      = this.dataset.active
                , isGhost       = this.dataset.ghost
                , isHighlighted = this.dataset.highlight
            // Получить позицию:
            const position = this.dataset.position
            // Получить значение и масть карты:
            const value = this.dataset.value
                , suit  = this.dataset.suit
            // Получить изображение карты:
            const pngCardImage  = globalThis.document.querySelector( `img#card_${ value }_${ suit }_png` )
                , webpCardImage = globalThis.document.querySelector( `img#card_${ value }_${ suit }_webp` )
            // Если такая карта существует:
            if ( pngCardImage && webpCardImage ) {
                // Создать новый код для содержания:
                const containerElement  = globalThis.document.createElement( "div" )
                    , pictureElement    = globalThis.document.createElement( "picture" )
                    , webpSourceElement = globalThis.document.createElement( "source" )
                    , imgElement        = globalThis.document.createElement( "img" )
                // Установить класс контейнера:
                containerElement.classList.add( "playing-card" )
                // Если передан дополнительный класс:
                if ( additionalClassName !== undefined ) {
                    // Установить дополнительный класс карты:
                    containerElement.classList.add( additionalClassName )
                }
                // Если карта активна:
                if ( isActive ) {
                    // Установить класс активности:
                    containerElement.classList.add( "is-active" )
                }
                // Если карта подавлена:
                if ( isGhost ) {
                    // Установить класс подавленности:
                    containerElement.classList.add( "is-ghost" )
                }
                // Если карта подсвечена:
                if ( isHighlighted ) {
                    // Установить класс подсвеченности:
                    containerElement.classList.add( "is-highlighted" )
                }
                // Если установлена позиция:
                if ( position !== undefined ) {
                    // Установить класс для позиции:
                    containerElement.classList.add( `is-position-${ position }` )
                }
                // Установить атрибуты элементов:
                webpSourceElement.setAttribute( "srcset", webpCardImage.src )
                webpSourceElement.setAttribute( "type", "image/webp" )
                imgElement.setAttribute( "src", pngCardImage.src )
                // Вставить элементы друг в друга:
                pictureElement.appendChild( webpSourceElement )
                pictureElement.appendChild( imgElement )
                containerElement.appendChild( pictureElement )
                // Вставить полученный элемент:
                this.appendChild( containerElement )
            }
        }
        /**
         * Обработать изменение атрибутов элемента.
         * 
         * @param {String} name             Имя атрибута.
         * @param {Object} oldValue         Прежнее значение атрибута.
         * @param {Object} newValue         Новое значение атрибута.
         * @returns undefined
         */
        attributeChangedCallback( name, oldValue, newValue ) {
            // Получить элемент контейнера:
            const containerElement = this.querySelector( ".playing-card" )
            // Если контейнер не был найден:
            if ( containerElement == null ) {
                // Выйти:
                return
            }
            // Переключение по имени атрибута:
            switch ( name ) {
                // Если это активность текущей карты:
                case "data-active":
                    // Если активность установлена:
                    if ( newValue === "true" ) {
                        // Добавить класс на контейнер:
                        containerElement.classList.add( "is-active" )
                    }
                    // Если активность не установлена:
                    else {
                        // Удалить класс с контейнера:
                        containerElement.classList.remove( "is-active" )
                    }
                    break
                // Если это подавленность текущей карты:
                case "data-ghost":
                    // Если подавленность установлена:
                    if ( newValue === "true" ) {
                        // Добавить класс на контейнер:
                        containerElement.classList.add( "is-ghost" )
                    }
                    // Если подавленность не установлена:
                    else {
                        // Удалить класс с контейнера:
                        containerElement.classList.remove( "is-ghost" )
                    }
                    break
                // Если это подсветка текущей карты:
                case "data-highlight":
                    // Если подсветка установлена:
                    if ( newValue === "true" ) {
                        // Добавить класс на контейнер:
                        containerElement.classList.add( "is-highlighted" )
                    }
                    // Если подсветка не установлена:
                    else {
                        // Удалить класс с контейнера:
                        containerElement.classList.remove( "is-highlighted" )
                    }
                    break
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-card", RoomCard )
</script>
<!-- Стиль компонента: -->
<style>
    .cards-container {
        display: none;
    }
</style>