/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать функцию запуска приложения:
import main from "./main.mjs"
// Запуск приложения в текущем окружении:
main( window
    , "durak_app"
    , "app.pfp.durak"
    )