<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Подключение зависимостей звуковых файлов: -->
<div class="sounds-container">
    <audio data-ref-sound-cards src="../assets/sound/cards.mp3"></audio>
    <audio data-ref-sound-chips1 src="../assets/sound/chips1.mp3"></audio>
    <audio data-ref-sound-chips2 src="../assets/sound/chips2.mp3"></audio>
    <audio data-ref-sound-reminder1 src="../assets/sound/reminder1.mp3"></audio>
    <audio data-ref-sound-reminder2 src="../assets/sound/reminder2.mp3"></audio>
    <audio data-ref-sound-next src="../assets/sound/next.mp3"></audio>
</div>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    
    /**
     * Этот класс описывает кастомный элемент <room-sounds>.
     */
    class RoomSounds
        extends Component
    {
        /** @property {HTMLElement}             Звук карт. */
        #cardsSound     = globalThis.document.querySelector( "[data-ref-sound-cards]" )
        /** @property {HTMLElement}             Звук фишек 1. */
        #chips1Sound    = globalThis.document.querySelector( "[data-ref-sound-chips1]" )
        /** @property {HTMLElement}             Звук фишек 2. */
        #chips2Sound    = globalThis.document.querySelector( "[data-ref-sound-chips2]" )
        /** @property {HTMLElement}             Звук напоминания 1. */
        #reminder1Sound = globalThis.document.querySelector( "[data-ref-sound-reminder1]" )
        /** @property {HTMLElement}             Звук напоминания 2. */
        #reminder2Sound = globalThis.document.querySelector( "[data-ref-sound-reminder2]" )
        /** @property {HTMLElement}             Звук паса. */
        #nextSound      = globalThis.document.querySelector( "[data-ref-sound-next]" )
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomPlayer.*", "roomPlayers.*", "roomBoard.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // При активности игрока:
                case "roomPlayer.active":
                    // Если активен:
                    if ( transaction.value == true ) {
                        // Проиграть звук:
                        this.#reminder1Sound.play()
                    }
                    // Если не активен:
                    else {
                        // Если не первая установка:
                        if ( transaction.oldValue !== undefined ) {
                            // Проиграть звук:
                            this.#nextSound.play()
                        }
                    }
                    break
                // При заморозке игрока:
                case "roomPlayer.frozen":
                    // Если активна:
                    if ( transaction.value == true ) {
                        // Проиграть звук:
                        this.#reminder2Sound.play()
                    }
                    break
                // При переходе хода игрока:
                case "roomPlayers.get(*).active":
                    // Если сброшен:
                    if ( transaction.value == false ) {
                        // Проиграть звук:
                        this.#nextSound.play()
                    }
                    break
                // При изменении карт борда:
                case "roomBoard.cards":
                    // Если не первая установка:
                    if ( transaction.oldValue !== undefined ) {
                        // Проиграть звук:
                        this.#cardsSound.play()
                    }
                    break
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-sounds", RoomSounds )
</script>
<!-- Стиль компонента: -->
<style>
    .sounds-container {
        display: none;
    }
</style>