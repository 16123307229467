<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    
    /**
     * Этот класс описывает кастомный элемент <application-loader>.
     */
    class ApplicationLoader
        extends Component
    {
        /**
         * Этот метод выполняется при подключении элемента в тело документа.
         * 
         * @returns undefined
         */
        connectedCallback() {
            // Установка отклика загрузки окна:
            globalThis.addEventListener(
                "load"
              , () => {
                    // Удалить этот элемент из родителя:
                    this.parentNode.removeChild( this )
                }
            )
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "application-loader", ApplicationLoader )
</script>