<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_enemy_template">
    <div class="playing-room-enemy__item" data-ref-enemy-container>
        <room-enemy-cards data-ref-cards data-count="0"></room-enemy-cards>
        <div class="enemy-canvas-timer is-hidden" data-ref-timer-canvas-container>
            <canvas data-ref-timer-canvas-arc></canvas>
            <canvas data-ref-timer-canvas-spark></canvas>
            <canvas data-ref-timer-canvas-particles></canvas>
        </div>
        <picture>							 
            <img src="../assets/images-compressed/player_avatar.png" class="playing-room-enemy__avatar" data-ref-enemy-avatar alt="">
        </picture>
        <div class="playing-room-enemy__action is-hidden" data-ref-enemy-status>
            <img class="playing-room-enemy__action-image is-hidden" data-ref-enemy-status-image data-ref-enemy-status-image-pass src="../assets/images-compressed/pass.png">
            <img class="playing-room-enemy__action-image is-hidden" data-ref-enemy-status-image data-ref-enemy-status-image-take src="../assets/images-compressed/take.png">
        </div>
        <div class="playing-room-enemy__timer_tint is-hidden" data-ref-enemy-timer-tint></div>
        <div class="playing-room-enemy__timer is-hidden" data-ref-enemy-timer>0</div>
        <div class="playing-room-enemy__place is-hidden" data-ref-enemy-place-tint data-ref-enemy-place>
            <img class="playing-room-enemy__place-image is-hidden" data-ref-enemy-place-image data-ref-enemy-place-image-1 src="../assets/images-compressed/place_1.png">
            <img class="playing-room-enemy__place-image is-hidden" data-ref-enemy-place-image data-ref-enemy-place-image-2 src="../assets/images-compressed/place_2.png">
            <img class="playing-room-enemy__place-image is-hidden" data-ref-enemy-place-image data-ref-enemy-place-image-3 src="../assets/images-compressed/place_3.png">
            <img class="playing-room-enemy__place-image is-hidden" data-ref-enemy-place-image data-ref-enemy-place-image-4 src="../assets/images-compressed/place_4.png">
            <img class="playing-room-enemy__place-image is-hidden" data-ref-enemy-place-image data-ref-enemy-place-image-5 src="../assets/images-compressed/place_5.png">
            <img class="playing-room-enemy__place-image is-hidden" data-ref-enemy-place-image data-ref-enemy-place-image-6 src="../assets/images-compressed/place_6.png">
        </div>
        <div class="playing-room-enemy__score" data-ref-enemy-score>0</div>
        <div class="playing-room-enemy__name" data-ref-enemy-name>Test</div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента с анимированным таймером:
    import AnimatedTimer from "../modules/component/animatedtimer.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-enemy>.
     */
    class RoomEnemy
        extends Templated( AnimatedTimer, "room_enemy_template" )
    {
        /** @property {Animation}               Текущая анимация. */
        #animation = undefined
        /** @property {Number}                  Интервал таймера. */
        #timerInterval = undefined
        /** @property {String}                  Текущий выводимый статус. */
        #currentStatus = undefined
        /** @property {String}                  Текущее выводимое место. */
        #currentPlace = undefined
        /**
         * Этот геттер определяет атрибуты за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedAttributes() {
            return [ "data-position" ]
        }
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomPlayers", "roomPlayers.*", "roomPlayer" ]
        }
        /**
         * Обработать изменение атрибутов элемента.
         * 
         * @param {String} name             Имя атрибута.
         * @param {Object} oldValue         Прежнее значение атрибута.
         * @param {Object} newValue         Новое значение атрибута.
         * @returns undefined
         */
        attributeChangedCallback( name, oldValue, newValue ) {
            // Получить элемент контейнера:
            const containerElement = this.querySelector( "[data-ref-enemy-container]" )
            // Если контейнер был найден:
            if ( containerElement !== null ) {
                // Переключение по имени атрибута:
                switch ( name ) {
                    // Если это позиция:
                    case "data-position":
                        // Удалить класс позиции:
                        containerElement.classList.remove( `position-${ oldValue }` )
                        // Добавить класс позиции:
                        containerElement.classList.add( `position-${ newValue }` )
                        break
                }
            }
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Получить текущее место:
            var currentSeat = this.dataset.seat
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если это первичный вывод противников:
                case "roomPlayers":
                    // Выделить вызов сеттеров:
                    transaction.expandCalls( "set" )
                    // Разбить транзакции модификации игрока:
                    transaction.expandCallResults( "get(*)" )
                    break
                // Обновление статуса активности:
                case "roomPlayers.get(*).active_time":
                    // Получить текущее место:
                    var [ seat ] = transaction.parameters
                      , player   = transaction.origin
                    // Если это текущее место:
                    if ( currentSeat == seat ) {
                        // Если параметр задан:
                        if ( player.active == true ) {
                            // Установить активность:
                            this.#setActiveEnemyPlayer(
                                player.active_timeout
                              , player.active_time
                            )
                        }
                        // Если параметр не задан:
                        else {
                            this.#resetActiveEnemyPlayer()
                        }
                    }
                    break
                // Обновление статуса заморозки:
                case "roomPlayers.get(*).frozen":
                    // Получить текущее место:
                    var [ seat ] = transaction.parameters
                      , player   = transaction.origin
                    // Если это текущее место:
                    if ( currentSeat == seat ) {
                        // Если параметр задан:
                        if ( player.frozen == true ) {
                            // Установить активность:
                            this.#setActiveEnemyPlayer(
                                player.freeze_timeout
                              , player.freeze_time
                            )
                            // Установить замороженность:
                            this.#setFrozenEnemyPlayer()
                        }
                        // Если параметр не задан:
                        else {
                            // Сбросить активность:
                            this.#resetActiveEnemyPlayer()
                            // Сбросить замороженность:
                            this.#resetFrozenEnemyPlayer()
                        }
                    }
                    break
                // Обновление количества карт:
                case "roomPlayers.get(*).cards":
                    // Получить текущее место:
                    var [ seat ] = transaction.parameters
                      , cards    = transaction.value
                    // Если это текущее место:
                    if ( currentSeat == seat ) {
                        // Установить количество карт:
                        this.#updateEnemyCards( cards )
                    }
                    break
                // Обновление статуса:
                case "roomPlayers.get(*).game_state":
                    // Получить текущее место:
                    var [ seat ] = transaction.parameters
                      , status   = transaction.value
                    // Если это текущее место:
                    if ( currentSeat == seat ) {
                        // Установить статус:
                        this.#showEnemyStatus( status )
                    }
                    break
                // Обновление места:
                case "roomPlayers.get(*).place":
                    // Получить текущее место:
                    var [ seat ] = transaction.parameters
                      , place    = transaction.value
                    // Если это текущее место:
                    if ( currentSeat == seat ) {
                        // Установить место выигрышное:
                        this.#showEnemyPlace( place )
                    }
                    break
                // Добавление игрока:
                case "roomPlayers.set":
                    // Если это не текущий игрок:
                    if ( currentSeat != state.roomPlayer.seat ) { 
                        // Получение места из параметра:
                        var [ seat, player ] = transaction.arguments
                        // Если это текущее место:
                        if ( seat === currentSeat ) {
                            // Показать врага:
                            this.#showEnemy()
                            // Установить имя и ранг:
                            this.#setEnemyPlayer( player.name, player.rank, player.avatar )
                        }
                    }
                    // Иначе скрыть место:
                    else {
                        // Показать врага:
                        this.#hideEnemy()
                    }
                    break
                // Удаление игрока:
                case "roomPlayers.delete":
                    // Получение места из параметра:
                    var [ seat ] = transaction.arguments
                    // Если это текущее место:
                    if ( seat === currentSeat ) {
                        // Скрыть врага:
                        this.#hideEnemy()
                    }
                    break
                // Если изменена информация игрока:
                case "roomPlayer":
                    // Получить текущее место игрока:
                    var { seat } = transaction.value
                    // Если место установлено:
                    if ( seat !== null ) {
                        // Если место совпадает с текущим противником:
                        if ( seat == this.dataset.seat ) {
                            // Скрыть этого противника:
                            this.#hideEnemy()
                        }
                    }
                    break
            }
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Получить текущую позицию противника:
            const position = this.dataset.position
            // Получить элемент контейнера:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Установить класс позиции на текущий:
            enemyContainer.classList.add( `position-${ position }` )
            // Инициализировать элементы таймера:
            this.initializeAnimatedTimer(
                this.querySelector( "[data-ref-timer-canvas-arc]" )
              , this.querySelector( "[data-ref-timer-canvas-spark]" )
              , this.querySelector( "[data-ref-timer-canvas-particles]" )
            )
            // Установить размер анимированного таймера:
            this.resizeAnimatedTimer( 192, 192 )
            // Установить скрытый класс:
            enemyContainer.classList.add( "is-hidden" )
        }
        /**
         * Этот метод выполняется при отключении шаблонизированного элемента от тела документа.
         * 
         * @returns undefined
         */
        templateDisconnectedCallback() {
            // Деинициализация анимированного таймера:
            this.deinitializeAnimatedTimer()
        }
        /**
         * Этот метод показывает текущего противника.
         * 
         * @returns undefined
         */
        #showEnemy() {
            // Получить элемент контейнера:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Если игрок не отображается:
            if ( enemyContainer.classList.contains( "is-hidden" ) == true ) {
                // Если есть анимация:
                if ( this.#animation ) {
                    // Отменить анимацию:
                    this.#animation.cancel()
                }
                // Создать анимацию:
                this.#animation = enemyContainer.animate(
                    [ { "transform": "scale(1.2)", "opacity": 0 } 
                    , { "transform": "scale(1)", "opacity": 1 }
                    ]
                    , { "easing"  : "ease"
                      , "duration": 200
                      }
                )
                // По окончании анимации:
                this.#animation.onfinish = () => {
                    // Сброс анимации:
                    this.#animation = undefined
                    // Сбросить скрытый класс:
                    enemyContainer.classList.remove( "is-hidden" )
                }
            }
        }
        /**
         * Этот метод скрывает текущего противника.
         * 
         * @returns undefined
         */
        #hideEnemy() {
            // Получить элемент контейнера:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Если игрок отображается:
            if ( enemyContainer.classList.contains( "is-hidden" ) == false ) {
                // Создать анимацию:
                this.#animation = enemyContainer.animate(
                    [ { "transform": "scale(1)", "opacity": 1 } 
                    , { "transform": "scale(0.8)", "opacity": 0 }
                    ]
                    , { "easing"  : "ease"
                      , "duration": 200
                      }
                )
                // По окончании анимации:
                this.#animation.onfinish = () => {
                    // Установить скрытый класс:
                    enemyContainer.classList.add( "is-hidden" )
                    // Сброс анимации:
                    this.#animation = undefined
                }
            }
        }
        /**
         * Этот метод устанавливает имя, ранг и аватар игрока-противника.
         * 
         * @param {String} name             Имя противника.
         * @param {Number} score            Ранг противника.
         * @param {String} [avatar]         URL аватара противника.
         * @returns undefined
         */
        #setEnemyPlayer( name, score, avatar ) {
            // Получить элементы вывода данных игрока:
            const nameElement   = this.querySelector( "[data-ref-enemy-name]" )
                , scoreElement  = this.querySelector( "[data-ref-enemy-score]" )
                , avatarElement = this.querySelector( "[data-ref-enemy-avatar]" )
            // Установить имя и ранг:
            nameElement.textContent  = name
            scoreElement.textContent = score
            // Если передан аватар:
            if ( ( avatar !== undefined ) && ( avatar !== null ) ) {
                avatarElement.src = avatar
            }
        }
        /**
         * Этот метод обновляет ранг игрока-противника.
         * 
         * @param {Number} score            Ранг противника.
         * @returns undefined
         */
        #setEnemyPlayerScore( score ) {
            // Получить элемент вывода ранга игрока:
            const scoreElement = this.querySelector( "[data-ref-enemy-score]" )
            // Установить текущее значение ранга:
            scoreElement.textContent = score
        }
        /**
         * Этот метод устанавливает текущего игрока активным.
         * 
         * @param {Number} totalTime        Количество времени активности.
         * @param {Number} startTime        Начальное время активности.
         * @returns undefined
         */
        #setActiveEnemyPlayer( totalTime, startTime ) {
            // Получить текущий контейнер игрока:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Если игрок отображается:
            if ( enemyContainer.classList.contains( "is-hidden" ) == false ) {
                // Получить контейнер таймера:
                const timerContainer = this.querySelector( "[data-ref-timer-canvas-container]" )
                // Получить численный таймер:
                const timerTextElement = this.querySelector( "[data-ref-enemy-timer]" )
                    , timerTintElement = this.querySelector( "[data-ref-enemy-timer-tint]" )
                // Установить время:
                const timeDiff = Math.round( ( new Date().getTime() - startTime ) / 1000 )
                    , timeLeft = Math.max( 0, totalTime - timeDiff )
                // Установить значение:
                timerTextElement.textContent = Math.min( totalTime, timeLeft )
                // Сбросить интервал:
                clearInterval( this.#timerInterval )
                // Установить интервал 
                this.#timerInterval = setInterval(
                    () => {
                        // Получить текущие значения времени:
                        const currentTimeDiff = Math.round( ( new Date().getTime() - startTime ) / 1000 )
                            , currentTimeLeft = Math.max( 0, totalTime - currentTimeDiff )
                        // Установить значение:
                        timerTextElement.textContent = Math.min( totalTime, currentTimeLeft )
                    }
                  , 1000
                )
                // Показать таймер:
                timerTextElement.classList.remove( "is-hidden" )
                timerTintElement.classList.remove( "is-hidden" )
                // Установить класс активности:
                enemyContainer.classList.add( "is-active" )
                // Запуск таймера:
                this.startAnimatedTimer( totalTime, startTime )
                // Скрыть элемент:
                timerContainer.classList.remove( "is-hidden" )
            }
        }
        /**
         * Этот метод сбрасывает активность текущего игрока.
         * 
         * @returns undefined
         */
        #resetActiveEnemyPlayer() {
            // Получить текущий контейнер игрока:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Получить контейнер таймера:
            const timerContainer = this.querySelector( "[data-ref-timer-canvas-container]" )
            // Получить численный таймер:
            const timerTextElement = this.querySelector( "[data-ref-enemy-timer]" )
                , timerTintElement = this.querySelector( "[data-ref-enemy-timer-tint]" )
            // Установить класс активности:
            enemyContainer.classList.remove( "is-active" )
            // Остановка таймера:
            this.stopAnimatedTimer()
            // Сбросить значение:
            timerTextElement.textContent = ""
            // Сбросить интервал:
            clearInterval( this.#timerInterval )
            // Скрыть таймер:
            timerTextElement.classList.add( "is-hidden" )
            timerTintElement.classList.add( "is-hidden" )
            // Скрыть элемент:
            timerContainer.classList.add( "is-hidden" )
        }
        /**
         * Этот метод обновляет количество карт противника.
         * 
         * @param {Number} cards        Кол-во карт.
         * @returns undefined
         */
        #updateEnemyCards( cards ) {
            // Получить компонент вывода карт:
            const cardsComponent = this.querySelector( "[data-ref-cards]" )
            // Установить параметр количества карт:
            cardsComponent.dataset.count = cards
        }
        /**
         * Этот метод выводит статус игрока.
         *
         * @param {String} type         Тип текущего статуса.
         * @returns undefined
         */
        #showEnemyStatus( type ) {
            // Получить контейнер для вывода статуса:
            const statusContainer = this.querySelector( "[data-ref-enemy-status]" )
                , statusImageElements = statusContainer.querySelectorAll( "[data-ref-enemy-status-image]" )
            // Скрытие изображений:
            for ( const statusImageElement of statusImageElements ) {
                // Скрытие изображения:
                statusImageElement.classList.add( "is-hidden" )
            }
            // Объявить переменную изображения статуса:
            let target
            // Переключение по типу:
            switch ( type ) {
                // Статус "пас":
                case "pass":
                    target = statusContainer.querySelector( "[data-ref-enemy-status-image-pass]" )
                    break
                // Статус "беру":
                case "take":
                    target = statusContainer.querySelector( "[data-ref-enemy-status-image-take]" )
                    break
                // Неподдерживаемый статус:
                default:
                    // Скрыть элемент:
                    statusContainer.classList.add( "is-hidden" )
                    return

            }
            // Отобразить элемент:
            target.classList.remove( "is-hidden" )
            // Создать анимацию:
            const animation = target.animate(
                [ { "transform": "translateY(20px)", "opacity": 0 } 
                , { "transform": "translateY(0)", "opacity": 1, "offset": 0.3 }
                , { "transform": "translateY(0)", "opacity": 1, "offset": 0.3 }
                ]
              , { "fill"    : "forwards"
                , "easing"  : "ease"
                , "duration": 2000
                , "delay"   : 10 
                }
            )
            // Показать элемент:
            statusContainer.classList.remove( "is-hidden" )
            // Установить текущий статус:
            this.#currentStatus = type
        }
        /**
         * Этот метод выводит выигрышное место игрока.
         * 
         * @param {Number} place            Выигрышное место.
         * @returns undefined
         */
        #showEnemyPlace( place ) {
            // Получить вывод места:
            const placeContainerElement = this.querySelector( "[data-ref-enemy-place]" )
                , placeImageElements = placeContainerElement.querySelectorAll( "[data-ref-enemy-place-image]" )
                , placeTintElement = this.querySelector( "[data-ref-enemy-place-tint]" )
            // Скрыть текущие места:
            for ( const placeImageElement of placeImageElements ) {
                // Скрыть изображение:
                placeImageElement.classList.add( "is-hidden" )
            }
            // Если место установлено:
            if ( place !== 0 ) {
                // Получить изображение места:
                const placeCurrentImageElement = placeContainerElement.querySelector(
                    `[data-ref-enemy-place-image-${ place }]`
                )
                // Вывести место:
                placeCurrentImageElement.classList.remove( "is-hidden" )
                // Вывести элементы:
                placeContainerElement.classList.remove( "is-hidden" )
                placeTintElement.classList.remove( "is-hidden" )
            }
            // Если место не установлено:
            else {
                // Скрыть элементы:
                placeContainerElement.classList.add( "is-hidden" )
                placeTintElement.classList.add( "is-hidden" )
            }
            // Установить текущий статус:
            this.#currentPlace = place
        }
        /**
         * Этот метод устанавливает замороженность текущего игрока.
         * 
         * @returns undefined
         */
        #setFrozenEnemyPlayer() {
            // Получить текущий контейнер игрока:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Установить статус "заморожен":
            enemyContainer.classList.add( "is-frozen" )
        }
        /**
         * Этот метод сбрасывает замороженность текущего игрока.
         * 
         * @returns undefined
         */
        #resetFrozenEnemyPlayer() {
            // Получить текущий контейнер игрока:
            const enemyContainer = this.querySelector( "[data-ref-enemy-container]" )
            // Сбросить статус "заморожен":
            enemyContainer.classList.remove( "is-frozen" )
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-enemy", RoomEnemy )
</script>
<!-- Стиль компонента: -->
<style type="text/scss">
    .enemy-canvas-timer {
        width: 117px;
        height: 117px;
        position: absolute;
        left: 45px;
        top: 70px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        z-index: 4;
        & > canvas {
            position: absolute;
            opacity: 1;
            z-index: 99;
        }
        & > canvas:nth-child(2) {
            transform: translate(-2px, -1px);
        }
        & > canvas:last-child {
            z-index: 4;
            transition: 1s linear;
            opacity: 0;
            transform: translate(-2px, -1px);

            &.appeared {
                transition: 1s linear;

                opacity: 1;
            }
        }
        &.notThisPlayer {
            left: 15px;
            top: 14px;
        }
    }
</style>