/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/session/destroy".
 */
export default class SessionDestroy
    extends Request
{
    /**
     * Конструктор объекта класса SessionDestroy.
     */
    constructor() {
        super( "/session/destroy" )
    }
}