/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс входящих сообщений:
import Incoming from "../../incoming.mjs"
// Использовать объект состояния:
import { state } from "../../../../main.mjs"

/**
 * Этот класс описывает входящее сообщение "public_state". 
 */
export default class PublicState
    extends Incoming
{
    /**
     * Этот метод тестирует полученное сообщение и возвращает true, если сообщение
     * может быть обработано текущим экземпляром объекта входящего сообщения.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns {Boolean}
     */
    test( message ) {
        return ( message.channel.startsWith( "table_" ) == true )
            && ( message.event === "public_state" )
    }
    /**
     * Этот метод обрабатывает получение сообщения из сокета.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns undefined
     */
    process( message ) {
        // Получить место:
        const seat = message.data.seat.toString()
        // Проверка есть ли игрок:
        if ( state.roomPlayers.has( seat ) == true ) {
            // Получить игрока:
            const player = state.roomPlayers.get( seat )
            // Обновление текущего игрока:
            Object.assign( player, message.data.state )
        }
        // Если игрока нет:
        else {
            // Вставить новое значение:
            state.roomPlayers.set( seat, message.data.state )
        }
    }
}