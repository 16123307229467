<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="window_freeze_template">
    <div class="modal room__1frozen" data-modal="room__1frozen">
        <div class="modal__overlay registration">
            <div class="room__1frozen-wrapper">
                <div class="room__1frozen-inner">
                    <div class="room__1frozen-level">
                        <picture>
                            <source srcset="../assets/images-compressed/room_1_frozen_level.webp" type="image/webp">
                            <img src="../assets/images-compressed/room_1_frozen_level.png" alt="">
                        </picture>
                        <span data-ref-freeze-timer></span>
                    </div>
                    <picture>
                        <source srcset="../assets/images-compressed/room_1_frozen_title.webp" type="image/webp">
                        <img src="../assets/images-compressed/room_1_frozen_title.png" class="room__1frozen-title" alt="">
                    </picture>
                    <h3 class="room__1frozen-subtitle">время на ВАШ ход закончилось</h3>
                    <p class="room__1frozen-text">По окончанию времени заморозки, игровой стол закроется автоматически.</p>
                    <p class="room__1frozen-text">Нажмите кнопку “Продолжить”<br> для возобновления игры</p>
                    <picture>
                        <source srcset="../assets/images-compressed/room__1frozen_btnshadow.webp" type="image/webp">
                        <img src="../assets/images-compressed/room__1frozen_btnshadow.png" class="room__1frozen-btnshadow" alt="">
                    </picture>
                    <form action="/room/unfreeze"
                          method="post"
                          data-request-disable="initiator"
                          data-request-clear
                          data-request-json
                          >
                        <input type="hidden" name="room_id" value="" data-ref-room-id-input>
                        <template-event name="click" listener="onContinueClick">
                            <button type="submit" class="button_gold playing-room-panel__button_gold room__1frozen-btn" data-ref-unfreeze-button>
                                ПРОДОЛЖИТЬ								
                            </button>
                        </template-event>
                    </form>	
                </div>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_frozen_mainimg.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_frozen_mainimg.png" class="room__1frozen-mainimg" alt="">
                </picture>

                <picture>
                    <source srcset="../assets/images-compressed/room_1_frozen_item1.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_frozen_item1.png" class="room__1frozen-item1 room__1frozen-item" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_frozen_item2.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_frozen_item2.png" class="room__1frozen-item2 room__1frozen-item" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_frozen_item3.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_frozen_item3.png" class="room__1frozen-item3 room__1frozen-item" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_frozen_item4.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_frozen_item4.png" class="room__1frozen-item4 room__1frozen-item" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_frozen_item5.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_frozen_item5.png" class="room__1frozen-item5 room__1frozen-item" alt="">
                </picture>
            </div>
        </div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <window-freeze>.
     */
    class WindowFreeze
        extends Templated( Component, "window_freeze_template" )
    {
        /** @property {Number}              Числовой идентификатор интервала. */
        #intervalId = undefined
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomId", "roomPlayer" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если изменен идентификатор комнаты:
                case "roomId":
                    // Установка содержания поля ввода:
                    this.#setRoomId( transaction.value )
                    break
                // Если установка игрока:
                case "roomPlayer":
                    // Получить время:
                    var { freeze_time, freeze_timeout } = transaction.value
                    // Установить время:
                    const timeDiff = Math.round( ( new Date().getTime() - freeze_time ) / 1000 )
                        , timeLeft = Math.max( 0, freeze_timeout - timeDiff )
                    // Вывести таймер:
                    this.#startFreezeTimer( timeLeft )
                    break
            }
        }
        /**
         * Этот метод обрабатывает клик на кнопке продолжить.
         * 
         * @returns undefined
         */
        onContinueClick() {
            // Сброс текущего таймера:
            this.#stopFreezeTimer()
        }
        /**
         * Этот метод устанавливает идентификатор текущей комнаты.
         * 
         * @param {String} id           Идентификатор (номер) комнаты.
         * @returns undefined
         */
        #setRoomId( id ) {
            // Установка содержания полей ввода:
            for ( const inputElement of this.querySelectorAll( "[data-ref-room-id-input]" ) ) {
                inputElement.value = id
            }
        }
        /**
         * Этот метод устанавливает количество секунд заморозки.
         * 
         * @param {Number} amount           Количество секунд заморозки.
         * @returns undefined
         */
        #startFreezeTimer( amount ) {
            // Сброс текущего таймера:
            this.#stopFreezeTimer()
            // Если таймер положительный:
            if ( amount > 0 ) {
                // Получить элемент 
                const timerElement = this.querySelector( "[data-ref-freeze-timer]" )
                // Установить текущее значение:
                timerElement.textContent = amount
                // Получить элемент кнопки продолжения:
                const unfreezeButton = this.querySelector( "[data-ref-unfreeze-button]" )
                // Получить текущее время:
                const startTime = new Date().getTime()
                // Установить интервал обновления таймера:
                this.#intervalId = setInterval(
                    () => {
                        // Получить смещение секунд:
                        const timeOffset = Math.round( ( new Date().getTime() - startTime ) / 1000 )
                        // Обновить значение вывода:
                        const currentAmount = Math.max( 0, amount - timeOffset )
                        // Установить значение:
                        timerElement.textContent = currentAmount
                        // Если таймер достиг нуля:
                        if ( currentAmount == 0 ) {
                            // Сброс текущего таймера:
                            this.#stopFreezeTimer()
                            // Установить кнопке статус неактивной:
                            unfreezeButton.disabled = "disabled"
                            // Передать сообщение выхода:
                            globalThis.top.postMessage( { type: "close" }, "*" )
                        }
                    }
                  , 1000
                )
            }
        }
        /**
         * Этот метод сбрасывает таймер заморозки.
         * 
         * @returns undefined
         */
        #stopFreezeTimer() {
            // Если интервал установлен:
            if ( this.#intervalId !== undefined ) {
                // Сбросить интервал:
                clearInterval( this.#intervalId )
                // Сбросить идентификатор интервала:
                this.#intervalId = undefined
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "window-freeze", WindowFreeze )
</script>