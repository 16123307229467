<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_enemy_cards_template">
    <div class="playing-room-enemy__cards" data-ref-enemy-cards></div>
</template>
<!-- Шаблон элемента карты: -->
<template id="room_enemy_cards_card_template">
    <picture data-ref-js-enemy-card>
        <source srcset="../assets/images-compressed/gold_facedown.webp" type="image/webp">
        <img src="../assets/images-compressed/gold_facedown.png" alt="" data-ref-enemy-card-image>
    </picture>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-enemy-cards>.
     */
    class RoomEnemyCards
        extends Templated( Component, "room_enemy_cards_template" )
    {
        /** @property {Number}                  Количество выведенных карт. */
        #showedCards = 0
        /**
         * Этот геттер определяет атрибуты за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedAttributes() {
            return [ "data-count" ]
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Обновить количество выводимых карт:
            this.#updateCards()
        }
        /**
         * Этот метод сбрасывает выводимые карты игрока.
         * 
         * @returns undefined
         */
        #clearCards() {
            // Сбросить текущие карты:
            this.#showedCards = 0
            // Получить выводимые элементы карт:
            const cards = this.querySelectorAll( "[data-ref-js-enemy-card]" )
            // Удалить все узлы карт:
            for ( const card of cards ) {
                card.parentNode.removeChild( card )
            }
        }
        /**
         * Этот метод обновляет количество выводимых карт игрока.
         * 
         * @returns undefined
         */
        #updateCards() {
            // Получить текущее количество выводимых карт:
            const count = Number( this.dataset.count )
            // Если все карты выведены:
            if ( this.#showedCards === count ) {
                // Ничего не делать:
                return
            }
            // Получить текущий контейнер карт:
            const cardsContainer = this.querySelector( "[data-ref-enemy-cards]" )
            // Получить шаблон карты:
            const cardTemplate = globalThis.document.getElementById( "room_enemy_cards_card_template" )
            // Объявить минимальный шаг:
            const step = 12
            // Объявить контейнер углов:
            const angles = []
            // Если текущее количество не четное:
            if ( count % 2 == 1 ) {
                // Вставить центральный угол:
                angles.push( 90 )
                // Получить целое от деления:
                const roundedHalf = Math.floor( count / 2 )
                // Получить текущее смещение:
                const shift = Math.min( step, 90 / roundedHalf )
                // Пройти циклом для всех левых карт:
                for ( let iteration = 0, position = 90
                    ; iteration < roundedHalf
                    ; iteration++ 
                    ) { 
                    // Добавить угол:
                    angles.unshift( position - shift )
                    // Сместить текущую позицию:
                    position -= shift
                }
                // Пройти циклом для всех правых карт:
                for ( let iteration = 0, position = 90
                    ; iteration < roundedHalf
                    ; iteration++
                    ) { 
                    // Добавить угол:
                    angles.push( position + shift )
                    // Сместить текущую позицию:
                    position += shift
                }
            }
            // Если количество четное:
            else {
                // Получить половину:
                const half = count / 2
                // Получить текущее смещение:
                const shift = Math.min( step, 90 / half )
                // Получить серединный сдвиг:
                const centerShift = shift / 2
                // Пройти циклом для всех левых карт:
                for ( let iteration = 0, position = 90 + centerShift
                    ; iteration < half
                    ; iteration++ 
                    ) { 
                    // Добавить угол:
                    angles.unshift( position - shift )
                    // Сместить текущую позицию:
                    position -= shift
                }
                // Пройти циклом для всех правых карт:
                for ( let iteration = 0, position = 90 - centerShift
                    ; iteration < half
                    ; iteration++ 
                    ) {
                    // Добавить угол:
                    angles.push( position + shift )
                    // Сместить текущую позицию:
                    position += shift
                }
            }
            // Если есть лишние карты:
            if ( this.#showedCards > count ) {
                // Получить текущие карты:
                const currentCards = this.querySelectorAll( "[data-ref-js-enemy-card]" )
                // Для каждой лишней карты:
                for ( let iteration = 0
                    ; iteration < this.#showedCards - count
                    ; iteration++
                    ) {
                    // Удалить узел лишней карты:
                    currentCards[ iteration ].parentNode.removeChild(
                        currentCards[ iteration ]
                    )
                }
            }
            // Если есть недостающие карты:
            else {
                // Для каждой недостающей карты:
                for ( let iteration = 0
                    ; iteration < count - this.#showedCards
                    ; iteration++
                    ) {
                    // Создать элемент карты из шаблона:
                    const cardFragment = cardTemplate.content.cloneNode( true )
                        , cardElement  = cardFragment.querySelector( "[data-ref-enemy-card-image]" )
                    // Установить инициализированные параметры:
                    cardElement.style.transform = "rotate(359deg)"
                    cardElement.style.opacity   = 0
                    // Вставить карту в контейнер:
                    cardsContainer.appendChild( cardFragment )
                }
            }
            // Обновить число выводимых карт:
            this.#showedCards = count
            // Отрисовать карты следующим кадром анимации:
            globalThis.requestAnimationFrame(
                () => {
                    // Получить актуальные карты:
                    const currentCards = this.querySelectorAll( "[data-ref-enemy-card-image]" )
                    // Для каждого рассчитанного угла:
                    for ( const [ index, angle ] of Object.entries( angles ) ) {
                        // Если карта еще есть:
                        if ( index in currentCards ) {
                            // Выставить углы смещений:
                            currentCards[ index ].style.transform = `rotate(${ angle }deg)`
                            currentCards[ index ].style.opacity   = 1
                        }
                    }
                }   
            )
        }
        /**
         * Обработать изменение атрибутов элемента.
         * 
         * @param {String} name             Имя атрибута.
         * @param {Object} oldValue         Прежнее значение атрибута.
         * @param {Object} newValue         Новое значение атрибута.
         * @returns undefined
         */
        attributeChangedCallback( name, oldValue, newValue ) {
            // Получить элемент контейнера:
            const containerElement = this.querySelector( "[data-ref-enemy-cards]" )
            // Если контейнер был найден:
            if ( containerElement !== null ) {
                // Переключение по имени атрибута:
                switch ( name ) {
                    // Если это количество выводимых карт:
                    case "data-count":
                        // Обновить количество карт:
                        this.#updateCards()
                        break
                }
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-enemy-cards", RoomEnemyCards )
</script>