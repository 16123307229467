<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="window_results_template">
    <div class="modal room__1resultes" data-modal="room__1resultes">
        <div class="modal__overlay registration" data-close>
            <div class="room__1resultes-wrapper">
                <div class="room__1resultes-inner">
                    <div class="room__1resultes-header">
                        <picture>
                            <source srcset="../assets/images-compressed/room_1_results_title.webp" type="image/webp">
                            <img src="../assets/images-compressed/room_1_results_title.png" class="room__1resultes-title" alt="">
                        </picture>
                    </div>
                    <div class="room__1resultes-table">
                        <ul class="room__1resultes-tableheader">
                            <li>Место</li>
                            <li>Имя игрока</li>
                            <li>Выигрыш</li>
                            <li>опыт</li>
                        </ul>
                        <ul class="room__1resultes-list">
                            <li data-ref-results-list-1>
                                <div class="room__1resultes-nomber">
                                    <picture>
                                        <source srcset="../assets/images-compressed/room_1_results_nomber1.webp" type="image/webp">
                                        <img src="../assets/images-compressed/room_1_results_nomber1.png" alt="">
                                    </picture>	
                                    <span>1</span>									
                                </div>
                                <p class="room__1resultes-name" data-ref-results-list-name></p>
                                <p class="room__1resultes-benefit" data-ref-results-list-coins></p>
                                <p class="room__1resultes-exp" data-ref-results-list-expirience></p>
                            </li>
                            <li data-ref-results-list-2>
                                <div class="room__1resultes-nomber">
                                    <picture>
                                        <source srcset="../assets/images-compressed/room_1_results_nomber2.webp" type="image/webp">
                                        <img src="../assets/images-compressed/room_1_results_nomber2.png" alt="">
                                    </picture>	
                                    <span>2</span>	
                                                                    
                                </div>
                                <p class="room__1resultes-name" data-ref-results-list-name></p>
                                <p class="room__1resultes-benefit" data-ref-results-list-coins></p>
                                <p class="room__1resultes-exp" data-ref-results-list-expirience></p>
                            </li>
                            <li data-ref-results-list-3>
                                <div class="room__1resultes-nomber">
                                    <picture>
                                        <source srcset="../assets/images-compressed/room_1_results_nomber3.webp" type="image/webp">
                                        <img src="../assets/images-compressed/room_1_results_nomber3.png" alt="">
                                    </picture>	
                                    <span>3</span>										
                                </div>
                                <p class="room__1resultes-name" data-ref-results-list-name></p>
                                <p class="room__1resultes-benefit" data-ref-results-list-coins></p>
                                <p class="room__1resultes-exp" data-ref-results-list-expirience></p>
                            </li>
                            <li data-ref-results-list-4>
                                <div class="room__1resultes-nomber">4</div>
                                <p class="room__1resultes-name" data-ref-results-list-name></p>
                                <p class="room__1resultes-benefit" data-ref-results-list-coins></p>
                                <p class="room__1resultes-exp" data-ref-results-list-expirience></p>
                            </li>
                            <li data-ref-results-list-5>
                                <div class="room__1resultes-nomber">5</div>
                                <p class="room__1resultes-name" data-ref-results-list-name></p>
                                <p class="room__1resultes-benefit" data-ref-results-list-coins></p>
                                <p class="room__1resultes-exp" data-ref-results-list-expirience></p>
                            </li>
                            <li data-ref-results-list-6>
                                <div class="room__1resultes-nomber">6</div>
                                <p class="room__1resultes-name" data-ref-results-list-name></p>
                                <p class="room__1resultes-benefit" data-ref-results-list-coins></p>
                                <p class="room__1resultes-exp" data-ref-results-list-expirience></p>
                            </li>
                        </ul>
                    </div>
                    <picture>
                        <source srcset="../assets/images-compressed/room__1resultes_btnshadow.webp" type="image/webp">
                        <img src="../assets/images-compressed/room__1resultes_btnshadow.png" class="shadow" alt="">
                    </picture>
                    
                    <form action="/room/enter"
                          method="post"
                          data-request-disable="initiator"
                          data-request-clear
                          data-request-json
                          >
                        <input type="hidden" name="room_id" value="" data-ref-room-id-input>
                        <button class="button_gold playing-room-panel__button_gold room__1resultes-btn">								
                            ПРОДОЛЖИТЬ								
                        </button>
                    </form>
                    <template-event name="click" listener="onExitButtonClick">
                        <button class="room__1-btnexit" data-close>
                            <picture>
                                <source srcset="../assets/images-compressed/room__1resultes_btnexit_bg.webp" type="image/webp">
                                <img src="../assets/images-compressed/room__1resultes_btnexit_bg.png" alt="">
                            </picture>
                            выйти
                        </button>
                    </template-event>
                </div>
                <picture>
                        <source srcset="../assets/images-compressed/room__1resultes_mainimg.webp" type="image/webp">
                        <img src="../assets/images-compressed/room__1resultes_mainimg.png" class="room__1resultes-mainimg" alt="">
                    </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_results_bgtitle.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_results_bgtitle.png" class="room__1resultes-bgtitle" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_results_bg1.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_results_bg1.png"  class="room__1resultes-bg1" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_results_bg2.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_results_bg2.png"  class="room__1resultes-bg2" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_results_bg3.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_results_bg3.png"  class="room__1resultes-bg3" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room_1_results_bg3.webp" type="image/webp">
                    <img src="../assets/images-compressed/room_1_results_bg3.png"  class="room__1resultes-bg4" alt="">
                </picture>
            </div>
        </div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <window-results>.
     */
    class WindowResults
        extends Templated( Component, "window_results_template" )
    {
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomId", "lastGameResults" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если изменен идентификатор комнаты:
                case "roomId":
                    // Установка содержания поля ввода:
                    this.#setRoomId( transaction.value )
                    break
                // Если изменен последний выигрыш:
                case "lastGameResults":
                    // Получить число результатов:
                    const resultsCount = Object.keys( transaction.value ).length
                    // Установить количество результатов:
                    this.#showItems( resultsCount )
                    // Для каждого результата:
                    for ( const [ place, info ] of Object.entries( transaction.value ) ) {
                        // Установить значения:
                        this.#setListItemName(
                            place
                          , info.name
                          , info.coins
                          , info.exp
                        )
                    }
                    break
            }
        }
        /**
         * Обработка события нажатия на выход.
         */
        onExitButtonClick() {
            // Передать сообщение выхода:
            globalThis.top.postMessage( { type: "close" }, "*" )
            return false
        }
        /**
         * Этот метод устанавливает идентификатор текущей комнаты.
         * 
         * @param {String} id           Идентификатор (номер) комнаты.
         * @returns undefined
         */
        #setRoomId( id ) {
            // Установка содержания полей ввода:
            for ( const inputElement of this.querySelectorAll( "[data-ref-room-id-input]" ) ) {
                inputElement.value = id
            }
        }
        /**
         * Этот метод отображает количество позиций соответственно количеству мест за столом.
         * 
         * @param {Number} size             Количество мест за столом.
         * @returns undefined
         */
        #showItems( size ) {
            // Обойти позиции:
            for ( let iteration = 1
                ; iteration <= 6
                ; iteration++
                ) {
                // Получить элемент списка:
                const listItemElement = this.querySelector( `[data-ref-results-list-${ iteration }]` )
                // Если это отображаемое место:
                if ( iteration <= size ) {
                    // Удалить класс скрытия:
                    listItemElement.classList.remove( "is-hidden" )
                }
                // Если это скрытое место:
                else {
                    // Добавить класс скрытия:
                    listItemElement.classList.add( "is-hidden" )
                    // Сбросить значения:
                    this.#resetItem( iteration )
                }
            }
        }
        /**
         * Этот метод сбрасывает данные позиции.
         * 
         * @param {Number} place             Место.
         * @returns undefined
         */
        #resetItem( place ) {
            // Получить элемент списка:
            const listItemElement = this.querySelector( `[data-ref-results-list-${ place }]` )
            // Если элемент найден:
            if ( listItemElement !== null ) {
                // Получить элементы для элемента списка:
                const nameElement       = listItemElement.querySelector( "[data-ref-results-list-name]" )
                    , coinsElement      = listItemElement.querySelector( "[data-ref-results-list-coins]" )
                    , expirienceElement = listItemElement.querySelector( "[data-ref-results-list-expirience]" )
                // Cбросить поля:
                nameElement.textContent = ""
                coinsElement.textContent = ""
                expirienceElement.textContent = ""
            }
        }
        /**
         * Этот метод устанавливает имя и приз на определенной позиции списка входа.
         * 
         * @param {Number} place            Место.
         * @param {String} name             Имя игрока.
         * @param {Number} prize_coins      Коины.
         * @param {Number} prize_exp        Опыт.
         * @returns undefined
         */
        #setListItemName( place, name, prize_coins, prize_exp ) {
            // Получить элемент списка:
            const listItemElement = this.querySelector( `[data-ref-results-list-${ place }]` )
            // Если элемент найден:
            if ( listItemElement !== null ) {
                // Получить элементы для элемента списка:
                const nameElement       = listItemElement.querySelector( "[data-ref-results-list-name]" )
                    , coinsElement      = listItemElement.querySelector( "[data-ref-results-list-coins]" )
                    , expirienceElement = listItemElement.querySelector( "[data-ref-results-list-expirience]" )
                // Cбросить поля:
                nameElement.textContent = name
                coinsElement.textContent = `${ prize_coins > 0 ? "+" : "" }${ prize_coins }`
                expirienceElement.textContent = `${ prize_exp > 0 ? "+" : "" }${ prize_exp }`
            } 
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "window-results", WindowResults )
</script>