<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_hand_template">
    <div class="playing-room-panel__cards" data-ref-player-hand-container></div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"
    // Использовать объект атаки:
    import RoomAttackRequest from "../modules/request/roomattackrequest.mjs"
    // Использовать объект защиты:
    import RoomDefendRequest from "../modules/request/roomdefendrequest.mjs"

    /**
     * Этот класс описывает компонент <room-hand>.
     */
    class RoomHand
        extends Templated( Component, "room_hand_template" )
    {
        /** @property {Set}         Текущие выводимые карты руки. */
        #currentHand = new Set()
        /** @property {String}      Текущая козырная масть. */
        #currentTrump = undefined
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomPlayer", "roomPlayer.*", "roomBoard", "roomBoard.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если обновлен сам объект игрока:
                case "roomPlayer":
                    // Разбить транзакцию на события:
                    transaction.expand()
                    break
                // Если обновлены карты:
                case "roomPlayer.cards":
                    // Получить карты игрока:
                    var cards = transaction.value
                    // Отобразить карты игрока:
                    this.#setHandCards( cards )
                    // Если установлен козырь:
                    if ( state.roomBoard.trump != null ) {
                        // Получить масть козыря:
                        var trumpSuit = state.roomBoard.trump.split( "" )[ 1 ]
                        // Отметить козыри в руке:
                        this.#setTrumpSuit( trumpSuit )
                    }
                    break
                // Если обновлены активные карты:
                case "roomPlayer.active_cards":
                    // Получить карты игрока:
                    var active_cards = transaction.value
                    // Если карты установлены:
                    if ( active_cards !== null ) {
                        // Отобразить карты игрока:
                        this.#setActiveCards( active_cards )
                    }
                    // Если карты не установлены:
                    else {
                        // Сбросить активные карты игрока:
                        this.#resetActiveCards()
                    }
                    break
                // Если обновлены доступные карты:
                case "roomPlayer.available_cards":
                    // Получить доступные карты игрока:
                    var available_cards = transaction.value
                    // Если карты установлены:
                    if ( available_cards !== null ) {
                        // Отобразить доступные карты игрока:
                        this.#setAvailableCards( available_cards )
                    }
                    // Если карты не установлены:
                    else {
                        // Сбросить активные карты игрока:
                        this.#resetAvailableCards()
                    }
                    break
                // Если обновлены подбрасываемые карты:
                case "roomPlayer.throwable_cards":
                    // Получить подбрасываемые карты игрока:
                    var throwable_cards = transaction.value
                    // Если карты установлены:
                    if ( throwable_cards !== null ) {
                        // Отобразить подбрасываемые карты игрока:
                        this.#setThrowableCards( throwable_cards )
                    }
                    // Если карты не установлены:
                    else {
                        // Сбросить активные карты игрока:
                        this.#resetThrowableCards()
                    }
                    break
                // Смена позиции:
                case "roomPlayer.attack":
                case "roomPlayer.defend":
                    // Если игрок не атакует и не защищается, сбросить статус:
                    if ( ( state.roomPlayer.attack == false )
                      && ( state.roomPlayer.defend == false )
                       ) {
                        // Сбросить статусы карт:
                        this.#resetCardsStatus()
                    }
                    break
                // Если обновлен сам объект борда:
                case "roomBoard":
                case "roomBoard.trump":
                    // Получить козырную карту:
                    var trump = state.roomBoard.trump
                    // Если установлен козырь:
                    if ( trump !== null ) {
                        // Получить масть козыря:
                        var trumpSuit = trump.split( "" )[ 1 ]
                        // Отметить козыри в руке:
                        this.#setTrumpSuit( trumpSuit )
                    }
                    // Если козыря нет:
                    else {
                        // Сбросить козыри в руке:
                        this.#resetTrumpSuit()
                    }
                    break
            }
        }
        /**
         * Этот метод показывает переданные карты в текущей руке игрока.
         * 
         * @param {Array}  cards            Карты игрока.
         * @returns undefined
         */
        #setHandCards( cards ) {
            // Получить элемент контейнера карт на борде:
            const handContainer = this.querySelector( "[data-ref-player-hand-container]" )
            // Сохранить переменную текущей карты:
            let lastCard
            // Сохранить текущие карты:
            const currentHand = new Set( this.#currentHand )
            // Для каждой карты:
            for ( const card of cards ) {
                // Получить значение и масть:
                const [ value, suit ] = card.split( "" )
                // Если этой карты нет в руке:
                if ( this.#currentHand.has( card ) == false ) {
                    // Создать элемент карты:
                    const cardComponent = globalThis.document.createElement( "room-card" )
                    // Карту можно перемещать:
                    cardComponent.dataset.drag = true
                    // Установить масть и значение карты:
                    cardComponent.dataset.value = value
                    cardComponent.dataset.suit  = suit
                    // Установить дополнительный класс карты:
                    cardComponent.dataset.class = "playing-card_player"
                    // Если есть последняя карта:
                    if ( lastCard !== undefined ) {
                        // Если у неё есть следующая:
                        if ( lastCard.nextElementSibling != null ) {
                            // Вставить в эту позицию:
                            handContainer.insertBefore(
                                cardComponent
                              , lastCard.nextElementSibling
                            )
                        }
                        // Если у неё нет следующей:
                        else {
                            // Вставить в конец:
                            handContainer.appendChild( cardComponent )
                        }
                    }
                    // Если нет последней карты:
                    else {
                        // Если есть первая карта:
                        if ( handContainer.firstElementChild != null ) {
                            // Вставить до него:
                            handContainer.insertBefore(
                                cardComponent
                              , handContainer.firstChild
                            )
                        }
                        // Если нет первой карты:
                        else {
                            // Вставить в конец:
                            handContainer.appendChild( cardComponent )
                        }
                    }
                    // Создать анимацию удаления:
                    const animation = cardComponent.animate(
                        [ { "transform": "rotateY(-50deg) translateX(-21px) translateY(-10px)", "opacity": 0 } 
                        , { "transform": "rotateY(0deg) translateX(0) translateY(0)", "opacity": 1 }
                        ]
                      , { "fill"    : "forwards"
                        , "easing"  : "ease"
                        , "duration": 200
                        }
                    )
                    // Установить эту карту последней:
                    lastCard = cardComponent
                    // Сохранить карту в руке:
                    this.#currentHand.add( card )
                    // Установить обработчики событий карты:
                    this.#setCardEventsHandlers( cardComponent )
                }
                // Если карта есть:
                else {
                    // Получить эту карту:
                    const existingCardElement = this.querySelector(
                        `room-card[data-value="${ value }"][data-suit="${ suit }"]`
                    )
                    // Установить последней:
                    lastCard = existingCardElement
                }
                // Удалить из текущих:
                currentHand.delete( card )
            }
            // Если есть оставшиеся карты:
            if ( currentHand.size ) {
                // Обойти их:
                for ( const removedCard of currentHand.values() ) {
                    // Получить значение и масть удаленной карты:
                    const [ removedCardValue, removedCardSuit ] = removedCard.split( "" )
                    // Получить удаляемую карту:
                    const removedCardElement = this.querySelector(
                        `room-card[data-value="${ removedCardValue }"][data-suit="${ removedCardSuit }"]`
                    )
                    // Создать анимацию удаления:
                    const animation = removedCardElement.animate(
                        [ { "transform": "translateX(0) translateY(0)", "opacity": 1 } 
                        , { "transform": "rotateY(-50deg) translateX(-21px) translateY(20px)", "opacity": 0 }
                        ]
                      , { "fill"    : "forwards"
                        , "easing"  : "ease"
                        , "duration": 200
                        }
                    )
                    // По завершению:
                    animation.onfinish = () => {
                        // Удалить карту:
                        removedCardElement.parentNode.removeChild( removedCardElement )
                    }
                    // Удалить карту из текущих:
                    this.#currentHand.delete( removedCard )
                }
            }
            // Обновить размер контейнера:
            this.#updateHandSize()
        }
        /**
         * Этот метод обновляет размер контейнера.
         * 
         * @returns undefined
         */
        #updateHandSize() {
            // Получить элемент контейнера карт на борде:
            const handContainer = this.querySelector( "[data-ref-player-hand-container]" )
            // Удалить классы контейнера:
            handContainer.classList.remove( "m-min-count" )
            handContainer.classList.remove( "m-mid-count" )
            handContainer.classList.remove( "m-max-count" )
            // Получить текущее число карт:
            const count = this.#currentHand.size
            // Переключение по условиям:
            switch ( true ) {
                // Для минимального вывода:
                default:
                    // Установить класс минимального размера:
                    handContainer.classList.add( "m-min-count" )
                    break
                // Для среднего вывода:
                case count >= 7 && count <= 15:
                    // Установить класс среднего размера:
                    handContainer.classList.add( "m-mid-count" )
                    break
                // Для максимального вывода:
                case count > 15:
                    // Установить класс максимального размера:
                    handContainer.classList.add( "m-max-count" )
                    break
            }
        }
        /**
         * Этот метод удаляет все карты текущей руки.
         * 
         * @returns undefined
         */
        #dropHandCards() {
            // Получить элемент контейнера карт на борде:
            const handContainer = this.querySelector( "[data-ref-player-hand-container]" )
            // Удалить элементы:
            handContainer.innerHTML = ""
        }
        /**
         * Этот метод показывает козырные карты в руке игрока.
         * 
         * @param {String} suit             Козырная масть.
         * @returns undefined
         */
        #setTrumpSuit( suit ) {
            // Сбросить козыри:
            this.#resetTrumpSuit()
            // Получить все карты козырей:
            const trumpCards = this.querySelectorAll( `room-card[data-suit="${ suit }"]` )
            // Обработать найденные карты:
            for ( const cardComponent of trumpCards ) {
                // Если масть козырная:
                if ( cardComponent.dataset.suit == suit ) {
                    // Установить подсветку карты:
                    cardComponent.dataset.highlight = true
                }
            }
        }
        /**
         * Этот метод сбрасывает козырные карты в руке игрока.
         * 
         * @param {String} suit             Козырная масть.
         * @returns undefined
         */
        #resetTrumpSuit() {
            // Получить все карты козырей:
            const highlightedCards = this.querySelectorAll( `room-card[data-highlight]` )
            // Для всех подсвеченных:
            for ( const highlightedCardComponent of highlightedCards ) {
                // Удалить подсветку:
                delete highlightedCardComponent.dataset.highlight
            }
        }
        /**
         * Этот метод показывает активные карты.
         * 
         * @param {Array}  active_cards     Текущие активные карты.
         * @returns undefined
         */
        #setActiveCards( active_cards ) {
            // Сбросить активные карты:
            this.#resetActiveCards()
            // Если активные карты установлены:
            if ( active_cards !== null ) {
                // Перебрать активные карты:
                for ( const card of active_cards ) {
                    // Получить значение и масть:
                    const [ value, suit ] = card.split( "" )
                    // Получить элемент карты:
                    const cardComponent = this.querySelector(
                        `room-card[data-value="${ value }"][data-suit="${ suit }"]`
                    )
                    // Если найдена карта:
                    if ( cardComponent != null ) {
                        // Установить активность:
                        cardComponent.dataset.active = true
                    }
                }
            }
        }
        /**
         * Этот метод сбрасывает активные карты.
         * 
         * @returns undefined
         */
        #resetActiveCards() {
            // Получить все активные карты:
            const activeCards = this.querySelectorAll( `room-card[data-active]` )
            // Для всех активных:
            for ( const activeCardComponent of activeCards ) {
                // Удалить активность:
                delete activeCardComponent.dataset.active
            }
        }
        /**
         * Этот метод показывает доступные карты в текущей руке.
         * 
         * @param {Array}  available_cards  Текущие доступные карты.
         * @returns undefined
         */
        #setAvailableCards( available_cards ) {
            // Сбросить доступные карты:
            this.#resetAvailableCards()
            // Если установлены доступные карты:
            if ( available_cards !== null ) {
                // Получить все карты:
                const cardComponentsList = this.querySelectorAll( "room-card" )
                // Обойти карты:
                for ( const cardComponent of cardComponentsList ) {
                    // Получить имя карты:
                    const cardName = cardComponent.dataset.value + cardComponent.dataset.suit
                    // Если нет такой доступной карты:
                    if ( available_cards.indexOf( cardName ) === -1 ) {
                        // Установить прозрачность:
                        cardComponent.dataset.ghost = true
                    }
                }
            }
        }
        /**
         * Этот метод сбрасывает доступные карты.
         * 
         * @returns undefined
         */
        #resetAvailableCards() {
            // Сбросить статусы карт:
            this.#resetCardsStatus()
        }
        /**
         * Этот метод показывает подбрасываемые карты.
         * 
         * @param {Array}  throwable_cards  Текущие подбрасываемые карты.
         * @returns undefined
         */
        #setThrowableCards( throwable_cards ) {
            // Сбросить подбрасываемые карты:
            this.#resetThrowableCards()
            // Если установлены подбрасываемые карты:
            if ( throwable_cards !== null ) {
                // Получить все карты:
                const cardComponentsList = this.querySelectorAll( "room-card" )
                // Обойти карты:
                for ( const cardComponent of cardComponentsList ) {
                    // Получить имя карты:
                    const cardName = cardComponent.dataset.value + cardComponent.dataset.suit
                    // Если нет такой доступной карты:
                    if ( throwable_cards.indexOf( cardName ) === -1 ) {
                        // Установить прозрачность:
                        cardComponent.dataset.ghost = true
                    }
                }
            }
        }
        /**
         * Этот метод сбрасывает подбрасываемые карты.
         * 
         * @returns undefined
         */
        #resetThrowableCards() {
            // Сбросить статусы карт:
            this.#resetCardsStatus()
        }
        /**
         * Этот метод сбрасывает подбрасываемые карты.
         * 
         * @returns undefined
         */
        #resetCardsStatus() {
            // Получить все погашенные карты:
            const ghostCards = this.querySelectorAll( `room-card[data-ghost]` )
            // Для всех погашеных:
            for ( const ghostCardComponent of ghostCards ) {
                // Удалить атрибут:
                delete ghostCardComponent.dataset.ghost
            }
        }
        /**
         * Этот метод устанавливает обработчики событий карты.
         * 
         * @returns undefined
         */
        #setCardEventsHandlers( card ) {
            // Запоминать стартовые позиции:
            let initialX, initialY
              , offsetX, offsetY
            // Установка обработки двойного отпускания кнопки:
            card.addEventListener(
                "mousedown"
              , event => {
                    // Записать стартовые позиции:
                    initialX = event.clientX
                    initialY = event.clientY
                    offsetX = event.offsetX
                    offsetY = event.offsetY
                }
            )
            // Установка обработки начала касания:
            card.addEventListener(
                "touchstart"
              , event => {
                    // Записать стартовые позиции:
                    initialX = event.touches[ 0 ].clientX
                    initialY = event.touches[ 0 ].clientY
                    // Получить позицию текущего элемента:
                    const { x, y } = card.getBoundingClientRect()
                    // Посчитать смещение:
                    offsetX = event.touches[ 0 ].clientX - x
                    offsetY = event.touches[ 0 ].clientY - y
                }
            )
            // Установка обработки поднятия:
            card.addEventListener(
                "mouseup"
              , event => {
                    // Получить имя карты текущего элемента:
                    const cardName = card.dataset.value + card.dataset.suit
                    // Если значение отличается больше, чем на 5px:
                    if ( ( Math.abs( initialX - event.clientX ) < 5 )
                      || ( Math.abs( initialY - event.clientY ) < 5 )
                       ) {
                        // Если текущее состояние игрока защита:
                        if ( state.roomPlayer.defend == true ) {
                            // Если текущая карта активная:
                            if ( state.roomPlayer.active_cards.indexOf( cardName ) !== -1 ) {
                                // Получить активную карту борда:
                                const activeCardName = state.roomBoard.active
                                // Создать запрос защиты:
                                const defendRequest = new RoomDefendRequest(
                                    state.roomId
                                  , cardName
                                  , activeCardName
                                )
                                // Отправить запрос на сервер:
                                state.client.server.execute(
                                    defendRequest
                                )
                            }
                        }
                        // Если текущее состояние игрока атака:
                        if ( state.roomPlayer.attack == true ) {
                            // Если текущая карта подбрасываемая:
                            if ( ( state.roomPlayer.throwable_cards === null )
                              || ( state.roomPlayer.throwable_cards.indexOf( cardName ) !== -1 ) 
                               ) {
                                // Создать запрос атаки:
                                const attackRequest = new RoomAttackRequest(
                                    state.roomId
                                  , cardName
                                )
                                // Отправить запрос на сервер:
                                state.client.server.execute( attackRequest )
                            } 
                        }
                        // Выйти из обработчика:
                        return
                    }
                    // Если текущее состояние игрока защита:
                    if ( state.roomPlayer.defend == true ) {
                        // Если текущая карта активная:
                        if ( state.roomPlayer.active_cards.indexOf( cardName ) !== -1 ) {
                            // Получить целевую карту борда:
                            const targetCardName = this.#getTargetCardName(
                                card
                              , offsetX
                              , offsetY
                              , event.clientX
                              , event.clientY
                            )
                            // Если карта есть:
                            if ( targetCardName !== undefined ) {
                                // Создать запрос защиты:
                                const defendRequest = new RoomDefendRequest(
                                    state.roomId
                                  , cardName
                                  , targetCardName
                                )
                                // Отправить запрос на сервер:
                                state.client.server.execute(
                                    defendRequest
                                )
                            }
                        }
                    }
                    // Если текущее состояние игрока атака:
                    if ( state.roomPlayer.attack == true ) {
                        // Если текущая карта подбрасываемая:
                        if ( ( state.roomPlayer.throwable_cards === null )
                          || ( state.roomPlayer.throwable_cards.indexOf( cardName ) !== -1 ) 
                           ) {
                            // Создать запрос атаки:
                            const attackRequest = new RoomAttackRequest(
                                state.roomId
                              , cardName
                            )
                            // Отправить запрос на сервер:
                            state.client.server.execute( attackRequest )
                        } 
                    }
                }
            )
            // Установка обработки поднятия:
            card.addEventListener(
                "touchend"
              , event => {
                    // Если значение отличается больше, чем на 5px:
                    if ( ( Math.abs( initialX - event.changedTouches[ 0 ].clientX ) < 5 )
                      || ( Math.abs( initialY - event.changedTouches[ 0 ].clientY ) < 5 )
                       ) {
                        // Если текущее состояние игрока защита:
                        if ( state.roomPlayer.defend == true ) {
                            // Если текущая карта активная:
                            if ( state.roomPlayer.active_cards.indexOf( cardName ) !== -1 ) {
                                // Получить активную карту борда:
                                const activeCardName = state.roomBoard.active
                                // Создать запрос защиты:
                                const defendRequest = new RoomDefendRequest(
                                    state.roomId
                                  , cardName
                                  , activeCardName
                                )
                                // Отправить запрос на сервер:
                                state.client.server.execute(
                                    defendRequest
                                )
                            }
                        }
                        // Если текущее состояние игрока атака:
                        if ( state.roomPlayer.attack == true ) {
                            // Если текущая карта подбрасываемая:
                            if ( ( state.roomPlayer.throwable_cards === null )
                              || ( state.roomPlayer.throwable_cards.indexOf( cardName ) !== -1 ) 
                               ) {
                                // Создать запрос атаки:
                                const attackRequest = new RoomAttackRequest(
                                    state.roomId
                                  , cardName
                                )
                                // Отправить запрос на сервер:
                                state.client.server.execute( attackRequest )
                            } 
                        }
                        // Выйти из обработчика:
                        return
                    }
                    // Получить имя карты текущего элемента:
                    const cardName = card.dataset.value + card.dataset.suit
                    // Если текущее состояние игрока защита:
                    if ( state.roomPlayer.defend == true ) {
                        // Если текущая карта активная:
                        if ( state.roomPlayer.active_cards.indexOf( cardName ) !== -1 ) {
                            // Получить целевую карту борда:
                            const targetCardName = this.#getTargetCardName(
                                card
                              , offsetX
                              , offsetY
                              , event.changedTouches[ 0 ].clientX
                              , event.changedTouches[ 0 ].clientY
                            )
                            // Если карта есть:
                            if ( targetCardName !== undefined ) {
                                // Создать запрос защиты:
                                const defendRequest = new RoomDefendRequest(
                                    state.roomId
                                  , cardName
                                  , targetCardName
                                )
                                // Отправить запрос на сервер:
                                state.client.server.execute(
                                    defendRequest
                                )
                            }
                        }
                    }
                    // Если текущее состояние игрока атака:
                    if ( state.roomPlayer.attack == true ) {
                        // Если текущая карта подбрасываемая:
                        if ( ( state.roomPlayer.throwable_cards === null )
                          || ( state.roomPlayer.throwable_cards.indexOf( cardName ) !== -1 ) 
                           ) {
                            // Создать запрос атаки:
                            const attackRequest = new RoomAttackRequest(
                                state.roomId
                              , cardName
                            )
                            // Отправить запрос на сервер:
                            state.client.server.execute( attackRequest )
                        } 
                    }
                }
            )
        }
        /**
         * Этот метод получает целевую карту борда для текущей карты.
         * 
         * @param {Component} card              Компонент карты.
         * @param {Number} mousedownOffsetX     Смещение клика по x.
         * @param {Number} mousedownOffsetY     Смещение клика по y.
         * @param {Number} mouseupX             Позиция отпускания кнопки по x.
         * @param {Number} mouseupY             Позиция отпускания кнопки по y.
         * @returns {String}
         */
        #getTargetCardName( card, mousedownOffsetX, mousedownOffsetY, mouseupX, mouseupY ) {
            // Получить баунды текущей карты:
            var { width, height } = card.getBoundingClientRect()
            // Установить исходные значения:
            const x1 = mouseupX - mousedownOffsetX
                , x2 = x1 + width
                , y1 = mouseupY - mousedownOffsetY
                , y2 = y1 + height
            // Получить карты борда:
            const boardComponent = globalThis.document.querySelector( "room-board" )
                , boardCards = boardComponent.querySelectorAll( "room-card:not([data-position='top'])" )
            // Создать контейнер для баундов карт:
            const boundsMap = new Map()
            // Обойти карты борда:
            for ( const boardCard of boardCards ) {
                // Получить текущие значения баундов:
                var { width, height, x, y } = boardCard.firstChild.getBoundingClientRect()
                // Назвать переменные:
                const yn1 = y
                    , yn2 = y + height
                // Отсечь по верху:
                if ( ( ( yn1 < y1 ) && ( yn2 - y2 < 5 ) )
                  || ( ( yn2 > y2 ) && ( y2 - yn1 < 5 ) ) 
                   ) {
                    // Выйти, потому что пересечено менее чем на 5px:
                    return undefined
                } 
                // Проверить, что карты пересечены или покрыты по y:
                if ( ( ( y1 >= yn1 ) && ( y1 <= yn2 ) )
                  || ( ( y2 >= yn1 ) && ( y2 <= yn2 ) )
                  || ( ( yn1 >= y1 ) && ( yn2 <= y2 ) )
                   ) {
                    // Сохранить диапазоны x:
                    boundsMap.set( [ x, x + width ], boardCard )
                }
            }
            // Создать карту для затронутых карт:
            const touchedCards = new Map()
            // Запоминать минимумы:
            const minimalIntersections = new Set()
            // Найти вхождение точек по x:
            for ( const [ bounds, touchedCard ] of boundsMap ) {
                // Если обе точки входят в цель:
                if ( ( bounds[ 0 ] >= x1 )
                  && ( bounds[ 1 ] >= x1 )
                  && ( bounds[ 0 ] <= x2 )
                  && ( bounds[ 1 ] <= x2 )
                   ) {
                    // Это точно нужная карта:
                    touchedCards.set( 1, touchedCard )
                    // Не продолжать поиск:
                    break
                }  
                // Если точка x1 входит в диапазон:
                if ( ( bounds[ 0 ] <= x1 )
                  && ( bounds[ 1 ] >= x1 )
                   ) {
                    // Получить максимальную ширину:
                    var maxIntersection = Math.max(
                        Math.abs( bounds[ 0 ] - x1 )
                      , Math.abs( bounds[ 1 ] - x1 )
                    )
                    // Получить минимальную ширину:
                    var minIntersection = Math.min(
                        Math.abs( bounds[ 0 ] - x1 )
                      , Math.abs( bounds[ 1 ] - x1 )
                    )
                    // Добавить затронутую карту:
                    touchedCards.set( maxIntersection, touchedCard )
                    // Добавить минимум:
                    minimalIntersections.add( minIntersection )
                }
                // Если точка x2 входит в диапазон:
                if ( ( bounds[ 0 ] <= x2 )
                  && ( bounds[ 1 ] >= x2 )
                   ) {
                    // Получить X коэффициент:
                    var maxIntersection = Math.max(
                        Math.abs( bounds[ 0 ] - x2 )
                      , Math.abs( bounds[ 1 ] - x2 )
                    )
                    // Получить X коэффициент:
                    var minIntersection = Math.min(
                        Math.abs( bounds[ 0 ] - x1 )
                      , Math.abs( bounds[ 1 ] - x1 )
                    )
                    // Добавить затронутую карту:
                    touchedCards.set( maxIntersection, touchedCard )
                    // Добавить минимум:
                    minimalIntersections.add( minIntersection )
                }
            }
            // Если есть затронутые карты:
            if ( touchedCards.size > 0 ) {
                // Получить максимальный коэффициент:
                const maxIntersection = Math.max( ...touchedCards.keys() )
                    , minIntersection = Math.min( ...minimalIntersections.keys() )
                // Если затронута одна карта:
                if ( touchedCards.size == 1 ) {
                    // Если минимальный коэфициент меньше 5px:
                    if ( minIntersection < 5 ) {
                        // Не засчитывать пересечение:
                        return undefined
                    }
                }
                // Получить карту с максимальный коэффициентом:
                const touchedCard = touchedCards.get( maxIntersection )
                // Вернуть имя карты:
                return touchedCard.dataset.value + touchedCard.dataset.suit
            }
            // Иначе вернуть undefined:
            return undefined
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-hand", RoomHand )
</script>