/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс входящих сообщений:
import Incoming from "../../incoming.mjs"
// Использовать объект состояния:
import { state } from "../../../../main.mjs"

/**
 * Этот класс описывает входящее сообщение "freeze". 
 */
export default class Freeze
    extends Incoming
{
    /**
     * Этот метод тестирует полученное сообщение и возвращает true, если сообщение
     * может быть обработано текущим экземпляром объекта входящего сообщения.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns {Boolean}
     */
    test( message ) {
        return ( message.channel.startsWith( "table_" ) == true )
            && ( message.event === "freeze" )
    }
    /**
     * Этот метод обрабатывает получение сообщения из сокета.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns undefined
     */
    process( message ) {
        // Если это текущий игрок:
        if ( message.data.seat == state.roomPlayer.seat ) {
            // Открыть оверлей окна заморозки:
            state.currentOverlay = "window-freeze"
        }
    }
}