/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс входящих сообщений:
import Incoming from "../../incoming.mjs"
// Использовать объект состояния:
import { state } from "../../../../main.mjs"

/**
 * Этот класс описывает входящее сообщение "state". 
 */
export default class State
    extends Incoming
{
    /**
     * Этот метод тестирует полученное сообщение и возвращает true, если сообщение
     * может быть обработано текущим экземпляром объекта входящего сообщения.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns {Boolean}
     */
    test( message ) {
        return ( message.channel.startsWith( "table_" ) == true )
            && ( message.event === "state" )
    }
    /**
     * Этот метод обрабатывает получение сообщения из сокета.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns undefined
     */
    process( message ) {
        // Обновление текущего игрока:
        Object.assign( state.roomPlayer, message.data.state )
    }
}