/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс объектов роута:
import Route from "../route.mjs"
// Использовать сообщение подписки на канал сообщений:
import SubscribeMessage from "../message/outgoing/subscribe.mjs"
// Использовать сообщение отписки от канала сообщений:
import UnsubscribeMessage from "../message/outgoing/unsubscribe.mjs"
// Использовать запрос состояния комнаты:
import RoomGetStateRequest from "../request/roomgetstate.mjs"
// Использовать запрос состояния комнаты:
import RoomLeaveRequest from "../request/roomleave.mjs"
// Использовать запрос входа в чат:
import ChatEnterRequest from "../request/chatenter.mjs"
// Использовать запрос выхода из чата:
import ChatLeaveRequest from "../request/chatleave.mjs"
// Использовать запрос пинга чата:
import ChatPingRequest from "../request/chatping.mjs"
// Использовать объект состояния:
import { state } from "../../main.mjs"

/**
 * Этот класс представляет объект роута обработки перехода на url игровой комнаты.
 */
export default class Room
    extends Route
{
    /** @property {Set}                 Набор объектов подписчиков роута. */
    #subscribers      = new Set()
    /** @property {Number}              Интервал пинга чата комнаты. */
    #chatPingInterval = undefined 
    /**
     * Этот метод тестирует переданный объект URL и возвращает true, если он может быть обработан
     * текущим объектом роута.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns {Boolean}
     */
    test( url, historyState ) {
        // Проверка запроса пути:
        return url.pathname === "/room"
    }
    /**
     * Этот метод обрабатывает открытие роута в приложении.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns undefined
     */
    async open( url, historyState ) {
        // Получить объекты сокета и сервера:
        const { socket, server } = state.client
        // Получить идентификатор комнаты из URL:
        const roomId = url.searchParams.get( "id" )
        // Выполнить запрос на получение данных комнаты:
        await server.execute( new RoomGetStateRequest( roomId ) )
        // Создать запрос на подписку канала стола:
        const tableSubscriber = new SubscribeMessage( `table_${ roomId }` )
        // Подписаться на канал:
        await socket.transmit( tableSubscriber, true )
        // Создать запрос на подписку канала чата стола:
        const chatSubscriber = new SubscribeMessage( `chat_${ roomId }` )
        // Подписаться на канал:
        socket.transmit( chatSubscriber, true )
        // Сохранить подписчики:
        this.#subscribers.add( tableSubscriber )
        this.#subscribers.add( chatSubscriber )
        // Выполнить запрос входа в чат:
        server.execute( new ChatEnterRequest( roomId ) )
        // Создать интервал пинга чата:
        this.#chatPingInterval = setInterval(
            () => server.execute( new ChatPingRequest( roomId ) )
          , 30000
        )
        // Установить текущий идентификатор комнаты в состояние:
        state.roomId = roomId
        // Установить текущий экран в состояние:
        state.currentScreen = "room-container"
        // Создать новый контейнер сообщений чата:
        state.chatMessages = new Set()
    }
    /**
     * Этот метод обрабатывает закрытие роута в приложении.
     * 
     * @param {URL}    url              Объект закрываемого адреса в приложении.
     * @param {Object} historyState     Объект закрываемого состояния из истории.
     * @returns undefined
     */
    async close( url, historyState ) {
        // Получить объекты сокета и сервера:
        const { socket, server } = state.client
        // Получить идентификатор комнаты из URL:
        const roomId = url.searchParams.get( "id" )
        // Отправить запрос на отписку канала стола и чата:
        socket.transmit( new UnsubscribeMessage( `table_${ roomId }` ) )
        socket.transmit( new UnsubscribeMessage( `chat_${ roomId }` ) )
        // Сбросить восстановление подписчиков:
        for ( const subscriber of this.#subscribers.values() ) {
            // Отключить подписчик от восстановления при переподключении:
            socket.dismiss( subscriber )
        }
        // Сбросить интервал пинга чата:
        clearInterval( this.#chatPingInterval )
        this.#chatPingInterval = undefined
        // Выполнить запрос выхода из чата:
        server.execute( new ChatLeaveRequest( roomId ) )
        // Сбросить текущий идентификатор комнаты из состояния:
        delete state.roomId
        // Сбросить текущий экран из состояния:
        delete state.currentScreen
        // Сбросить контейнер сообщений чата:
        delete state.chatMessages
    }
    /**
     * Этот метод обрабатывает закрытие окна приложения. Возврат false может остановить
     * выход из приложения с сообщением о несохраненных данных.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns {Boolean}
     */
    quit( url, historyState ) {
        // Получить объекты сокета и сервера:
        const { server } = state.client
        // Получить идентификатор комнаты из URL:
        const roomId = url.searchParams.get( "id" )
        // Отправить запрос на выход:
        server.execute( new RoomLeaveRequest( roomId ) )
        return true
    }
}