<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_container_template">
    <template-event name="click" listener="onContainerClick">
        <div class="turn-device-message">
            <div>
                <p>Пожалуйста поверните устройство или коснитесь экрана для начала игры</p>
                <img src="../assets/images-original/common/turn-screen.svg" width="50%">
            </div>
        </div>
        <section class="playing-room">
            <div class="playing-room__container">
                <room-table></room-table>
            </div>
        </section>
    </template-event>
    <room-sounds></room-sounds>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"
    // Использовать библиотеку nosleep:
    import NoSleep from "nosleep.js"

    /**
     * Этот класс описывает компонент <room-container>.
     */
    class RoomContainer
        extends Templated( Component, "room_container_template" )
    {
        /** @property {MediaQueryList}          Объект запроса изменения ширины окна. */
        #mediaQueryMobile = globalThis.matchMedia( "(max-width: 1024px)" )
        /** @property {NoSleep}             Объект борьбы с засыпанием устройства. */
        #noSleepInstance = new NoSleep()
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomId" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени свойства:
            switch ( transaction.name ) {
                // Если изменен идентификатор комнаты:
                case "roomId":
                    // Установка содержания поля ввода:
                    this.#setRoomId( transaction.value )
                    break
            }
        }
        /**
         * Этот метод устанавливает идентификатор текущей комнаты.
         * 
         * @param {String} id           Идентификатор (номер) комнаты.
         * @returns undefined
         */
        #setRoomId( id ) {
            // Установить заголовок окна браузера:
            globalThis.document.title = `Дурак подкидной / Комната №${ id }`
        }
        /**
         * Этот метод обрабатывает клик на контейнере игры.
         * 
         * @returns undefined
         */
        onContainerClick() {
            // Проверка на мобильный вьюпорт:
            if ( this.#mediaQueryMobile.matches == true ) {
                // Если блокировка не установлена:
                if ( this.#noSleepInstance.isEnabled == false ) {
                    // Установить блокировку:
                    this.#noSleepInstance.enable()
                }
                // Если нет полноэкранного элемента:
                if ( globalThis.document.fullscreenElement == null ) {
                    // Попробовать сделать этот элемент полноэкранным:
                    this.requestFullscreen( { "navigationUI": "hide" } )
                }
                // Принудительно перейти в пейзажный режим:
                try {
                    globalThis.screen.orientation.lock( "landscape" )
                }
                catch( e ) {}
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-container", RoomContainer )
</script>
<!-- Стили компонента: -->
<style>
    .turn-device-message {
        display: none;
    }
    @media screen and (orientation: portrait) {
        .playing-room {
            display: none;
        }
        .modal {
            display: none !important;
        }
        .turn-device-message {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            color: #fff;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .turn-device-message div {
            padding: 0 2rem;
            text-align: center;
        }

        .turn-device-message img {
            margin-top: 2rem;
        }
    }
</style>