/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/room_chat/leaveChat".
 */
export default class ChatLeave
    extends Request
{
    /**
     * Конструктор объекта класса ChatLeave.
     * 
     * @param {Number} roomId           Идентификатор комнаты.
     */
    constructor( roomId ) {
        super( "/room_chat/leaveChat", "POST" )
        // Установка значений переменных объекта:
        this.body = { "room_id": roomId }
    }
}