/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс сообщения вебсокет:
import Message from "../message.mjs"

/**
 * Этот класс описывает объект исходящего сообщения WebSocket.
 */
export default class Outgoing
    extends Message
{}