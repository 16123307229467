/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/room/defend".
 */
export default class RoomDefendRequest
    extends Request
{
    /**
     * Конструктор объекта класса RoomDefendRequest.
     * 
     * @param {Number} roomId           Идентификатор комнаты.
     * @param {String} card             Карта защиты.
     * @param {String} targetCard       Целевая карта.
     */
    constructor( roomId, card, targetCard ) {
        super( "/room/defend", "POST" )
        // Установка значений переменных объекта:
        this.body = { "room_id": roomId
                    , "card": card
                    , "target_card": targetCard
                    }
    }
}