<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_table_template">
    <div class="playing-room__table">
        <room-info></room-info>
        <room-deck></room-deck>
        <room-enemies></room-enemies>
        <room-controls></room-controls>
        <room-board data-drop="true"></room-board>
        <room-player></room-player>
        <room-chat></room-chat>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-table>.
     */
    class RoomTable
        extends Templated( Component, "room_table_template" )
    {}
    // Определение элемента:
    globalThis.customElements.define( "room-table", RoomTable )
</script>