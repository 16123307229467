/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/**
 * Этот класс описывает объект запроса на веб-сервер API.
 */
export default class Request
{
    /** @static {Set}                   Перечисление методов HTTP запросов. */
    static methods          = new Set( [ "GET", "POST", "PUT", "PATCH", "DELETE", "CONNECT", "HEAD", "TRACE", "OPTIONS" ] )
    /** @static {Set}                   Перечисление типов ответа. */
    static responseTypes    = new Set( [ "text", "json", "formData", "blob", "arrayBuffer", "stream" ] )
    /** @static {Set}                   Перечисление режимов кросс-доменного запроса. */
    static corsModes        = new Set( [ "cors", "no-cors", "same-origin" ] )
    /** @static {Set}                   Перечисление режимов передачи авторизации. */
    static credentialModes  = new Set( [ "omit", "same-origin", "include" ] )
    /** @static {Set}                   Перечисление режимов кеширования. */
    static cacheModes       = new Set( [ "default", "no-store", "reload", "no-cache", "force-cache", "only-if-cached" ] )
    /** @static {Set}                   Перечисление режимов реакции на редирект. */
    static redirectModes    = new Set( [ "follow", "error", "manual" ] )
    /** @static {Set}                   Перечисление режимов реакции на редирект. */
    static referrerPolicies = new Set( [ "no-referrer", "no-referrer-when-downgrade", "same-origin", "origin"
                                       , "strict-origin", "origin-when-cross-origin", "strict-origin-when-cross-origin", 
                                       , "unsafe-url"
                                       ] 
                                     )
    /** @property {String}              Относительный адрес ресурса сервера. */
    #uri             = undefined
    /** @property {String}              Метод запроса. */
    #method          = undefined
    /** @property {String}              Предполагаемый тип ответа. */
    #responseType    = undefined
    /** @property {HTMLElement}         Элемент инициатор запроса. */
    #initiator       = null
    /** @property {HTMLElement}         Элемент отправитель запроса. */
    #submitter       = null
    /** @property {Headers}             Заголовки запроса. */
    #headers         = new Headers()
    /** @property {Object}              Тело запроса. */
    #body            = undefined
    /** @property {String}              Режим CORS. */
    #mode            = undefined
    /** @property {String}              Режим передачи данных авторизации. */
    #credentials     = undefined
    /** @property {String}              Режим работы кеша. */
    #cache           = undefined
    /** @property {String}              Режим реакции на редирект. */
    #redirect        = undefined
    /** @property {String}              Ссылка источника запроса. */
    #referrer        = undefined
    /** @property {String}              Режим передачи источника запроса. */
    #referrerPolicy  = undefined
    /** @property {String}              Проверка целостности ответа (SRI). */
    #integrity       = undefined
    /** @property {String}              Режим keep-alive для соединения. */
    #keepalive       = undefined
    /** @property {Number}              Таймаут выполнения запроса. */
    #timeout         = 0
    /** @property {Boolean}             Статус ошибки выполнения запроса. */
    #error           = false
    /** @property {Object}              Объект ошибки выполнения запроса. */
    #errorObject     = undefined
    /**
     * Конструктор объекта класса Request.
     * 
     * @param {String} uri              Относительный адрес ресурса сервера.
     * @param {String} [method]         Метод запроса.
     * @param {String} [responseType]   Тип получаемого содержания.
     */
    constructor( uri, method = "GET", responseType = "json" ) {
        // Установка значений свойств объекта:
        this.uri          = uri
        this.method       = method
        this.responseType = responseType
    }
    /**
     * Этот геттер возвращает текущее установленное значение URI запроса. 
     * 
     * @returns {String}
     */
    get uri() {
        return this.#uri
    }
    /**
     * Этот сеттер устанавливает значение URI для текущего объекта.
     * 
     * @param {String} resource         Имя запрашиваемого ресурса.  
     * @returns undefined
     */
    set uri( resource ) {
        // Проверить, что имя ресурса относительно:
        if ( resource.startsWith( "/" ) == true ) {
            this.#uri = resource
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                "Request: Имя ресурса может быть задано только относительно сервера."
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение метода запроса.
     * 
     * @returns {String}
     */
    get method() {
        return this.#method
    }
    /**
     * Этот сеттер устанавливает значение имени HTTP-метода для текущего объекта.
     * Поддерживаются значения "get", "post", "put", "delete", "connect", "head", 
     * "trace", "patch", "options" в любом регистре.
     * 
     * @param {String} name             Имя HTTP-метода запроса. 
     * @returns undefined
     */
    set method( name ) {
        // Проверка, что значение поддерживается:
        if ( Request.methods.has( name.toUpperCase() ) == true ) {
            // Установить метод:
            this.#method = name.toUpperCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя метода "${ name }". Поддерживаются значения `
              + `"get", "post", "put", "delete", "connect", "head", "trace", "patch", `
              + `"options" в любом регистре.`
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение типа (результата) запроса.
     * 
     * @returns {String}
     */
    get responseType() {
        return this.#responseType
    }
    /**
     * Этот сеттер устанавливает значение типа ответа для текущего объекта. Значениями 
     * могут быть: "text", "json", "formData", "blob", "arrayBuffer" и "stream".
     * 
     * @param {String} name             Тип ожидаемого ответа на запрос. 
     * @returns undefined
     */
    set responseType( name ) {
        // Проверка, что значение поддерживается:
        if ( Request.responseTypes.has( name.toLowerCase() ) == true ) {
            // Установить тип ответа:
            this.#responseType = name.toLowerCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя типа результата "${ name }". Значениями `
              + ` могут быть: "text", "json", "formData", "blob", "arrayBuffer" и "stream".`
            )
        }
    }
    /**
     * Этот геттер возвращает текущий объект-элемент инициатора (напр. формы).
     * 
     * @returns {HTMLElement}
     */
    get initiator() {
        return this.#initiator
    }
    /**
     * Этот сеттер устанавливает значение объекта-элемента инициатора для текущего объекта.
     * 
     * @param {HTMLElement} element         Объект элемента инициатора. 
     * @returns undefined
     */
    set initiator( element ) {
        // Проверка типа аргумента:
        if ( element instanceof HTMLElement ) {
            // Установить элемент инициатора:
            this.#initiator = element
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                "Request: Некорректное тип аргумента, ожидался элемент DOM."
            )
        }
    }
    /**
     * Этот геттер возвращает текущий объект-элемент отправителя (напр. кнопки submit).
     * 
     * @returns {HTMLElement}
     */
    get submitter() {
        return this.#submitter
    }
    /**
     * Этот сеттер устанавливает значение  для текущего объекта.
     * 
     * @param {} v              Значение 
     * @returns undefined
     */
    set submitter( element ) {
        // Проверка типа аргумента:
        if ( element instanceof HTMLElement ) {
            // Установить элемент инициатора:
            this.#submitter = element
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                "Request: Некорректный тип аргумента, ожидался элемент DOM."
            )
        }
    }
    /**
     * Этот геттер возвращает текущий объект заголовков.
     * 
     * @returns {Header}
     */
    get headers() {
        return this.#headers
    }
    /**
     * Этот геттер возвращает текущее установленное значение тела запроса.
     * 
     * @returns {Object}
     */
    get body() {
        return this.#body
    }
    /**
     * Этот сеттер устанавливает значение тела запроса для текущего объекта. Значением
     * может быть строка (например, в формате JSON), объект FormData для отправки данных 
     * как form/multipart и Blob, ArrayBuffer, ArrayBufferView. Если передан примитивный
     * объект он будет сериализован в json.
     * 
     * @param {Object} data             Объект представляющий тело запроса.
     * @returns undefined
     */
    set body( data ) {
        // Если данные являются примитивным объектом:
        if ( data.constructor === Object ) {
            // Добавить "Content-Type" заголовок:
            this.headers.set( "Content-Type", "application/json" )
            // Сменить тип на json-строку:
            data = JSON.stringify( data )
        }
        // Проверка типа аргумента:
        if ( ( data.constructor === String )
          || ( data instanceof FormData )
          || ( data instanceof Blob )
          || ( data instanceof URLSearchParams )
          || ( data instanceof ArrayBuffer )
          || ( data instanceof Uint8Array )
          || ( data instanceof Uint8ClampedArray )
          || ( data instanceof Int16Array )
          || ( data instanceof Uint16Array )
          || ( data instanceof Int32Array )
          || ( data instanceof Uint32Array )
          || ( data instanceof Float32Array )
          || ( data instanceof Float64Array )
          || ( data instanceof DataView )
           ) {
            // Установить объект данных:
            this.#body = data
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                "Request: Некорректный тип аргумента, ожидался String, FormData, Blob, "
              + "File, ArrayBuffer, ArrayBufferView, URLSearchParams или примитивный Object."
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение режима запроса.
     * 
     * @returns {String}
     */
    get mode() {
        return this.#mode
    }
    /**
     * Этот сеттер устанавливает значение режима кросс-доменного запроса для 
     * текущего объекта. Значением может быть "cors", "no-cors", или "same-origin".
     * 
     * @param {String} value            Значение режима кросс-доменного запроса.
     * @returns undefined
     */
    set mode( value ) {
        // Проверка, что значение поддерживается:
        if ( Request.corsModes.has( value.toLowerCase() ) == true ) {
            // Установить тип кросс-доменного запроса:
            this.#mode = value.toLowerCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя режима кросс-доменного запроса "${ name }". `
              + `Значением может быть "cors", "no-cors", или "same-origin".`
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение режима передачи 
     * данных авторизации запроса.
     * 
     * @returns {String}
     */
    get credentials() {
        return this.#credentials
    }
    /**
     * Этот сеттер устанавливает значение режима передачи данных авторизации запроса
     * для текущего объекта. Значением может быть "omit", "same-origin", или "include".
     * 
     * @param {String} value            Значение режима передачи авторизации.
     * @returns undefined
     */
    set credentials( value ) {
        // Проверка, что значение поддерживается:
        if ( Request.credentialModes.has( value.toLowerCase() ) == true ) {
            // Установить тип передачи авторизации:
            this.#credentials = value.toLowerCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя режима передачи авторизации "${ name }". `
              + `Значением может быть "omit", "same-origin", или "include".`
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение настройки кеширования.
     * 
     * @returns {String}
     */
    get cache() {
        return this.#cache
    }
    /**
     * Этот сеттер устанавливает значение настройки кеширования для текущего объекта.
     * Значением может быть: "default", "no-store", "reload", "no-cache", "force-cache", 
     * и "only-if-cached".
     * 
     * @param {String} value            Значение режима кеширования запроса.
     * @returns undefined
     */
    set cache( value ) {
        // Проверка, что значение поддерживается:
        if ( Request.cacheModes.has( value.toLowerCase() ) == true ) {
            // Установить тип кеширования:
            this.#cache = value.toLowerCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя режима передачи авторизации "${ name }". `
              + `Значением может быть: "default", "no-store", "reload", "no-cache", `
              + `"force-cache", и "only-if-cached".`
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение реакции на редирект.
     * 
     * @returns {String}
     */
    get redirect() {
        return this.#redirect
    }
    /**
     * Этот сеттер устанавливает значение реакции на редирект для текущего объекта.
     * Значением может быть: "follow", "error" и "manual".
     * 
     * @param {String} value            Значение режима реакции на редирект.
     * @returns undefined
     */
    set redirect( mode ) {
        // Проверка, что значение поддерживается:
        if ( Request.redirectModes.has( mode.toLowerCase() ) == true ) {
            // Установить тип ответа:
            this.#redirect = mode.toLowerCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя режима реакции на редирект "${ name }". `
              + `Значением может быть: "follow", "error" и "manual".`
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение реферера.
     * 
     * @returns {String}
     */
    get referrer() {
        return this.#referrer
    }
    /**
     * Этот сеттер устанавливает значение реферера для текущего объекта.
     * 
     * @param {String} value            Значение реферера.
     * @returns undefined
     */
    set referrer( value ) {
        this.#referrer = value
    }
    /**
     * Этот геттер возвращает текущее установленное значение политики реферера.
     * 
     * @returns {String}
     */
    get referrerPolicy() {
        return this.#referrerPolicy
    }
    /**
     * Этот сеттер устанавливает значение политики передачи реферера для текущего объекта.
     * Значением может быть: "no-referrer", "no-referrer-when-downgrade", "same-origin", 
     * "origin", "strict-origin", "origin-when-cross-origin", "strict-origin-when-cross-origin", 
     * или "unsafe-url".
     * 
     * @param {String} value            Значение политики передачи реферера. 
     * @returns undefined
     */
    set referrerPolicy( value ) {
        // Проверка, что значение поддерживается:
        if ( Request.referrerPolicies.has( value.toLowerCase() ) == true ) {
            // Установить тип ответа:
            this.#referrerPolicy = value.toLowerCase()
        }
        // Иначе выбросить ошибку:
        else {
            throw new Error(
                `Request: Некорректное имя режима реакции на редирект "${ name }". `
              + `Значением может быть: "no-referrer", "no-referrer-when-downgrade", "same-origin", `
              + `"origin", "strict-origin", "origin-when-cross-origin", "strict-origin-when-cross-origin", `
              + `или "unsafe-url".`
            )
        }
    }
    /**
     * Этот геттер возвращает текущее установленное значение контрольной суммы.
     * 
     * @returns {String}
     */
    get integrity() {
        return this.#integrity
    }
    /**
     * Этот сеттер устанавливает значение  для текущего объекта.
     * 
     * @param {String} checksum              
     * @returns undefined
     */
    set integrity( checksum ) {
        this.#integrity = checksum
    }
    /**
     * Этот геттер возвращает текущее установленное значение режима Keep-Alive.
     * 
     * @returns {Boolean}
     */
    get keepalive() {
        return this.#keepalive
    }
    /**
     * Этот сеттер устанавливает значение  для текущего объекта.
     * 
     * @param {Boolean} mode            Значение флага. 
     * @returns undefined
     */
    set keepalive( mode ) {
        this.#keepalive = mode
    }
    /**
     * Этот геттер возвращает текущее установленное значение таймаута подключения.
     * 
     * @returns {Number}
     */
    get timeout() {
        return this.#timeout
    }
    /**
     * Этот сеттер устанавливает значение таймаута подключения для текущего объекта.
     * 
     * @param {Number} seconds          Количество секунд таймаута. 
     * @returns undefined
     */
    set timeout( seconds ) {
        this.#timeout = seconds
    }
    /**
     * Этот геттер возвращает текущий режим ошибочного запроса.
     * 
     * @returns {Boolean}
     */
    get error() {
        return this.#error
    }
    /**
     * Этот сеттер устанавливает режим ошибочного запроса для текущего объекта.
     * 
     * @param {Boolean} state           Значение флага.
     * @returns undefined
     */
    set error( state ) {
        this.#error = state
    }
    /**
     * Этот геттер возвращает текущий объект ошибки запроса.
     * 
     * @returns {Error}
     */
    get errorObject() {
        return this.#errorObject
    }
    /**
     * Этот сеттер устанавливает значение  для текущего объекта.
     * 
     * @param {Error} error             Объект ошибки. 
     * @returns undefined
     */
    set errorObject( error ) {
        this.#errorObject = error
    }
}