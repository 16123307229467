/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/**
 * Этот класс представляет объект роута -- адреса приложения переход на который вызывает
 * реакцию внутри интерфейса.
 */
export default class Route
{
    /**
     * Этот метод тестирует переданный объект URL и возвращает true, если он может быть обработан
     * текущим объектом роута.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns {Boolean}
     */
    test( url, historyState ) {
        return false
    }
    /**
     * Этот метод обрабатывает открытие роута в приложении.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns undefined
     */
    async open( url, historyState ) {
        return
    }
    /**
     * Этот метод обрабатывает обновление роута в приложении.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns undefined
     */
    async refresh( url, historyState ) {
        return await this.open( url, historyState )
    }
    /**
     * Этот метод обрабатывает закрытие роута в приложении.
     * 
     * @param {URL}    url              Объект закрываемого адреса в приложении.
     * @param {Object} historyState     Объект закрываемого состояния из истории.
     * @returns undefined
     */
    async close( url, historyState ) {
        return
    }
    /**
     * Этот метод обрабатывает закрытие окна приложения. Возврат false может остановить
     * выход из приложения с сообщением о несохраненных данных.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns {Boolean}
     */
    quit( url, historyState ) {
        return true
    }
}