/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать стили приложения:
import "./assets/sass/app.scss"
// Использовать компоненты приложения:
import "./components/application-loader.html"
import "./components/application-overlay.html"
import "./components/application-root.html"
import "./components/application-screen.html"
import "./components/room-board.html"
import "./components/room-card.html"
import "./components/room-chat.html"
import "./components/room-container.html"
import "./components/room-controls.html"
import "./components/room-deck.html"
import "./components/room-enemies.html"
import "./components/room-enemy.html"
import "./components/room-enemy-cards.html"
import "./components/room-hand.html"
import "./components/room-info.html"
import "./components/room-player.html"
import "./components/room-sounds.html"
import "./components/room-table.html"
import "./components/room-timer.html"
import "./components/window-enter.html"
import "./components/window-freeze.html"
import "./components/window-results.html"
import "./components/window-win.html"
// Использовать объект и функции состояния:
import State, { addStateEventListener, loggerEventHandler } from "./modules/state.mjs"
// Использовать объект клиента:
import Client from "./modules/client.mjs"
// Использовать объекта подключения к web api:
import Server from "./modules/server.mjs"
// Использовать объект подключения к websocket:
import Socket from "./modules/socket.mjs"
// Использовать объект роутера приложения:
import Router from "./modules/router.mjs"
// Использовать модуль состояния сессии:
import Session from "./modules/session.mjs"
// Использовать обработчики ответов API:
import ChatAddMessageResponse from "./modules/response/chat/addmessage.mjs"
import ChatEnterResponse from "./modules/response/chat/enterchat.mjs"
import ChatLeaveResponse from "./modules/response/chat/leavechat.mjs"
import ChatPingResponse from "./modules/response/chat/ping.mjs"
import RoomAttackResponse from "./modules/response/room/attack.mjs"
import RoomDefendResponse from "./modules/response/room/defend.mjs"
import RoomEnterResponse from "./modules/response/room/enter.mjs"
import RoomGetStateResponse from "./modules/response/room/getstate.mjs"
import RoomLeaveResponse from "./modules/response/room/leave.mjs"
import RoomPassResponse from "./modules/response/room/pass.mjs"
import RoomReadyResponse from "./modules/response/room/ready.mjs"
import RoomTakeResponse from "./modules/response/room/take.mjs"
import RoomUnfreezeResponse from "./modules/response/room/unfreeze.mjs"
import RoomUnreadyResponse from "./modules/response/room/unready.mjs"
import SessionCreateResponse from "./modules/response/session/create.mjs"
import SessionDestroyResponse from "./modules/response/session/destroy.mjs"
import SessionRefreshResponse from "./modules/response/session/refresh.mjs"
import SessionRegenerateResponse from "./modules/response/session/regenerate.mjs"
// Использовать обработчики сообщений с сервера:
import ChatAddMessage from "./modules/message/incoming/chat/addmessage.mjs"
import TableActiveCardMessage from "./modules/message/incoming/table/activecard.mjs"
import TableActivePlayerMessage from "./modules/message/incoming/table/activeplayer.mjs"
import TableAttackMessage from "./modules/message/incoming/table/attack.mjs"
import TableBeatCardMessage from "./modules/message/incoming/table/beatcard.mjs"
import TableBoardMessage from "./modules/message/incoming/table/board.mjs"
import TableBoardCardMessage from "./modules/message/incoming/table/boardcard.mjs"
import TableClearMessage from "./modules/message/incoming/table/clear.mjs"
import TableDealCardsMessage from "./modules/message/incoming/table/dealcards.mjs"
import TableDealStartMessage from "./modules/message/incoming/table/dealstart.mjs"
import TableDefendMessage from "./modules/message/incoming/table/defend.mjs"
import TableEndGameMessage from "./modules/message/incoming/table/endgame.mjs"
import TableEndMoveMessage from "./modules/message/incoming/table/endmove.mjs"
import TableEnterMessage from "./modules/message/incoming/table/enter.mjs"
import TableFoolMessage from "./modules/message/incoming/table/fool.mjs"
import TableFreezeMessage from "./modules/message/incoming/table/freeze.mjs"
import TableLeaveMessage from "./modules/message/incoming/table/leave.mjs"
import TableOpenedMessage from "./modules/message/incoming/table/opened.mjs"
import TablePassMessage from "./modules/message/incoming/table/pass.mjs"
import TablePopTrumpMessage from "./modules/message/incoming/table/poptrump.mjs"
import TablePublicStateMessage from "./modules/message/incoming/table/publicstate.mjs"
import TableReadyMessage from "./modules/message/incoming/table/ready.mjs"
import TableRemoveCardMessage from "./modules/message/incoming/table/removecard.mjs"
import TableStateMessage from "./modules/message/incoming/table/state.mjs"
import TableTakeMessage from "./modules/message/incoming/table/take.mjs"
import TableTakeCardsMessage from "./modules/message/incoming/table/takecards.mjs"
import TableUnfreezeMessage from "./modules/message/incoming/table/unfreeze.mjs"
import TableUnreadyMessage from "./modules/message/incoming/table/unready.mjs"
import TableWinMessage from "./modules/message/incoming/table/win.mjs"
// Использовать обработчики роутов приложения:
import OpenRoute from "./modules/route/open.mjs"
import RoomRoute from "./modules/route/room.mjs"

/** @export {State}                     Объект глобального состояния приложения. */
export const state = State.create( {
    "roomPlayer" : {}
  , "roomPlayers": new Map()
} )
// Установка обработчика события для состояния:
addStateEventListener( state, "mutate", loggerEventHandler )
// Публиковать стейт
globalThis.state = state
/**
 * Эта функция обрабатывает запуск приложения.
 * 
 * @param {Object} window               Объект окна приложения.
 * @param {String} containerId          Идентификатор корневого элемента вывода приложения.
 * @param {String} sessionPrefix        Префикс пользовательской сессии приложения.
 * @returns undefined
 */
export default async function main( window
                                  , containerId
                                  , sessionPrefix
                                  ) {
    // Получить корневой элемент приложения:
    const container = globalThis.document.getElementById( containerId )
    // Создать новый объект клиента приложения:
    const client = new Client() 
    // Создать объекты подключения к API, сокету и объект сессии:
    client.server  = new Server()
    client.socket  = new Socket()
    client.session = new Session( sessionPrefix )
    client.router  = new Router( window )
    // Разместить клиента в состоянии:
    state.client = client
    // Инициализация объектов ответа от API:
    client.server.subscribe(
        new ChatAddMessageResponse()
      , new ChatEnterResponse()
      , new ChatLeaveResponse()
      , new ChatPingResponse()
      , new RoomAttackResponse()
      , new RoomDefendResponse()
      , new RoomEnterResponse()
      , new RoomGetStateResponse()
      , new RoomLeaveResponse()
      , new RoomPassResponse()
      , new RoomReadyResponse()
      , new RoomTakeResponse()
      , new RoomUnfreezeResponse()
      , new RoomUnreadyResponse()
      , new SessionCreateResponse()
      , new SessionDestroyResponse()
      , new SessionRefreshResponse()
      , new SessionRegenerateResponse()
    )
    // Инициализация объектов реакции на сообщения с сервера:
    client.socket.subscribe(
        new ChatAddMessage()
      , new TableActiveCardMessage()
      , new TableActivePlayerMessage()
      , new TableAttackMessage()
      , new TableBeatCardMessage()
      , new TableBoardMessage()
      , new TableBoardCardMessage()
      , new TableClearMessage()
      , new TableDealCardsMessage()
      , new TableDealStartMessage()
      , new TableDefendMessage()
      , new TableEndGameMessage()
      , new TableEndMoveMessage()
      , new TableEnterMessage()
      , new TableFoolMessage()
      , new TableFreezeMessage()
      , new TableLeaveMessage()
      , new TableOpenedMessage()
      , new TablePassMessage()
      , new TablePopTrumpMessage()
      , new TablePublicStateMessage()
      , new TableReadyMessage()
      , new TableRemoveCardMessage()
      , new TableStateMessage()
      , new TableTakeMessage()
      , new TableTakeCardsMessage()
      , new TableUnfreezeMessage()
      , new TableUnreadyMessage()
      , new TableWinMessage()
    )
    // Инициализация объектов обработки роутов в приложении:
    client.router.subscribe(
        new OpenRoute()
      , new RoomRoute()
    )
    // Установить обработчик выхода из приложения:
    window.addEventListener(
      "beforeunload"
     , event => {
            // Обработать выход при помощи роутера:
            if ( client.router.quit() == false ) {
                // Отменить стандартное действие:
                event.preventDefault()
            }
        }
    )
    // Вывести корневой элемент приложения:
    client.draw( container )
    // Выполнить навигацию к текущему URL:
    client.router.refresh()
}