/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/session/create".
 */
export default class SessionCreate
    extends Request
{
    /**
     * Конструктор объекта класса SessionCreate.
     * 
     * @param {Number} userId           Идентификатор пользователя.
     * @param {Number} timestamp        Штамп времени выписки токена.
     * @param {String} token            Токен авторизации.
     * @param {String} nonce            Примесь для хеша.
     */
    constructor( userId, timestamp, token, nonce ) {
        super( "/session/create", "POST" )
        // Установка значений переменных объекта:
        this.body = { "method": "token", userId, timestamp, token, nonce }
    }
}