/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/room/take".
 */
export default class RoomTake
    extends Request
{
    /**
     * Конструктор объекта класса RoomPass.
     * 
     * @param {Number} roomId           Идентификатор комнаты.
     */
    constructor( roomId ) {
        super( "/room/take", "POST" )
        // Установка значений переменных объекта:
        this.body = { "room_id": roomId }
    }
}