<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="window_win_template">
    <div class="modal room__1end" data-modal="room__1end">
        <div class="modal__overlay" data-close>
            <div class="room__1end-wrapper">
                <div class="room__1end-inner">
                    <p class="room__1end-title">ваш выигрыш</p>
                    <p class="room__1end-win" data-ref-win-amount></p>
                    <p class="room__1end-pfp">PFP COLD COINS</p>
                    <button class="button_gold playing-room-panel__button_gold room__1end-btn" data-close>	
                        ПРОДОЛЖИТЬ								
                    </button>
                    <picture>
                        <source srcset="../assets/images-compressed/room_1_end_main.webp" type="image/webp">
                        <img src="../assets/images-compressed/room_1_end_main.png"  class="room__1end-main" alt="">
                    </picture>
                    <picture>
                        <source srcset="../assets/images-compressed/room_1_end_bg1.webp" type="image/webp">
                        <img src="../assets/images-compressed/room_1_end_bg1.png" class="room__1end-bg1" alt="">
                    </picture>
                    <picture>
                        <source srcset="../assets/images-compressed/room_1_end_bg2.webp" type="image/webp">
                        <img src="../assets/images-compressed/room_1_end_bg2.png" class="room__1end-bg2" alt="">
                    </picture>
                    <picture>
                        <source srcset="../assets/images-compressed/room_1_end_bgcoins.webp" type="image/webp">
                        <img src="../assets/images-compressed/room_1_end_bgcoins.png" class="room__1end-bgcoins" alt="">
                    </picture>
                    <picture>
                        <source srcset="../assets/images-compressed/room_1_blick.webp" type="image/webp">
                        <img src="../assets/images-compressed/room_1_blick.png" class="room__1end-blick" alt="">
                    </picture>
                </div>	
            </div>
        </div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <window-win>.
     */
    class WindowWin
        extends Templated( Component, "window_win_template" )
    {
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "lastWin" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если изменен последний выигрыш:
                case "lastWin":
                    // Установка содержания поля вывода:
                    this.#setWinAmount( transaction.value.prize_coins )
                    break
            }
        }
        /**
         * Этот метод устанавливает количество выигрышных фишек в текущей валюте стола.
         * 
         * @param {Number} amount           Количество фишек.
         * @returns undefined
         */
        #setWinAmount( amount ) {
            // Получить элемент вывода выигрыша:
            const winAmountElement = this.querySelector( "[data-ref-win-amount]" )
            // Сохранить стартовое число и шаг:
            let current = amount - Math.floor( amount / 100 * 20 )
            // Установить число:
            winAmountElement.textContent = current
            // Установить интервал обновления значения:
            const interval = setInterval(
                () => {
                    // Обновить текущее число:
                    current += 10
                    // Если завершено, закончить:
                    if ( current >= amount ) {
                        clearInterval( interval )
                    }
                    // Обновить текущее выводимое значение:
                    winAmountElement.textContent = Math.min( current, amount )
                }
              , 100
            )
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "window-win", WindowWin )
</script>