<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_deck_template">
    <div class="playing-room__deck">
        <div data-ref-deck-trump></div>
        <div class="playing-room__deck-stack" data-ref-deck-stack></div>
        <div class="playing-room__deck-count" data-ref-deck-count></div>
    </div>
</template>
<!-- Шаблона элемента карты стека: -->
<template id="room_deck_stack_template">
    <div class="playing-room__deck-stack-card" data-ref-deck-card>
        <picture>
            <source srcset="../assets/images-compressed/gold_facedown.webp" type="image/webp">
            <img src="../assets/images-compressed/gold_facedown.png" width="105" alt="">
        </picture>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-deck>.
     */
    class RoomDeck
        extends Templated( Component, "room_deck_template" )
    {
        /** @property {Number}              Количество карт в колоде. */
        #cardsCount = 0
        /** @property {Set}                 Список анимаций колоды. */
        #animations = new Set()
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomBoard", "roomBoard.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если это первичный вывод колоды:
                case "roomBoard":
                    // Разбить транзакцию на события:
                    transaction.expand()
                    break
                // Если это модификация козыря:
                case "roomBoard.trump":
                    // Получить козыря:
                    var trump = transaction.value
                    // Если есть козырь:
                    if ( trump !== null ) {
                        // Разобрать карту козыря:
                        const [ trumpValue, trumpSuit ] = trump.split( "" )
                        // Установить текущего козыря:
                        this.#setDeckTrumpCard( trumpValue, trumpSuit )
                    }
                    // Если козыря нет:
                    else {
                        // Удалить из вывода козырь:
                        this.#resetDeckTrumpCard()
                    }
                    break
                // Если это модификация колоды:
                case "roomBoard.deck":
                    // Получить козырь и колоду:
                    var deck = transaction.value
                    // Если больше чем текущее:
                    if ( deck > this.#cardsCount ) {
                        // Установить количество карт в колоде:
                        this.#setDeckStackCards( deck )
                    }
                    // Если меньше чем текущее:
                    else {
                        // Выдать разницу:
                        this.#dealDeckCards( this.#cardsCount - deck )
                    }
            }
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Обновить количество:
            this.#updateDeckCount()
        }
        /**
         * Этот метод выполняется при отключении шаблонизированного элемента от тела документа.
         * 
         * @returns undefined
         */
        templateDisconnectedCallback() {
            // Сбросить текущие выводимые карты:
            this.#cardsCount = 0
        }
        /**
         * Этот метод сбрасывает текущие выводимые карты.
         * 
         * @returns undefined
         */
        #resetDeckCards() {
            // Получить контейнер козырной карты и колоды:
            const stackCardsContainer = this.querySelector( "[data-ref-deck-stack]" )
            // Сбросить содержание:
            stackCardsContainer.innerHTML = ""
            // Сбросить текущие выводимые карты:
            this.#cardsCount = 0
        }
        /**
         * Этот метод сбрасывает текущие выводимые карты.
         * 
         * @returns undefined
         */
        #resetDeckTrumpCard() {
            // Получить контейнер козырной карты и колоды:
            const stackTrumpCardContainer = this.querySelector( "[data-ref-deck-trump]" )
            // Сбросить содержание:
            stackTrumpCardContainer.innerHTML = ""
        }
        /**
         * Этот метод устанавливает карту текущего козыря.
         * 
         * @param {String} value            Значение карты.
         * @param {String} suit             Масть карты.
         * @returns undefined 
         */
        #setDeckTrumpCard( value, suit ) {
            // Выполнить сброс карты:
            this.#resetDeckTrumpCard() 
            // Получить контейнер козырной карты:
            const stackTrumpCardContainer = this.querySelector( "[data-ref-deck-trump]" )
            // Создать элемент карты:
            const cardComponent = globalThis.document.createElement( "room-card" )
            // Установить масть и значение карты:
            cardComponent.dataset.value = value
            cardComponent.dataset.suit = suit
            // Установить дополнительный класс карты:
            cardComponent.dataset.class = "playing-card_deck"
            // Установить подавленный режим по умолчанию:
            cardComponent.dataset.ghost = true
            // Добавить козырную карту:
            stackTrumpCardContainer.appendChild( cardComponent )
        }
        /**
         * Этот метод устанавливает карты в стопке.
         * 
         * @param {Number} count            Количество карт в стопке.
         * @returns undefined
         */
        #setDeckStackCards( count ) {
            // Получить контейнер козырной карты и колоды:
            const stackTrumpCardContainer = this.querySelector( "[data-ref-deck-trump]" )
                , stackCardsContainer     = this.querySelector( "[data-ref-deck-stack]" )
            // Получить карту козыря:
            const trumpCardComponent = stackTrumpCardContainer.querySelector( "room-card" )
            // Если есть анимации:
            if ( this.#animations.size ) {
                // Финишировать:
                this.#animations.values().map( animation => animation.finish() )
            }
            // Если нет карт:
            if ( count === 0 ) {
                // Если выводится козырь:
                if ( trumpCardComponent !== null ) {
                    // Выдать козырь:
                    stackTrumpCardContainer.dataset.ghost = true
                }
                // Сбросить выводимую колоду:
                this.#resetDeckCards()
            }
            // Если карты есть:
            else {
                // Если карта присутствует:
                if ( trumpCardComponent !== null ) {
                    // Сбросить подавленный режим:
                    trumpCardComponent.dataset.ghost = false
                }
                // Убрать карты стопки колоды:
                stackCardsContainer.innerHTML = ""
                // Если карт несколько:
                if ( count > 1 ) {
                    // Получить количество карт в стопке:
                    const stackCardsCount = count - 1
                    // Получить шаблон карты в стопке колоды:
                    const cardStackTemplate = globalThis.document.getElementById( "room_deck_stack_template" )
                    // Для всего остатка карт:
                    for ( let iteration = 0; iteration < stackCardsCount; iteration++ ) {
                        // Получить элемент для карты:
                        const cardStackElement = cardStackTemplate.content.cloneNode( true )
                        // Вставить карту в контейнер:
                        stackCardsContainer.appendChild( cardStackElement )
                    } 
                }
            }
            // Обновить текущее количество карт:
            this.#cardsCount = count
            // Обновить вывод количества:
            this.#updateDeckCount()
        }
        /**
         * Этот метод сдает карты из текущей колоды.
         * 
         * @param {Number} count                Количество карт для сдачи.
         * @returns undefined
         */
        async #dealDeckCards( count ) {
            // Получить контейнер козырной карты и колоды:
            const stackTrumpCardContainer = this.querySelector( "[data-ref-deck-trump]" )
                , stackCardsContainer     = this.querySelector( "[data-ref-deck-stack]" )
            // Получить козырную карту:
            const trumpCardComponent = stackTrumpCardContainer.querySelector( "room-card" )
            // Если в стопке одна карта:
            if ( this.#cardsCount == 1 ) {
                // Если есть козырь:
                if ( trumpCardComponent != null ) {
                    // Выдать козырь:
                    trumpCardComponent.dataset.ghost = true
                }
                // Сбросить количество:
                this.#cardsCount = 0
                // Обновить вывод количества:
                this.#updateDeckCount()
            }
            // Если карт несколько:
            else {
                // Если есть козырь:
                if ( trumpCardComponent != null ) {
                    // Активировать козырь:
                    delete trumpCardComponent.dataset.ghost
                }
                // Обновить количество:
                this.#cardsCount -= count
                // Обновить количество:
                this.#updateDeckCount()
                // Создать делей анимации пропадания карты:
                let delay = 0
                // Сохранять удаляемые карты и анимации:
                const cardsToRemove = []
                    , animations    = []
                // Получить все карты в стопке:
                const stackCards = stackCardsContainer.querySelectorAll(
                    "[data-ref-deck-card]:not([data-animated])"
                )
                // Для каждой карты начиная с конца:
                for ( let iteration = stackCards.length - 1
                    ; iteration >= Math.max( 0, stackCards.length - count )
                    ; iteration--
                    ) {
                    // Иначе выдать карту из стопки:
                    const lastStackCard = stackCards[ iteration ]
                    // Создать анимацию:
                    const animation = lastStackCard.animate(
                        [ { "transform": "translateX(0)", "opacity": 1 } 
                        , { "transform": "translateX(-21px)", "opacity": 0 }
                        ]
                      , { "fill"    : "forwards"
                        , "easing"  : "ease"
                        , "duration": 200
                        , "delay"   : delay 
                        }
                    )
                    // Добавить статус анимации:
                    lastStackCard.dataset.animated = true
                    // Сохранить промис завершения:
                    animations.push( animation.finished )
                    // Сохранить анимацию:
                    this.#animations.add( animation )
                    // Добавить карту к удаляемым:
                    cardsToRemove.push( lastStackCard )
                    // Увеличить делей анимации:
                    delay += 100
                    // По окончанию анимации:
                    animation.onfinish = () => {
                        // Убрать анимацию:
                        this.#animations.delete( animation )
                    }
                }
                // Ожидать завершения анимаций:
                await Promise.all( animations )
                // Удалить все узлы:
                cardsToRemove.map(
                    node => {
                        // Если узел еще существует:
                        if ( node.parentNode !== undefined ) {
                            // Удалить узел:
                            node.parentNode.removeChild( node )
                        }
                    }
                )
                // Если карт выдано больше чем в колоде:
                if ( stackCards.length < count ) {
                    // Выдать козырь:
                    trumpCardComponent.dataset.ghost = true
                }
            }
        }
        /**
         * Этот метод обновляет число карт в текущей колоде.
         * 
         * @returns undefined
         */
        #updateDeckCount() {
            // Получить элемент вывода количества карт в колоде:
            const deckCountElement = this.querySelector( "[data-ref-deck-count]" )
            // Если карт нет:
            if ( this.#cardsCount == 0 ) {
                // Скрыть элемент:
                deckCountElement.classList.add( "is-hidden" )
            }
            // Если в колоде есть карты:
            else {
                // Показать элемент:
                deckCountElement.classList.remove( "is-hidden" )
                // Вывести количество карт:
                deckCountElement.textContent = this.#cardsCount 
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-deck", RoomDeck )
</script>