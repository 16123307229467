/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс обработки ответов с сервера:
import Response from "../../response.mjs"
// Использовать объект состояния:
import { state } from "../../../main.mjs"

/**
 * Этот класс описывает объект обработки ответов от веб API:
 * /api/v1/session/create
 */
export default class SessionCreate
    extends Response
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return request.uri === "/session/create"
    }
    /**
     * Этот метод обрабатывает ответ сервера.
     *
     * @param {Object} data                 Полученные данные с сервера.
     * @returns undefined
     */
    async process( data ) {
        // Если результат получен:
        if ( data.status == true ) {
            // Получить текущий объект сессии и роутера:
            const { session, router } = state.client
            // Установить новые настройки сессии:
            session.accessToken  = data.access
            session.refreshToken = data.refresh
            session.expireAt     = new Date().getTime() + ( Number( data.ttl ) * 1000 )
        }
    }
}