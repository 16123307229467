/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать объект запроса:
import Request from "./request.mjs"
// Использовать состояние приложения:
import { state } from "../main.mjs"
// Использовать свойства и функции событий состояния:
import { addStateEventListener
       , removeStateEventListener
       , StateTransaction
       }
  from "./state.mjs"

/** @export {Symbol}                    Символ маркера обработанной ссылки. */
export const PROCESSED_LINK_MARKER = Symbol( "processed link marker" )
/** @export {Symbol}                    Символ маркера обработанной формы. */
export const PROCESSED_FORM_MARKER = Symbol( "processed form marker" )
/**
 * Этот класс описывает базовый объект HTML компонента приложения. Он создает ссылку на
 * текущий объект клиента приложения (предоставляет доступ к API, сокету и сессии) и 
 * обрабатывает внутреннее содержание элемента для перехвата кликов на ссылки, кнопки и 
 * отправку HTML-форм.
 */
export default class Component
    extends HTMLElement
{
    /** @property {MutationObserver}    Объект наблюдения за изменениями узлов. */
    #observer = undefined
    /** @property {Function}            Функция обработчика изменения состояния. */
    #stateMutatedCallback = undefined
    /**
     * Этот геттер определяет свойства состояния за которыми будет следить элемент.
     * 
     * @returns {Array}
     */
    static get observedState() {
        return []
    }
    /**
     * Конструктор объекта класса Component.
     */
    constructor() {
        super()
        // Установка значений переменных объекта:
        this.#observer = new MutationObserver(
            this.#childListMutatedCallback.bind( this )
        )
    }
    /**
     * Этот геттер возвращает родительский компонент.
     * 
     * @returns {Component}
     */
    get parentComponent() {
        // Объявить переменную текущего узла:
        let currentNode = this
        // Пройти по родительским элементам:
        while ( ( currentNode = currentNode.parentNode ) !== null ) {
            // Если текущий узел это компонент:
            if ( currentNode instanceof Component ) {
                // Вернуть результат:
                return currentNode
            }
        }
        // Иначе вернуть null:
        return null
    }
    /**
     * Этот геттер возвращает корневой компонент.
     * 
     * @returns {Component}
     */
    get rootComponent() {
        // Объявить переменную текущего и найденного узла:
        let currentNode = this
          , foundNode   = null
        // Пройти по родительским элементам:
        while ( ( currentNode = currentNode.parentNode ) !== null ) {
            // Если текущий узел это компонент:
            if ( currentNode instanceof Component ) {
                // Запомнить результат:
                foundNode = currentNode
            }
        }
        // Вернуть найденный узел:
        return foundNode
    }
    /**
     * Этот метод обрабатывает узел ссылки и устанавливает обработчик, который будет 
     * привязан к роутеру приложения.
     * 
     * @param {HTMLElement} anchorElement       Элемент узла документа.
     * @returns undefined
     */
    #updateLink( anchorElement ) {
        // Получить объект роутера:
        const router = state.client.router
        // Проверить, что ссылка еще не обработана:
        if ( anchorElement[ PROCESSED_LINK_MARKER ] === undefined ) {
            // Установить обработку клика:
            anchorElement.addEventListener(
                "click"
              , event => {
                    // Получить элемент ссылки:
                    const anchorElement = event.currentTarget
                    // Получить ссылку и цель текущего элемента:
                    const href   = anchorElement.getAttribute( "href" )
                        , target = anchorElement.getAttribute( "target" )
                    // Получить данные настроек роутера:
                    const ignore = anchorElement.dataset.routerIgnore
                    // Проверить, что путь ссылки указан локально к окну и не игнорирован:
                    if ( ( ( ignore == undefined ) || ( ignore === "false" ) )
                      && ( ( target == null ) || ( target === "_self" ) )
                      && ( href !== null )
                      && ( href.startsWith( "/" ) == true )
                       ) {
                        // Выполнить переход с роутера:
                        router.open( href )
                        // Отменить действие:
                        event.stopPropagation()
                        // Не выполнять фактический переход:
                        event.preventDefault()
                    }
                }
            )
            // Установить ссылку как обработанную:
            anchorElement[ PROCESSED_LINK_MARKER ] = true
        }
    }
    /**
     * Этот метод обрабатывает элементы ссылок в переданном узле и привязывает переходы к
     * роутеру приложения.
     * 
     * @param {HTMLElement} container       Контейнер для обработки узлов.
     * @returns undefined
     */
    #updateLinks( container ) {
        // Объявить очередь с текущим элементом:
        const elementsQueue = [ container ]
        // Объявить переменную текущего узла:
        let currentElement
        // Обработать текущую очередь:
        while ( ( currentElement = elementsQueue.pop() ) !== undefined ) {
            // Обработать дочерние узлы элемента:
            for ( const childElement of currentElement.children ) {
                // Если текущий элемент это ссылка:
                if ( childElement.matches( "a" ) == true ) {
                    // Привязать ссылку:
                    this.#updateLink( childElement )
                }
                // Если дочерний элемент не компонент:
                if ( childElement instanceof Component == false ) {
                    // Если у этого элемента есть дочерние узлы:
                    if ( childElement.children.length > 0 ) {
                        // Добавить элемент в очередь обработки:
                        elementsQueue.push( childElement )
                    }
                }
            }
        }
    }
    /**
     * Этот метод обрабатывает переданный элемент формы, привязывая её к отправке данных
     * на текущий сервер приложения.
     * 
     * @param {HTMLElement} formElement     Элемент обрабатываемой формы.
     * @returns undefined
     */
    #updateForm( formElement ) {
        // Получить объект сервера:
        const server = state.client.server
        // Проверить, что форма еще не обработана:
        if ( formElement[ PROCESSED_FORM_MARKER ] === undefined ) {
            // Установить обработку отправки формы:
            formElement.addEventListener(
                "submit"
              , event => {
                    // Получить элемент формы и инициатора отправки:
                    const form      = event.currentTarget
                        , submitter = event.submitter
                    // Получить адрес действия, метод, тип и цель отправки:
                    const action  = form.getAttribute( "action" )
                        , method  = form.getAttribute( "method" )
                        , target  = form.getAttribute( "target" )
                    // Получить данные настроек запроса:
                    const ignore  = form.dataset.requestIgnore
                        , clear   = form.dataset.requestClear
                        , json    = form.dataset.requestJson
                        , disable = form.dataset.requestDisable
                        , type    = form.dataset.responseType
                    // Проверить, что путь ссылки указан локально к окну и не игнорирован:
                    if ( ( ( ignore == undefined ) || ( ignore === "false" ) )
                      && ( ( target == undefined ) || ( target === "_self" ) )
                      && ( action !== null )
                      && ( action.startsWith( "/" ) == true )
                       ) {
                        // Создать новый объект данных формы:
                        const formData = new FormData( form )
                        // Если установлен сабмиттер:
                        if ( submitter !== null ) {
                            // Если у сабмиттера установлено имя:
                            if ( submitter.name !== "" ) {
                                // Добавить значение из сабмиттера:
                                formData.append( submitter.name, submitter.value )
                            }
                        }
                        // Создать новый объект запроса на сервер:
                        const request = new Request( action, method, type || "json" )
                        // Установить параметры запроса:
                        request.initiator = form
                        request.submitter = submitter
                        // Если указан атрибут json:
                        if ( ( json !== undefined ) && ( json !== "false" ) ) {
                            // Отправить в виде объекта (json):
                            request.body = Object.fromEntries( formData )
                        }
                        // Если нет атрибута json:
                        else {
                            // Отправить multipart запрос:
                            request.body = formData
                        }
                        // Если установлен режим блокировки:
                        if ( ( disable !== undefined ) && ( disable !== "false" ) ) {
                            // Создать контейнер блокированных элементов:
                            const disabledElements = []
                            // Если установлен инициатор для блокировки:
                            if ( disable === "initiator" ) {
                                // Для всех элементов:
                                for ( const element of form.elements ) {
                                    // Которые в данный момент не заблокированы:
                                    if ( element.disabled == false ) {
                                        // Установить блокировку:
                                        element.disabled = "disabled"
                                        // Добавить в контейнер:
                                        disabledElements.push( element )
                                    }
                                }
                            }
                            // Если установлен сабмиттер для блокировки:
                            if ( disable === "submitter" ) {
                                // Если сабмиттер не заблокирован:
                                if ( submitter.disabled == false ) {
                                    // Установить блокировку:
                                    submitter.disabled = "disabled"
                                    // Добавить в контейнер:
                                    disabledElements.push( submitter )
                                }
                            }
                            // Выполнить отправку запроса и ожидать результат:
                            server.execute( request ).then( () => {
                                // Для всех отключенных элементов:
                                for ( const element of disabledElements ) {
                                    // Вернуть прежний статус:
                                    element.disabled = false
                                }
                                // Если установлен сброс:
                                if ( ( clear !== undefined ) && ( clear !== "false" ) ) {
                                    // Выполнить сброс формы:
                                    form.reset()
                                }
                            } )
                        }
                        // Если режим блокировки не установлен:
                        else {
                            // Выполнить отправку запроса:
                            server.execute( request )
                            // Если установлен сброс:
                            if ( ( clear !== undefined ) && ( clear !== "false" ) ) {
                                // Выполнить сброс формы:
                                form.reset()
                            }
                        }
                        // Отменить действие:
                        event.stopPropagation()
                        // Не выполнять фактический переход:
                        event.preventDefault()
                    } 
                }
            )
            // Установить форму как обработанную:
            formElement[ PROCESSED_LINK_MARKER ] = true
            // Получить параметр автоматической отправки формы:
            const auto = formElement.dataset.requestAuto
            // Если установлен параметр автоматической отправки:
            if ( ( auto !== undefined ) && ( auto !== "false" ) ) {
                // Выполнить отправку формы:
                formElement.submit()
            }
        }
    }
    /**
     * Этот метод обрабатывает элементы форм в переданном узле и привязывает отправку формы
     * к вызову методов API.
     * 
     * @param {HTMLElement} container       Контейнер для обработки узлов.
     * @returns undefined
     */
    #updateForms( container ) {
        // Объявить очередь с текущим элементом:
        const elementsQueue = [ container ]
        // Объявить переменную текущего узла:
        let currentElement
        // Обработать текущую очередь:
        while ( ( currentElement = elementsQueue.pop() ) !== undefined ) {
            // Обработать дочерние узлы элемента:
            for ( const childElement of currentElement.children ) {
                // Если текущий элемент это форма:
                if ( childElement.matches( "form" ) == true ) {
                    // Привязать форму:
                    this.#updateForm( childElement )
                }
                // Если дочерний элемент не компонент:
                if ( childElement instanceof Component == false ) {
                    // Если у этого элемента есть дочерние узлы:
                    if ( childElement.children.length > 0 ) {
                        // Добавить элемент в очередь обработки:
                        elementsQueue.push( childElement )
                    }
                }
            }
        }
    }
    /**
     * Этот метод устанавливает слежение за переданным узлом при помощи текущего обсервера.
     * 
     * @param {HTMLElement} node                    Целевой узел для слежения.
     * @param {Boolean}     [ignoreComponents]      Если true, то компоненты игнорируются.
     * @returns undefined
     */
    #observe( node, ignoreComponents = true ) {
        // Если переданный элемент не компонент:
        if ( ( ignoreComponents == false )
          || ( node instanceof Component == false ) 
           ) {
            // Установить слежение через обсервер:
            this.#observer.observe(
                node
              , { "childList" : true
                , "subtree"   : false
                , "attributes": false
                }
            )
        }
    }
    /**
     * Этот метод устанавливает слежение за дочерними узлами (и дочерними дочерних) для 
     * переданного узла, игнорируя потомков других компонентов.
     * 
     * @param {HTMLElement} node            Целевой узел для слежения.
     * @returns undefined
     */
    #observeChildElements( node ) {
        // Объявить очередь с текущим элементом:
        const elementsQueue = [ node ]
        // Объявить переменную текущего узла:
        let currentElement
        // Обработать текущую очередь:
        while ( ( currentElement = elementsQueue.pop() ) !== undefined ) {
            // Обработать дочерние узлы элемента:
            for ( const childElement of currentElement.children ) {
                // Если дочерний элемент не компонент:
                if ( childElement instanceof Component == false ) {
                    // Установить слежение через обсервер:
                    this.#observe( childElement )
                    // Если у этого элемента есть дочерние узлы:
                    if ( childElement.children.length > 0 ) {
                        // Добавить элемент в очередь обработки:
                        elementsQueue.push( childElement )
                    }
                }
            }
        }
    }
    /**
     * Этот метод вызывается при модификации списка дочерних узлов для текущего компонента.
     * 
     * @param {Array} mutations             Список мутаций элемента DOM.
     * @returns undefined 
     */
    #childListMutatedCallback( mutations ) {
        // Обработка каждой мутации:
        for ( const mutation of mutations ) {
            // Если тип это модификация узлов:
            if ( mutation.type === "childList" ) {
                // Обработать добавленные узлы:
                for ( const addedNode of mutation.addedNodes ) {
                    // Если это элемент:
                    if ( addedNode.nodeType === 1 ) {
                        // Игнорировать компоненты:
                        if ( addedNode instanceof Component == false ) {
                            // Если текущий элемент это ссылка:
                            if ( addedNode.matches( "a" ) == true ) {
                                // Привязать ссылку:
                                this.#updateLink( addedNode )
                            }
                            // Если текущий элемент это форма:
                            if ( addedNode.matches( "form" ) == true ) {
                                // Привязать форму:
                                this.#updateForm( addedNode )
                            }
                            // Обработать дочерние ссылки и формы:
                            this.#updateLinks( addedNode )
                            this.#updateForms( addedNode )
                            // Прикрепить узел к слежению:
                            this.#observe( addedNode )
                            // Прикрепить дочерние узлы к слежению:
                            this.#observeChildElements( addedNode )
                        }
                    }
                }
            }
        }
    }
    /**
     * Этот метод выполняется при подключении элемента в документ.
     * 
     * @returns undefined
     */
    connectedCallback() {
        // Выполнить инициализацию ссылок и форм для содержания компонента:
        this.#updateLinks( this )
        this.#updateForms( this )
        // Прикрепить наблюдение за узлом компонента:
        this.#observe( this, false )
        // Прикрепить наблюдение за дочерними узлами:
        this.#observeChildElements( this )
        // Выполнить отклик прикрепления состояния к компоненту:
        this.stateCallback( state )
        // Если установлены свойства для слежения:
        if ( this.constructor.observedState.length > 0 ) {
            // Создать функцию отклик для обработки события модификации:
            this.#stateMutatedCallback = ( { transaction } ) => {
                // Если это свойство прослушивается:
                if ( transaction.matches( ...this.constructor.observedState ) == true ) {
                    // Вызвать отклик обновления состояния:
                    this.stateChangedCallback( state
                                             , transaction
                                             )
                }
            }
            // Установка обработчика события для состояния:
            addStateEventListener( state, "mutate", this.#stateMutatedCallback )
            // Для всех текущих свойств состояния:
            for ( const propertyName of Object.keys( state ) ) {
                // Если это свойство прослушивается:
                if ( this.constructor.observedState.indexOf( propertyName ) !== -1 ) {
                    // Создать транзакцию первоначальной установки свойства:
                    const transaction = StateTransaction.create( state, propertyName )
                    // Использовать обработку событий для инициализации:
                    transaction.addEventListener( "mutate", this.#stateMutatedCallback )
                    // Выполнить инициализацию:
                    this.stateChangedCallback( state, transaction )
                }
            }
        }
    }
    /**
     * Этот метод выполняется при отключении элемента от документа.
     * 
     * @returns undefined
     */
    disconnectedCallback() {
        // Если установлены свойства для слежения:
        if ( this.constructor.observedState.length > 0 ) {
            // Удаление обработчика события для состояния:
            removeStateEventListener( state, "mutate", this.#stateMutatedCallback )
            // Сбросить прослушивание мутаций DOM:
            this.#observer.disconnect()
        }
    }
    /**
     * Этот метод обрабатывает установку объекта состояния.
     * 
     * @param {Proxy} state                 Проксированный объект состояния.
     * @returns undefined
     */
    stateCallback( state ) {}
    /**
     * Этот метод обрабатывает изменение значения свойства объекта состояния.
     * 
     * @param {Proxy}            state          Проксированный объект состояния.
     * @param {StateTransaction} transaction    Транзакция.
     * @returns undefined
     */
    stateChangedCallback( state, transaction ) {}
}