/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс входящих сообщений:
import Incoming from "../../incoming.mjs"
// Использовать объект состояния:
import { state } from "../../../../main.mjs"

/**
 * Этот класс описывает входящее сообщение "leave". 
 */
export default class Leave
    extends Incoming
{
    /**
     * Этот метод тестирует полученное сообщение и возвращает true, если сообщение
     * может быть обработано текущим экземпляром объекта входящего сообщения.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns {Boolean}
     */
    test( message ) {
        return ( message.channel.startsWith( "table_" ) == true )
            && ( message.event === "leave" )
    }
    /**
     * Этот метод обрабатывает получение сообщения из сокета.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns undefined
     */
    process( message ) {
        // Получение данных из сообщения:
        const { seat, occupied } = message.data
        // Удаление игрока из состояния:
        state.roomPlayers.delete( seat )
        // Обновление информации комнаты:
        state.roomInfo.occupied = occupied
        // Если это текущий игрок:
        if ( state.roomPlayer.seat === seat ) {
            // Передать сообщение выхода:
            globalThis.top.postMessage( { type: "close" }, "*" )
        }
    }
}