/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/**
 * Этот класс представляет объект роутера -- переключения экранов приложения в зависимости
 * от текущего URI.
 */
export default class Router
{
    /** @property {Object}                  Объект окна роутера. */
    #window       = undefined
    /** @property {String}                  Базовый путь роутера. */
    #basePath     = undefined
    /** @property {Set}                     Набор обработчиков переходов. */
    #listeners    = new Set()
    /** @property {Set}                     Набор открытых объектов роутов. */
    #opened       = new Set()
    /** @property {URL}                     Объект текущего открытого в роутере url. */
    #currentUrl   = undefined
    /** @property {Object}                  Объект текущего открытого в роутере состояния. */
    #currentHistoryState = undefined
    /**
     * Конструктор объекта класса Router.
     * 
     * @param {Object} window               Объект окна роутера.
     * @param {String} [basePath]           Базовый путь роутера.
     */
    constructor( window, basePath ) {
        // Установка значений переменных объекта:
        this.#window   = window
        this.#basePath = basePath
        // Подписка на события изменения истории:
        this.#window.addEventListener(
            "popstate"
          ,  ( ...args ) => this.popStateCallback( ...args )
        )
    }
    /**
     * Этот геттер возвращает объект окна, которое используется в текущем объекте роутера.
     * 
     * @returns {Object}
     */
    get window() {
        return this.#window
    }
    /**
     * Этот геттер возвращает базовый путь, который используется в текущем объекте роутера.
     * 
     * @returns {Object}
     */
    get basePath() {
        return this.#basePath
    }
    /**
     * Этот геттер возвращает текущий открытый в роутере url.
     * 
     * @returns {URL}
     */
    get currentUrl() {
        return this.#currentUrl
    }
    /**
     * Этот геттер возвращает текущее открытое в роутере состояние.
     * 
     * @returns {Object}
     */
    get currentHistoryState() {
        return this.#currentHistoryState
    }
    /**
     * Этот метод обрабатывает событие popstate на объекте истории.
     * 
     * @param {Object} event                Объект события.
     * @returns undefined
     */
    popStateCallback( event ) {
        // Получить состояние из истории:
        const historyState = event.state
        // Получить текущую локацию:
        const url = new URL( this.#window.location.href )
        // Выполнить переход на новый URL:
        this.navigate( url, historyState )
    }
    /**
     * Этот метод добавляет объекты обработки переходов по роутам приложения.
     * 
     * @param {Route} ...listeners           Список слушателей переходов.
     * @returns undefined
     */
    subscribe( ...listeners ) {
        // Добавить переданные объекты в набор:
        listeners.forEach( listener => this.#listeners.add( listener ) )
    }
    /**
     * Этот метод удаляет объекты обработки ответов из текущего объекта подключения к сокету.
     * 
     * @param {Route} ...listeners          Список слушателей переходов
     * @returns undefined
     */
    unsubscribe( ...listeners ) {
        // Удалить переданные объекты из набора:
        listeners.forEach( listener => this.#listeners.delete( listener ) )
    }
    /**
     * Этот метод выполняет переход на объекте истории (добавляя запись), что вызывает обработку
     * перехода внутри приложения и изменение текущего адреса страницы.
     * 
     * @param {String} uri                  Компоненты пути открываемого роута.
     * @param {Object} [historyState]              Объект состояния из истории.
     * @returns undefined
     */
    open( uri, historyState = {} ) {
        // Если объявлен базовый путь роутера:
        if ( this.#basePath !== undefined ) {
            // Добавить базовый путь к переданным компонентам пути:
            uri = this.#basePath + uri
        }
        // Использовать pushState для обновления состояния:
        this.#window.history.pushState( historyState, "", uri )
        // Получение URL текущего окна:
        const url = new URL( this.#window.location.href )
        // Выполнение навигации на новый адрес:
        this.navigate( url, historyState )
    }
    /**
     * Этот метод выполняет переход на объекте истории (не добавляя запись), что вызывает обработку
     * перехода внутри приложения и изменение текущего адреса страницы.
     * 
     * @param {String} uri                  Компоненты пути открываемого роута.
     * @param {Object} [historyState]              Объект состояния из истории.
     * @returns undefined
     */
    redirect( uri, historyState = {} ) {
        // Если объявлен базовый путь роутера:
        if ( this.#basePath !== undefined ) {
            // Добавить базовый путь к переданным компонентам пути:
            uri = this.#basePath + uri
        }
        // Использовать replaceState для обновления состояния:
        this.#window.history.replaceState( historyState, "", uri )
        // Получение URL текущего окна:
        const url = new URL( this.#window.location.href )
        // Выполнение навигации на новый адрес:
        this.navigate( url, historyState )
    }
    /**
     * Этот метод выполняет навигацию на текущий открытый URL окна.
     * 
     * @returns undefined
     */
    refresh() {
        // Получение URL текущего окна:
        const url = new URL( this.#window.location.href )
        // Выполнение навигации на текущий адрес:
        this.navigate( url )
    }
    /**
     * Этот метод исполняет навигацию внутри приложения по переданному URL.
     *
     * @param {URL}    url                  Объект открываемого URL.
     * @param {Object} [historyState]              Объект состояния из истории.
     * @returns {Boolean} 
     */
    async navigate( url, historyState = {} ) {
        // Обработка закрытия открытых роутов:
        for ( const route of this.#opened.values() ) {
            // Если проверка не проходит:
            if ( route.test( url, historyState ) == false ) {
                // Закрыть роут и использованием текущего состояния роутера:
                await route.close( this.#currentUrl
                                 , this.#currentHistoryState
                                 )
                // Удалить роут из списка открытых:
                this.#opened.delete( route )
            }
            // Если проверка прошла:
            else {
                // Обновить состояние роута:
                await route.refresh( url, historyState )
            }
        }
        // Обработка открытия роутов:
        for ( const route of this.#listeners.values() ) {
            // Если роут уже не открыт:
            if ( this.#opened.has( route ) == false ) {
                // Если проверка проходит:
                if ( route.test( url, historyState ) == true ) {
                    // Выполнить открытие роута:
                    await route.open( url, historyState )
                    // Добавить роут в список открытых:
                    this.#opened.add( route )
                }
            }
        }
        // Установить текущий url и состояние роутера:
        this.#currentUrl          = url
        this.#currentHistoryState = historyState
    }
    /**
     * Этот метод обрабатывает завершение приложения принудительно закрывая открытые роуты.
     * 
     * @returns {Boolean}
     */
    quit() {
        // Обработка закрытия открытых роутов:
        for ( const route of this.#opened.values() ) {
            // Получить результат выхода из роута:
            const status = route.quit( this.#currentUrl
                                     , this.#currentHistoryState 
                                     )
            // Если статус отрицательный:
            if ( status == false ) {
                // Вернуть false:
                return false
            }
        }
        // Иначе вернуть true:
        return true
    }
}