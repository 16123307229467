<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_player_template">
    <div class="playing-room-player is-hidden" data-ref-player-container>
        <div class="player-canvas-timer is-hidden" data-ref-timer-canvas-container>
            <canvas data-ref-timer-canvas-arc></canvas>
            <canvas data-ref-timer-canvas-spark></canvas>
            <canvas data-ref-timer-canvas-particles></canvas>
        </div>
        <div class="playing-room-player__menu">
            <a href="javascript:;" class="playing-room-player__menu-item playing-room-player__menu-item_mail" data-ref-player-mail>
                <span class="playing-room-player__mail-counter is-hidden" data-ref-player-mail-counter></span>
            </a>
            <a href="javascript:;" class="playing-room-player__menu-item playing-room-player__menu-item_menu" data-ref-player-menu></a>
            <template-event name="click" listener="onExitButtonClick">
                <a href="javascript:;" class="playing-room-player__menu-item playing-room-player__menu-item_exit" data-ref-player-exit></a>
            </template-event>
        </div>
        <div class="playing-room-player__action is-hidden" data-ref-player-status>
            <img class="playing-room-player__action-image is-hidden" data-ref-player-status-image data-ref-player-status-image-pass src="../assets/images-compressed/pass.png">
            <img class="playing-room-player__action-image is-hidden" data-ref-player-status-image data-ref-player-status-image-take src="../assets/images-compressed/take.png">
        </div>
        <div class="playing-room-player__timer_tint is-hidden" data-ref-player-timer-tint></div>
        <div class="playing-room-player__timer is-hidden" data-ref-player-timer>0</div>
        <div class="playing-room-player__place is-hidden" data-ref-player-place-tint data-ref-player-place>
            <img class="playing-room-player__place-image is-hidden" data-ref-player-place-image data-ref-player-place-image-1 src="../assets/images-compressed/place_1.png">
            <img class="playing-room-player__place-image is-hidden" data-ref-player-place-image data-ref-player-place-image-2 src="../assets/images-compressed/place_2.png">
            <img class="playing-room-player__place-image is-hidden" data-ref-player-place-image data-ref-player-place-image-3 src="../assets/images-compressed/place_3.png">
            <img class="playing-room-player__place-image is-hidden" data-ref-player-place-image data-ref-player-place-image-4 src="../assets/images-compressed/place_4.png">
            <img class="playing-room-player__place-image is-hidden" data-ref-player-place-image data-ref-player-place-image-5 src="../assets/images-compressed/place_5.png">
            <img class="playing-room-player__place-image is-hidden" data-ref-player-place-image data-ref-player-place-image-6 src="../assets/images-compressed/place_6.png">
        </div>
        <picture>
            <img src="../assets/images-compressed/player_avatar.png" class="playing-room-player__avatar" data-ref-player-avatar alt="">
        </picture>
        <div class="playing-room-player__block">
            <div class="playing-room-player__score" data-ref-player-score></div>
            <div class="playing-room-player__name" data-ref-player-name></div>
        </div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента с анимированным таймером:
    import AnimatedTimer from "../modules/component/animatedtimer.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-player>.
     */
    class RoomPlayer
        extends Templated( AnimatedTimer, "room_player_template" )
    {
        /** @property {MediaQueryList}          Объект запроса на адаптивность. */
        #mediaQueryMobile = globalThis.matchMedia( "(max-width:1000px) and (max-height:600px) and (orientation:landscape)" )
        /** @property {Animation}               Текущая анимация. */
        #animation = undefined
        /** @property {Number}                  Интервал таймера. */
        #timerInterval = undefined
        /** @property {String}                  Текущий выводимый статус. */
        #currentStatus = undefined
        /** @property {String}                  Текущее выводимое место. */
        #currentPlace = undefined
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomPlayer", "roomPlayer.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если обновлен сам объект игрока:
                case "roomPlayer":
                    // Обновить значения объекта:
                    transaction.expand()
                    break
                // Если обновлено место игрока:
                case "roomPlayer.state":
                    // Если текущее место не установлено:
                    if ( transaction.value == undefined ) {
                        // Скрыть игрока:
                        this.#hidePlayer()
                    }
                    // Если место установлено:
                    else {
                        this.#showPlayer()
                    }
                    break
                // Если обновлено имя игрока:
                case "roomPlayer.name":
                    // Установить имя текущего игрока:
                    this.#setPlayerName( transaction.value )
                    break
                // Если обновлен ранг игрока:
                case "roomPlayer.rank":
                    // Установить имя текущего игрока:
                    this.#setPlayerScore( transaction.value )
                    break
                // Если обновлен аватар игрока:
                case "roomPlayer.avatar":
                    // Установить имя текущего игрока:
                    this.#setPlayerAvatar( transaction.value )
                    break
                // Обновление статуса активности:
                case "roomPlayer.active_time":
                    // Если параметр задан:
                    if ( state.roomPlayer.active == true ) {
                        // Установить активность:
                        this.#setActivePlayer(
                            state.roomPlayer.active_timeout
                          , state.roomPlayer.active_time
                        )
                    }
                    // Если параметр не задан:
                    else {
                        this.#resetActivePlayer()
                    }
                    break
                // Обновление статуса:
                case "roomPlayer.game_state":
                    // Установить статус:
                    this.#showPlayerStatus( transaction.value )
                    break
                // Обновление места:
                case "roomPlayer.place":
                    // Установить место выигрышное:
                    this.#showPlayerPlace( transaction.value )
                    break
            }
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Получить элемент контейнера:
            const playerContainer = this.querySelector( "[data-ref-player-container]" )
            // Инициализировать элементы таймера:
            this.initializeAnimatedTimer(
                this.querySelector( "[data-ref-timer-canvas-arc]" )
              , this.querySelector( "[data-ref-timer-canvas-spark]" )
              , this.querySelector( "[data-ref-timer-canvas-particles]" )
            )
            // Установить размер анимированного таймера:
            this.resizeAnimatedTimer( 330, 330 )
            // Установить скрытый класс:
            playerContainer.classList.add( "is-hidden" )
        }
        /**
         * Этот метод выполняется при отключении шаблонизированного элемента от тела документа.
         * 
         * @returns undefined
         */
        templateDisconnectedCallback() {
            // Деинициализация анимированного таймера:
            this.deinitializeAnimatedTimer()
        }
        /**
         * Обработка события нажатия на выход.
         */
        onExitButtonClick() {
            // Передать сообщение выхода:
            globalThis.top.postMessage( { type: "close" }, "*" )
            return false
        }
        /**
         * Этот метод показывает текущего игрока.
         * 
         * @returns undefined
         */
        #showPlayer() {
            // Получить элемент контейнера:
            const playerContainer = this.querySelector( "[data-ref-player-container]" )
            // Если игрок не отображается:
            if ( playerContainer.classList.contains( "is-hidden" ) == true ) {
                // Показать элемент:
                playerContainer.classList.remove( "is-hidden" )
                // Если режим адаптивности:
                if ( this.#mediaQueryMobile.matches == false ) {
                    // Если есть анимация:
                    if ( this.#animation ) {
                        // Отменить анимацию:
                        this.#animation.cancel()
                    }
                    // Создать анимацию:
                    this.#animation = playerContainer.animate(
                        [ { "transform": "translateY(-46px) scale(1.2)", "opacity": 0 } 
                        , { "transform": "translateY(0) scale(1)", "opacity": 1 }
                        ]
                        , {  "easing"  : "ease"
                          , "duration": 200
                          }
                    )
                    // По окончании анимации:
                    this.#animation.onfinish = () => {
                        // Сброс анимации:
                        this.#animation = undefined
                    }
                }
            }
        }
        /**
         * Этот метод скрывает текущего игрока.
         * 
         * @returns undefined
         */
        #hidePlayer() {
            // Получить элемент контейнера:
            const playerContainer = this.querySelector( "[data-ref-player-container]" )
            // Если игрок не отображается:
            if ( playerContainer.classList.contains( "is-hidden" ) == false ) {
                // Создать анимацию:
                this.#animation = playerContainer.animate(
                    [ { "transform": "translateY(0) scale(1)", "opacity": 1 } 
                    , { "transform": "translateY(-46px) scale(0.8)", "opacity": 0 }
                    ]
                    , { "fill"    : "forwards"
                      , "easing"  : "ease"
                      , "duration": 200
                      }
                )
                // По окончании анимации:
                this.#animation.onfinish = () => {
                    // Установить скрытый класс:
                    playerContainer.classList.add( "is-hidden" )
                    // Сброс анимации:
                    this.#animation = undefined
                }
            }
        }
        /**
         * Этот метод устанавливает имя текущего игрока.
         * 
         * @param {String} name             Имя игрока.
         * @returns undefined
         */
        #setPlayerName( name ) {
            // Получить элемент имени игрока:
            const nameElement = this.querySelector( "[data-ref-player-name]" )
            // Установить текстовое содержание узла:
            nameElement.textContent = name
        }
        /**
         * Этот метод устанавливает ранг текущего игрока.
         * 
         * @param {Number} score            Ранг игрока.
         * @returns undefined
         */
        #setPlayerScore( score ) {
            // Получить элемент имени игрока:
            const scoreElement = this.querySelector( "[data-ref-player-score]" )
            // Установить текстовое содержание узла:
            scoreElement.textContent = score
        }
        /**
         * Этот метод устанавливает аватар текущего игрока.
         * 
         * @param {String} avatar           Аватар игрока.
         * @returns undefined
         */
        #setPlayerAvatar( avatar ) {
            // Получить элемент имени игрока:
            const avatarElement = this.querySelector( "[data-ref-player-avatar]" )
            // Установить текстовое содержание узла:
            avatarElement.src = avatar
        }
        /**
         * Этот метод устанавливает текущего игрока активным.
         * 
         * @param {Number} totalTime        Количество времени активности.
         * @param {Number} startTime        Начальное время активности.
         * @returns undefined
         */
        #setActivePlayer( totalTime, startTime ) {
            // Получить текущий контейнер игрока:
            const playerContainer = this.querySelector( "[data-ref-player-container]" )
            // Получить контейнер таймера:
            const timerContainer = this.querySelector( "[data-ref-timer-canvas-container]" )
            // Получить численный таймер:
            const timerTextElement = this.querySelector( "[data-ref-player-timer]" )
                , timerTintElement = this.querySelector( "[data-ref-player-timer-tint]" )
            // Установить время:
            const timeDiff = Math.round( ( new Date().getTime() - startTime ) / 1000 )
                , timeLeft = Math.max( 0, totalTime - timeDiff )
            // Установить значение:
            timerTextElement.textContent = Math.min( totalTime, timeLeft )
            // Сбросить интервал:
            clearInterval( this.#timerInterval )
            // Установить интервал 
            this.#timerInterval = setInterval(
                () => {
                    // Получить текущие значения времени:
                    const currentTimeDiff = Math.round( ( new Date().getTime() - startTime ) / 1000 )
                        , currentTimeLeft = Math.max( 0, totalTime - currentTimeDiff )
                    // Установить значение:
                    timerTextElement.textContent = Math.min( totalTime, currentTimeLeft )
                }
              , 1000
            )
            // Показать таймер:
            timerTextElement.classList.remove( "is-hidden" )
            timerTintElement.classList.remove( "is-hidden" )
            // Установить класс активности:
            playerContainer.classList.add( "is-active" )
            // Запуск таймера:
            this.startAnimatedTimer( totalTime, startTime )
            // Скрыть элемент:
            timerContainer.classList.remove( "is-hidden" )
        }
        /**
         * Этот метод сбрасывает активность текущего игрока.
         * 
         * @returns undefined
         */
        #resetActivePlayer() {
            // Получить текущий контейнер игрока:
            const playerContainer = this.querySelector( "[data-ref-player-container]" )
            // Получить контейнер таймера:
            const timerContainer = this.querySelector( "[data-ref-timer-canvas-container]" )
            // Получить численный таймер:
            const timerTextElement = this.querySelector( "[data-ref-player-timer]" )
                , timerTintElement = this.querySelector( "[data-ref-player-timer-tint]" )
            // Установить класс активности:
            playerContainer.classList.remove( "is-active" )
            // Остановка таймера:
            this.stopAnimatedTimer()
            // Сбросить значение:
            timerTextElement.textContent = ""
            // Сбросить интервал:
            clearInterval( this.#timerInterval )
            // Скрыть таймер:
            timerTextElement.classList.add( "is-hidden" )
            timerTintElement.classList.add( "is-hidden" )
            // Скрыть элемент:
            timerContainer.classList.add( "is-hidden" )
        }
        /**
         * Этот метод выводит статус игрока.
         *
         * @param {String} type         Тип текущего статуса.
         * @returns undefined
         */
        #showPlayerStatus( type ) {
            // Получить контейнер для вывода статуса:
            const statusContainer = this.querySelector( "[data-ref-player-status]" )
                , statusImageElements = statusContainer.querySelectorAll( "[data-ref-player-status-image]" )
            // Скрытие изображений:
            for ( const statusImageElement of statusImageElements ) {
                // Скрытие изображения:
                statusImageElement.classList.add( "is-hidden" )
            }
            // Объявить переменную изображения статуса:
            let target
            // Переключение по типу:
            switch ( type ) {
                // Статус "пас":
                case "pass":
                    target = statusContainer.querySelector( "[data-ref-player-status-image-pass]" )
                    break
                // Статус "беру":
                case "take":
                    target = statusContainer.querySelector( "[data-ref-player-status-image-take]" )
                    break
                // Неподдерживаемый статус:
                default:
                    // Скрыть элемент:
                    statusContainer.classList.add( "is-hidden" )
                    return

            }
            // Отобразить элемент:
            target.classList.remove( "is-hidden" )
            // Создать анимацию:
            const animation = target.animate(
                [ { "transform": "translateY(20px)", "opacity": 0 } 
                , { "transform": "translateY(0)", "opacity": 1, "offset": 0.3 }
                , { "transform": "translateY(0)", "opacity": 1, "offset": 0.3 }
                ]
              , { "fill"    : "forwards"
                , "easing"  : "ease"
                , "duration": 2000
                , "delay"   : 10 
                }
            )
            // Показать элемент:
            statusContainer.classList.remove( "is-hidden" )
            // Установить текущий статус:
            this.#currentStatus = type
        }
        /**
         * Этот метод выводит выигрышное место игрока.
         * 
         * @param {Number} place            Выигрышное место.
         * @returns undefined
         */
        #showPlayerPlace( place ) {
            // Получить вывод места:
            const placeContainerElement = this.querySelector( "[data-ref-player-place]" )
                , placeImageElements = placeContainerElement.querySelectorAll( "[data-ref-player-place-image]" )
                , placeTintElement = this.querySelector( "[data-ref-player-place-tint]" )
            // Скрыть текущие места:
            for ( const placeImageElement of placeImageElements ) {
                // Скрыть изображение:
                placeImageElement.classList.add( "is-hidden" )
            }
            // Если место установлено:
            if ( place !== 0 ) {
                // Получить изображение места:
                const placeCurrentImageElement = placeContainerElement.querySelector(
                    `[data-ref-player-place-image-${ place }]`
                )
                // Вывести место:
                placeCurrentImageElement.classList.remove( "is-hidden" )
                // Вывести элементы:
                placeContainerElement.classList.remove( "is-hidden" )
                placeTintElement.classList.remove( "is-hidden" )
            }
            // Если место не установлено:
            else {
                // Скрыть элементы:
                placeContainerElement.classList.add( "is-hidden" )
                placeTintElement.classList.add( "is-hidden" )
            }
            // Установить текущий статус:
            this.#currentPlace = place
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-player", RoomPlayer )
</script>
<!-- Стиль компонента: -->
<style type="text/scss">
    .player-canvas-timer {
        width: 400px;
        height: 400px;
        position: absolute;
        left: -8px;
        top: -18px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        z-index: 6;
        & > canvas {
            position: absolute;
            opacity: 4;
            z-index: 99;
        }
        & > canvas:nth-child(2) {
            transform: translate(-2px, -1px);
        }
        & > canvas:last-child {
            z-index: 6;
            transition: 1s linear;
            opacity: 0;
            transform: translate(-2px, -1px);

            &.appeared {
                transition: 1s linear;

                opacity: 1;
            }
        }
        &.notThisPlayer {
            left: 15px;
            top: 14px;
        }
    }
</style>