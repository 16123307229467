/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс обработки ответов с сервера:
import Response from "../../response.mjs"
// Использовать объект запроса на удаление сессии:
import SessionDestroyRequest from "../../request/sessiondestroy.mjs"
// Использовать объект состояния:
import { state } from "../../../main.mjs"

/**
 * Этот класс описывает объект обработки ответов от веб API:
 * /api/v1/session/regenerate
 */
export default class SessionRegenerate
    extends Response
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return request.uri === "/session/regenerate"
    }
    /**
     * Этот метод обрабатывает ответ сервера.
     *
     * @param {Object} data                 Полученные данные с сервера.
     * @returns undefined
     */
    async process( data ) {
        // Получить текущий объекты сессии, роутера и сервера:
        const { session, router, server } = state.client
        // Если результат получен:
        if ( data.status == true ) {
            // Установить новые настройки сессии:
            session.accessToken  = data.access
            session.refreshToken = data.refresh
            session.expireAt     = new Date().getTime() + data.ttl
            // Если сессия была создана выполнить обновление страницы:
            router.refresh()
        }
        // Если результат не получен:
        else {
            // Отправить запрос на удаление сессии:
            server.execute(
                new SessionDestroyRequest()
            )
        }
    }
}