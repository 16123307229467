/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс исходящих сообщений:
import Outgoing from "../outgoing.mjs"
// Использовать объект состояния:
import { state } from "../../../main.mjs"

/**
 * Этот класс описывает сообщение подписки на канал.
 */
export default class Subscribe
    extends Outgoing
{
    /** @property {String}          Описание команды. */
    command = "subscribe"
    /** @property {String}          Имя канала для подписки. */
    channel = undefined
    /** @property {Number}          Метка времени. */
    now = state.lastDataMicrotime
    /**
     * Конструктор объекта класса Subscribe.
     * 
     * @param {String} channel      Имя канала для подписки.
     */
    constructor( channel ) {
        super()
        // Установка значений переменных объекта:
        this.channel = channel
    }
    /**
     * Этот геттер возвращает текущее время в секундах.
     * 
     * @returns {Number}
     */
    get timestamp() {
        return new Date().getTime()
    }
}