/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/** @export {Symbol}                        Символ маркера шаблонизированного элемента. */
export const TEMPLATED_ELEMENT_MARKER = Symbol( "templated element marker" )
/**
 * Эта функция реализует mixin для реализации автоматически шаблонизируемого элемента компонента
 * без использования ShadowRoot.
 * 
 * @param {Function} ElementClass           Класс наследуемого элемента.
 * @param {String}   template               Идентификатор шаблона.
 * @returns {Function}
 */
export function Templated( ElementClass, template ) {
    // Вернуть класс для подмиксовки:
    return class extends ElementClass
    {
        /** @property {Boolean}             Маркер шаблонизированного элемента. */
        [TEMPLATED_ELEMENT_MARKER] = true
        /** @property {Node}                Текущий узел шаблона. */
        #template                  = globalThis.document.getElementById( template )
        /** @property {Array}               Список вставленных элементов шаблона. */
        #compiledNodes             = []
        /**
         * Этот метод выполняется при подключении элемента в тело документа.
         * 
         * @returns undefined
         */
        connectedCallback() {
            // Создать вставленный шаблон как копию:
            const compiledTemplate = this.#template.content.cloneNode( true )
            // Сохранить вставляемые узлы:
            this.#compiledNodes = [ ...compiledTemplate.childNodes ]
            // Вставить содержание шаблона в текущий элемент:
            this.appendChild( compiledTemplate )
            // Вызвать отклик после подключения шаблона:
            this.templateConnectedCallback()
            // Если в родителе объявлен отклик:
            if ( super.connectedCallback instanceof Function ) {
                // Выполнить родительский отклик подключения:
                super.connectedCallback()
            }
        }
        /**
         * Этот метод выполняется при отключении элемента от тела документа.
         * 
         * @returns undefined
         */
        disconnectedCallback() {
            // Выполнить отключение шаблонизированного элемента:
            this.templateDisconnectedCallback()
            // Удалить элементы шаблона:
            for ( const element of this.#compiledNodes ) {
                // Удалить этот элемент из текущего:
                this.removeChild( element )
            }
            // Если в родителе объявлен отклик:
            if ( super.disconnectedCallback instanceof Function ) {
                // Выполнить родительский отклик отключения:
                super.disconnectedCallback()
            }
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Если в родителе объявлен отклик:
            if ( super.templateConnectedCallback instanceof Function ) {
                // Выполнить родительский отклик подключения:
                super.templateConnectedCallback()
            }
        }
        /**
         * Этот метод выполняется при отключении шаблонизированного элемента от тела документа.
         * 
         * @returns undefined
         */
        templateDisconnectedCallback() {
            // Если в родителе объявлен отклик:
            if ( super.templateDisconnectedCallback instanceof Function ) {
                // Выполнить родительский отклик подключения:
                super.templateDisconnectedCallback()
            }
        }
        /**
         * Этот метод принимает события, которые были объявлены внутри шаблона.
         * 
         * @param {Object} event            Объект произошедшего события.
         * @returns undefined
         */
        handleEvent( event ) {
            // Получить текущую цель события:
            const { currentTarget } = event
            // Если конфигурация событий элемента доступна:
            if ( TemplateEvent.eventListenersWeakMap.has( currentTarget ) == true ) {
                // Получить текущую конфигурацию обработчиков:
                const eventListenersObject = TemplateEvent.eventListenersWeakMap.get(
                    currentTarget
                )
                // Если это событие объявлено:
                if ( eventListenersObject.has( event.type ) == true ) {
                    // Получить имя метода обработчика:
                    const listenerName = eventListenersObject.get( event.type )
                    // Если метод объявлен на текущем объекте:
                    if ( ( listenerName in this == true )
                      && ( this[ listenerName ] instanceof Function )
                       ) {
                        // Вызвать обработчик события:
                        this[ listenerName ]( event )
                    } 
                }
            }
        }
    }
}
/**
 * Этот класс описывает специальный элемент <template-event>, который устанавливает обработчики
 * событий на внутренние узлы или на родительский узел в зависимости от настроек.
 */
export class TemplateEvent
    extends HTMLElement
{
    /** @property {WeakMap}             Хранилище конфигураций обработчиков событий. */
    static eventListenersWeakMap = new WeakMap()
    /**
     * Этот геттер возвращает родительский шаблонизированный элемент.
     * 
     * @returns {HTMLElement}
     */
    get parentTemplatedElement() {
        // Объявить переменную текущего узла:
        let currentNode = this
        // Пройти по родительским элементам:
        while ( ( currentNode = currentNode.parentNode ) !== null ) {
            // Если текущий узел это шаблонизированного элемента:
            if ( currentNode[ TEMPLATED_ELEMENT_MARKER ] === true ) {
                // Вернуть результат:
                return currentNode
            }
        }
        // Иначе вернуть null:
        return null
    }
    /**
     * Этот метод выполняется при подключении элемента в тело документа.
     * 
     * @returns undefined
     */
    connectedCallback() {
        // Получить имя события и обработчика:
        const eventName         = this.getAttribute( "name" )
            , eventListenerName = this.getAttribute( "listener" )
        // Получить цель:
        const targetId     = this.getAttribute( "for" )
            , targetParent = this.getAttribute( "for-parent" )
        // Объявить переменную шаблонизированного элемента:
        const templatedParent = this.parentTemplatedElement
        // Если шаблонизированный родитель был найден:
        if ( templatedParent !== null ) {
            // Переключение на условии:
            switch ( true ) {
                // Если цель это идентификатор:
                case targetId !== null:
                    // Получить элемент по идентификатору:
                    const targetNode = templatedParent.querySelector( `#${ targetId }` )
                    // Если узел был найден:
                    if ( targetNode !== null ) {
                        // Установить объект текущего компонента как обработчик события:
                        targetNode.addEventListener( eventName, templatedParent )
                        // Если конфигурации элемента еще не существует:
                        if ( TemplateEvent.eventListenersWeakMap.has( targetNode ) == false ) {
                            // Сохранить для текущего объекта:
                            TemplateEvent.eventListenersWeakMap.set( targetNode, new Map() )
                        }
                        // Получить текущую конфигурацию обработчиков:
                        var eventListenersObject = TemplateEvent.eventListenersWeakMap.get( targetNode )
                        // Объявить обработчик события:
                        eventListenersObject.set( eventName, eventListenerName )
                    }
                    break
                // Если цель это родитель:
                case targetParent !== null:
                    // Получить элемент по идентификатору:
                    const parentNode = this.parentNode
                    // Если узел был найден:
                    if ( parentNode !== null ) {
                        // Установить объект текущего компонента как обработчик события:
                        parentNode.addEventListener( eventName, templatedParent )
                        // Если конфигурации элемента еще не существует:
                        if ( TemplateEvent.eventListenersWeakMap.has( parentNode ) == false ) {
                            // Сохранить для текущего объекта:
                            TemplateEvent.eventListenersWeakMap.set( parentNode, new Map() )
                        }
                        // Получить текущую конфигурацию обработчиков:
                        var eventListenersObject = TemplateEvent.eventListenersWeakMap.get( parentNode )
                        // Объявить обработчик события:
                        eventListenersObject.set( eventName, eventListenerName )
                    }
                    break
                // В другом случае:
                default:
                    // Обойти все прямые потомки:
                    for ( const childNode of [ ...this.childNodes ] ) {
                        // Проверить, что узел это элемент:
                        if ( childNode.nodeType === 1 ) {
                            // Установить объект текущего компонента как обработчик события:
                            childNode.addEventListener( eventName, templatedParent )
                            // Если конфигурации элемента еще не существует:
                            if ( TemplateEvent.eventListenersWeakMap.has( childNode ) == false ) {
                                // Сохранить для текущего объекта:
                                TemplateEvent.eventListenersWeakMap.set( childNode, new Map() )
                            }
                            // Получить текущую конфигурацию обработчиков:
                            var eventListenersObject = TemplateEvent.eventListenersWeakMap.get( childNode )
                            // Объявить обработчик события:
                            eventListenersObject.set( eventName, eventListenerName )
                        }
                        // Переместить узел наверх:
                        this.parentNode.insertBefore( childNode, this )
                    }
                    break
            }
        }
        // Удалить себя:
        this.parentNode.removeChild( this )
    }
}
// Определение элемента:
globalThis.customElements.define( "template-event", TemplateEvent )