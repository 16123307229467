/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс обработки ответов с сервера:
import Response from "../../response.mjs"
// Использовать объект состояния:
import { state } from "../../../main.mjs"

/**
 * Этот класс описывает объект обработки ответов от веб API:
 * /api/v1/session/destroy
 */
export default class SessionDestroy
    extends Response
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return request.uri === "/session/destroy"
    }
    /**
     * Этот метод обрабатывает ответ сервера.
     *
     * @param {Object} data                 Полученные данные с сервера.
     * @returns undefined
     */
    async process( data ) {
        // Если результат получен:
        if ( data.status == true ) {
            // Получить текущий объект сессии, роутера и сокета:
            const { session, router, socket } = state.client
            // Выполнить сброс сессии:
            session.reset()
            // Сбросить повторяемые запросы сокета:
            socket.dismissAll()
            // Выполнить переход к стартовому экрану:
            router.open( "/" )
        }
    }
}