<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="window_enter_template">
    <div class="modal room__1start" data-modal="room__1start">
        <div class="modal__overlay registration">
            <div class="room__1start-wrapper">
                <div class="room__1start-inner">
                    <div class="room__1start-block">
                        <div class="room__1start-box">
                            <picture>
                                <source srcset="../assets/images-compressed/room__1start_box_bg.webp" type="image/webp">
                                <img src="../assets/images-compressed/room__1start_box_bg.png" alt="">
                            </picture>
                            <p class="room__1start-boxtitle">игровой стол</p>
                            <p class="room__1start-nomber" data-ref-room-id></p>
                        </div>
                        <div class="room__1start-bank">
                            <picture>
                                <source srcset="../assets/images-compressed/room__1start_bank_bg.webp" type="image/webp">
                                <img src="../assets/images-compressed/room__1start_bank_bg.png" class="room__1start-bankbg" alt="">
                            </picture>
                            <p class="room__1start-banktitle">БАНК ИГРЫ</p>
                            <div class="room__1start-coinbox">
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_coin_silver.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_coin_silver.png" alt="">
                                </picture>
                            </div>
                            <p class="room__1start-price" data-ref-room-buyin></p>
                            <p class="room__1start-pfp">PFP<br>
                                <span data-ref-room-buyin-currency></span>
                            </p>
                        </div>
                    </div>
                    
                    <ul class="room__1start-list">
                        <li data-ref-enter-list-1>
                            <p class="room__1start-listnomber">1</p>
                            <p class="room__1start-listname" data-ref-enter-list-name></p>
                            <div class="room__1start-liststatus room__1start-liststatus-1 is-hidden" data-ref-enter-list-status-ready>
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_liststatus.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_liststatus.png" alt="">
                                </picture>
                                <span>ГОТОВ</span>
                            </div>
                            <div class="room__1start-liststatus room__1start-liststatus-2 is-hidden" data-ref-enter-list-status-waiting><span>Ожидание</span></div>
                        </li>
                        <li data-ref-enter-list-2>
                            <p class="room__1start-listnomber">2</p>
                            <p class="room__1start-listname" data-ref-enter-list-name></p>
                            <div class="room__1start-liststatus room__1start-liststatus-1 is-hidden" data-ref-enter-list-status-ready>
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_liststatus.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_liststatus.png" alt="">
                                </picture>
                                <span>ГОТОВ</span>
                            </div>
                            <div class="room__1start-liststatus room__1start-liststatus-2 is-hidden" data-ref-enter-list-status-waiting><span>Ожидание</span></div>
                        </li>
                        <li data-ref-enter-list-3>
                            <p class="room__1start-listnomber">3</p>
                            <p class="room__1start-listname" data-ref-enter-list-name></p>
                            <div class="room__1start-liststatus room__1start-liststatus-1 is-hidden" data-ref-enter-list-status-ready>
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_liststatus.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_liststatus.png" alt="">
                                </picture>
                                <span>ГОТОВ</span>
                            </div>
                            <div class="room__1start-liststatus room__1start-liststatus-2 is-hidden" data-ref-enter-list-status-waiting><span>Ожидание</span></div>
                        </li>
                        <li data-ref-enter-list-4>
                            <p class="room__1start-listnomber">4</p>
                            <p class="room__1start-listname" data-ref-enter-list-name></p>
                            <div class="room__1start-liststatus room__1start-liststatus-1 is-hidden" data-ref-enter-list-status-ready>
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_liststatus.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_liststatus.png" alt="">
                                </picture>
                                <span>ГОТОВ</span>
                            </div>
                            <div class="room__1start-liststatus room__1start-liststatus-2 is-hidden" data-ref-enter-list-status-waiting><span>Ожидание</span></div>
                        </li>
                        <li data-ref-enter-list-5>
                            <p class="room__1start-listnomber">5</p>
                            <p class="room__1start-listname" data-ref-enter-list-name></p>
                            <div class="room__1start-liststatus room__1start-liststatus-1 is-hidden" data-ref-enter-list-status-ready>
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_liststatus.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_liststatus.png" alt="">
                                </picture>
                                <span>ГОТОВ</span>
                            </div>
                            <div class="room__1start-liststatus room__1start-liststatus-2 is-hidden" data-ref-enter-list-status-waiting><span>Ожидание</span></div>
                        </li>
                        <li data-ref-enter-list-6>
                            <p class="room__1start-listnomber">6</p>
                            <p class="room__1start-listname" data-ref-enter-list-name></p>
                            <div class="room__1start-liststatus room__1start-liststatus-1 is-hidden" data-ref-enter-list-status-ready>
                                <picture>
                                    <source srcset="../assets/images-compressed/room__1start_liststatus.webp" type="image/webp">
                                    <img src="../assets/images-compressed/room__1start_liststatus.png" alt="">
                                </picture>
                                <span>ГОТОВ</span>
                            </div>
                            <div class="room__1start-liststatus room__1start-liststatus-2 is-hidden" data-ref-enter-list-status-waiting><span>Ожидание</span></div>
                        </li>
                    </ul>								
                </div>
                    <form action="/room/ready"
                          method="post"
                          data-request-disable="initiator"
                          data-request-json
                          >
                        <input type="hidden" name="room_id" value="" data-ref-room-id-input>
                        <button type="submit" 
                                class="button_gold playing-room-panel__button_gold room__1start-btn"
                                data-ref-ready-button
                                >	
                            Играть
                        </button>
                    </form>
                    <form action="/room/unready"
                          method="post"
                          data-request-disable="initiator"
                          data-request-json
                          >
                        <input type="hidden" name="room_id" value="" data-ref-room-id-input>
                        <button type="submit" 
                                class="button_blue playing-room-panel__button_blue room__1start-btn2 is-hidden"
                                data-ref-waiting-button
                                >	
                            Отмена
                        </button>
                    </form>
                    <form action="/room/leave"
                          method="post"
                          data-request-disable="initiator"
                          data-request-json
                          >
                        <input type="hidden" name="room_id" value="" data-ref-room-id-input>
                        <button class="room__1-btnexit" type="submit">
                            <picture>
                                <source srcset="../assets/images-compressed/room__1start_btnnext.webp" type="image/webp">
                                <img src="../assets/images-compressed/room__1start_btnnext.png" alt="">
                            </picture>
                            выйти
                        </button>
                    </form>	
                <picture>
                    <source srcset="../assets/images-compressed/room__1start_conis.webp" type="image/webp">
                    <img src="../assets/images-compressed/room__1start_conis.png" class="room__1start-mainimg" alt="">
                </picture>	
                    
                <picture>
                    <source srcset="../assets/images-compressed/room__1start_bg4.webp" type="image/webp">
                    <img src="../assets/images-compressed/room__1start_bg4.png" class="room__1start-bg4" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room__1start_bg5.webp" type="image/webp">
                    <img src="../assets/images-compressed/room__1start_bg5.png" class="room__1start-bg5" alt="">
                </picture>
                <picture>
                    <source srcset="../assets/images-compressed/room__1start_bg6.webp" type="image/webp">
                    <img src="../assets/images-compressed/room__1start_bg6.png" class="room__1start-bg6" alt="">
                </picture>
            </div>
        </div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <window-enter>.
     */
    class WindowEnter
        extends Templated( Component, "window_enter_template" )
    {
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomId", "roomInfo", "roomPlayers", "roomPlayers.*", "roomPlayer", "roomPlayer.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если изменен идентификатор комнаты:
                case "roomId":
                    // Установка содержания поля ввода:
                    this.#setRoomId( transaction.value )
                    break
                // Если изменена информация комнаты:
                case "roomInfo":
                    // Отобразить места:
                    this.#showItems( transaction.value.size )
                    // Отобразить байин комнаты:
                    this.#setRoomBuyinAmount(
                        transaction.value.buyin
                      , transaction.value.currency
                    )
                    break
                // Если изменен текущий игрок:
                case "roomPlayer":
                    // Обновить свойства:
                    transaction.expand()
                    break
                // Если изменен статус текущего игрока:
                case "roomPlayer.state":
                    // Если игровой статус "готов":
                    if ( transaction.value === "ready" ) {
                        // Отобразить кнопку ожидания:
                        this.#showWaitingButton()
                    }
                    // Если игрок не готов:
                    else {
                        // Отобразить кнопку готовности:
                        this.#showReadyButton()
                    }
                    break
                // Если установка игроков:
                case "roomPlayers":
                    // Выделить вызов сеттеров:
                    transaction.expandCalls( "set" )
                    // Выделить вызов геттеров:
                    transaction.expandCallResults( "get(*)" )
                    break
                // Если обновление состояния:
                case "roomPlayers.get(*).state":
                    // Получить параметры транзакции:
                    var [ seat ] = transaction.parameters
                    // Если игрок готов:
                    if ( transaction.value === "ready" ) {
                        // Показать статус готовности:
                        this.#setListItemReady( seat )
                    }
                    // Если игрок не готов:
                    else {
                        // Показать статус не готов:
                        this.#setListItemWaiting( seat )
                    }
                    break
                // Если установка пользователя:
                case "roomPlayers.set":
                    // Получить значения из вызова:
                    var [ seat, player ] = transaction.arguments
                    // Установка имени игрока:
                    this.#setListItemName( seat, player.name )
                    break
                // Если удаление пользователя:
                case "roomPlayers.delete":
                    // Получить значения из вызова:
                    var [ seat ] = transaction.arguments
                    // Сбросить игрока:
                    this.#resetItem( seat )
                    break
            }
        }
        /**
         * Этот метод устанавливает идентификатор текущей комнаты.
         * 
         * @param {String} id           Идентификатор (номер) комнаты.
         * @returns undefined
         */
        #setRoomId( id ) {
            // Установка содержания элемента:
            this.querySelector( "[data-ref-room-id]" ).innerHTML = id
            // Установка содержания полей ввода:
            for ( const inputElement of this.querySelectorAll( "[data-ref-room-id-input]" ) ) {
                inputElement.value = id
            }
        }
        /**
         * Этот метод отображает количество позиций соответственно количеству мест за столом.
         * 
         * @param {Number} size             Количество мест за столом.
         * @returns undefined
         */
        #showItems( size ) {
            // Обойти позиции:
            for ( let iteration = 1
                ; iteration <= 6
                ; iteration++
                ) {
                // Получить элемент списка:
                const listItemElement = this.querySelector( `[data-ref-enter-list-${ iteration }]` )
                // Если это отображаемое место:
                if ( iteration <= size ) {
                    // Удалить класс скрытия:
                    listItemElement.classList.remove( "is-hidden" )
                }
                // Если это скрытое место:
                else {
                    // Добавить класс скрытия:
                    listItemElement.classList.add( "is-hidden" )
                }
            }
        }
        /**
         * Этот метод сбрасывает данные позиции.
         * 
         * @param {Number} seat             Место.
         * @returns undefined
         */
        #resetItem( seat ) {
            // Получить элемент списка:
            const listItemElement = this.querySelector( `[data-ref-enter-list-${ seat }]` )
            // Если элемент найден:
            if ( listItemElement !== null ) {
                // Сбросить класс контейнера:
                listItemElement.classList.remove( "ready" )
                // Получить элемент имени для элемента списка:
                const nameElement = listItemElement.querySelector( "[data-ref-enter-list-name]" )
                // Cбросить имя:
                nameElement.textContent = ""
                // Получить блоки статусов:
                const readyElement   = listItemElement.querySelector( "[data-ref-enter-list-status-ready]" )
                    , waitingElement = listItemElement.querySelector( "[data-ref-enter-list-status-waiting]" )
                // Скрыть статусы готовности и ожидания:
                readyElement.classList.add( "is-hidden" )
                waitingElement.classList.remove( "is-hidden" )
            }
        }
        /**
         * Этот метод устанавливает имя на определенной позиции списка входа.
         * 
         * @param {Number} seat             Место.
         * @param {String} name             Имя игрока.
         * @returns undefined
         */
        #setListItemName( seat, name ) {
            // Получить элемент списка:
            const listItemElement = this.querySelector( `[data-ref-enter-list-${ seat }]` )
            // Если элемент найден:
            if ( listItemElement !== null ) {
                // Получить элемент имени для элемента списка:
                const nameElement = listItemElement.querySelector( "[data-ref-enter-list-name]" )
                // Установить имя:
                nameElement.textContent = name
            } 
        }
        /**
         * Этот метод устанавливает статус "готов" на определенной позиции списка входа.
         * 
         * @param {Number} seat             Место.
         * @returns undefined
         */
        #setListItemReady( seat ) {
            // Получить элемент списка:
            const listItemElement = this.querySelector( `[data-ref-enter-list-${ seat }]` )
            // Если элемент найден:
            if ( listItemElement !== null ) {
                // Установить класс контейнера:
                listItemElement.classList.add( "ready" )
                // Получить блоки статусов:
                const readyElement   = listItemElement.querySelector( "[data-ref-enter-list-status-ready]" )
                    , waitingElement = listItemElement.querySelector( "[data-ref-enter-list-status-waiting]" )
                // Скрыть ожидание и показать готовность:
                waitingElement.classList.add( "is-hidden" )
                readyElement.classList.remove( "is-hidden" )
            }
        }
        /**
         * Этот метод устанавливает статус "ожидает" на определенной позиции списка входа.
         * 
         * @param {Number} seat             Место.
         * @returns undefined
         */
        #setListItemWaiting( seat ) {
            // Получить элемент списка:
            const listItemElement = this.querySelector( `[data-ref-enter-list-${ seat }` )
            // Если элемент найден:
            if ( listItemElement !== null ) {
                // Сбросить класс контейнера:
                listItemElement.classList.remove( "ready" )
                // Получить блоки статусов:
                const readyElement   = listItemElement.querySelector( "[data-ref-enter-list-status-ready]" )
                    , waitingElement = listItemElement.querySelector( "[data-ref-enter-list-status-waiting]" )
                // Показать ожидание и скрыть готовность:
                waitingElement.classList.remove( "is-hidden" )
                readyElement.classList.add( "is-hidden" )
            }
        }
        /**
         * Этот метод устанавливает размер байина комнаты.
         * 
         * @param {Number} amount           Количество фишек байин в валюте стола.
         * @param {String} currency         Валюта.
         * @returns undefined
         */
        #setRoomBuyinAmount( amount, currency ) {
            // Получение элемента вывода байина:
            const buyinElement = this.querySelector( "[data-ref-room-buyin]" )
                , buyinCurrencyElement = this.querySelector( "[data-ref-room-buyin-currency]" )
            // Вывод количества фишек банка:
            buyinElement.textContent         = amount
            buyinCurrencyElement.textContent = currency
        }
        /**
         * Этот метод отображает кнопку неготовности.
         * 
         * @returns undefined
         */
        #showWaitingButton() {
            // Получить элементы кнопок:
            const readyButtonElement   = this.querySelector( "[data-ref-ready-button]" )
                , waitingButtonElement = this.querySelector( "[data-ref-waiting-button]" )
            // Скрыть кнопку готов и показать не готов:
            readyButtonElement.classList.add( "is-hidden" )
            waitingButtonElement.classList.remove( "is-hidden" )
        }
        /**
         * Этот метод отображает кнопку готовности.
         * 
         * @returns undefined
         */
        #showReadyButton() {
            // Получить элементы кнопок:
            const readyButtonElement   = this.querySelector( "[data-ref-ready-button]" )
                , waitingButtonElement = this.querySelector( "[data-ref-waiting-button]" )
            // Скрыть кнопку не готов и показать готов:
            readyButtonElement.classList.remove( "is-hidden" )
            waitingButtonElement.classList.add( "is-hidden" )
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "window-enter", WindowEnter )
</script>