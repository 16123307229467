/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать объект ответов от сервера:
import Authorized from "../authorized.mjs"
// Использовать объект запроса входа за стол:
import RoomEnterRequest from "../../request/roomenter.mjs"
// Использовать объект состояния приложения:
import { state } from "../../../main.mjs"

/**
 * Этот класс описывает объект ответов от веб API на запрос:
 * /api/v1/room/getState
 */
export default class RoomGetState
    extends Authorized
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return request.uri === "/room/getState"
    }
    /**
     * Этот метод обрабатывает ответ сервера.
     *
     * @param {Object}  data                    Полученные данные с сервера.
     * @param {Request} request                 Экземпляр объекта запроса.
     * @returns undefined
     */
    async process( data, request ) {
        // Если стол существует и играбелен:
        if ( ( data.exists == true )
          && ( data.playable == true )
           ) {
            // Сохранить время последней операции:
            state.lastDataMicrotime = data.now
            // Обновить информацию стола:
            state.roomInfo    = data.info
            // Обновить состояние борда:
            state.roomBoard   = data.board
            // Обновить состояние игроков:
            state.roomPlayers = new Map( Object.entries( data.players ) )
            // Обновить состояние игрока:
            state.roomPlayer  = data.state
            // Отправить запрос на вход за стол:
            state.client.server.execute(
                new RoomEnterRequest( data.info.room_id )
            )
            // Если игрок заморожен:
            if ( data.state.frozen == true ) {
                // Открыть оверлей окна заморозки:
                state.currentOverlay = "window-freeze"
            }
        }
        // Если стол не существует:
        else {
            // Вывести ошибку:
            globalThis.alert( "Стол не найден" )
        }
    }
}