/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать объект ответов от сервера:
import Response from "../response.mjs"
// Использовать запрос регенерации сессии:
import SessionRegenerateRequest from "../request/sessionregenerate.mjs"
// Использовать объект состояния:
import { state } from "../../main.mjs"

/**
 * Этот класс описывает объект авторизованных ответов от веб API.
 */
export default class Authorized
    extends Response
{
    /**
     * Этот метод обрабатывает объект запроса перед отправкой на сервер. Если метод возвращает true,
     * то переданный запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    async prepare( request ) {
        // Проверить, что запрос еще не был авторизован:
        if ( request.headers.has( "Authorization" ) == false ) {
            // Получить текущий объект сессии и сервера:
            const { session } = state.client
            // Проверить, что объект авторизован:
            if ( session.valid == true ) {
                // Установка заголовка авторизации:
                request.headers.set( "Authorization"
                                   , `Bearer ${ session.accessToken }`
                                   )
            }
        }
        // Выполнить запрос:
        return true
    }
    /**
     * Этот метод обрабатывает ошибку запроса к серверу.
     *
     * @param {Object}  answer                  Объект ответа от сервера.
     * @param {Request} request             Экземпляр объекта запроса.
     * @returns undefined
     */
    async processError( answer, request ) {
        // Если статус текущего запроса 403:
        if ( answer.status === 403 ) {
            // Получить текущий объект сессии и сервера:
            const { session, server } = state.client
            // Отправить запрос на регенерацию сессии:
            server.execute(
                new SessionRegenerateRequest( session.refreshToken )
            )
            // Отметить ошибку обработанной:
            return true
        }
        // Иначе не обрабатывать ошибку:
        return false
    }
}