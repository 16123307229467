<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"

    /**
     * Этот класс описывает кастомный элемент <application-screen>.
     */
    class ApplicationScreen
        extends Component
    {
        /** @property {HTMLElement}             Элемент содержания, открытого внутри текущего экрана. */
        #innerComponent = undefined
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "currentScreen" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени свойства:
            switch ( transaction.name ) {
                // Если изменен текущий экран:
                case "currentScreen":
                    // Если экран закрыт:
                    if ( transaction.value === undefined ) {
                        // Скрыть внутренний компонент:
                        this.#hideComponent()
                    }
                    // Если экран открыт:
                    else {
                        // Открыть его внутри текущего компонента:
                        this.#showComponent( transaction.value )
                    }
                    break
            }
        }
        /**
         * Этот метод создает новый элемент и устанавливает его в качестве текущего открытого экрана.
         *
         * @param {String} tagName              Имя тега элемента.
         * @param {Object} [attributes]         Опциональные атрибуты элемента.
         * @returns {HTMLElement}
         */
        #showComponent( tagName, attributes = {} ) {
            // Скрыть текущий внутренний компонент, если он установлен:
            this.#hideComponent()
            // Создать элемент:
            const element = globalThis.document.createElement( tagName )
            // Обойти атрибуты элемента:
            for ( const [ attributeName, attributeValue ] of Object.entries( attributes ) ) {
                // Установить атрибут:
                element.setAttribute( attributeName, attributeValue )
            }
            // Установить элемент, как открытый экран:
            this.#innerComponent = element
            // Вставить элемент в текущий узел:
            this.appendChild( element )
            // Вернуть созданный элемент компонента:
            return element
        }
        /**
         * Этот метод скрывает отображенный внутри элемента компонент.
         * 
         * @returns undefined
         */
        #hideComponent() {
            // Если компонент отображается внутри оверлея:
            if ( this.#innerComponent !== undefined ) {
                // Удалить этот элемент:
                this.removeChild( this.#innerComponent )
                // Сбросить текущий внутренний компонент:
                this.#innerComponent = undefined
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "application-screen", ApplicationScreen )
</script>