/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс объектов обработки запросов:
import Response from "./response.mjs"
// Использовать класс объектов отправки запросов:
import Request from "./request.mjs"

/**
 * Этот класс описывает удаленный сервер на который могут быть переданы запросы описанные
 * объектами Request и обработаны ответы при помощи объектов Response.
 */
export default class Server
{
    /** @property {URL}                 URL для подключения к серверу. */
    #url           = undefined
    /** @property {Number}              Стандартный таймаут запроса в секундах. */
    #timeout       = 30
    /** @property {Headers}             Объект заголовоков общих для всех запросов. */
    #commonHeaders = new Headers()
    /** @property {Set}                 Коллекция обработчиков запросов. */
    #listeners     = new Set()
    /** @property {WeakMap}             Слабая карта контроллеров отмены запросов. */
    #controllers   = new WeakMap()
    /**
     * Этот геттер возвращает URL для подключения к серверу, который был установлен для текущего объекта.
     * 
     * @returns {String}
     */
    get url() {
        return this.#url
    }
    /**
     * Этот сеттер устанавливает URL для текущего объекта.
     * 
     * @param {URL} url                 Объект URL для подключения сервера.
     * @returns undefined
     */
    set url( url ) {
        this.#url = url
    }
    /**
     * Этот геттер возвращает таймаут, установленный для текущего объекта.
     * 
     * @returns {Number}
     */
    get timeout() {
        return this.#timeout
    }
    /**
     * Этот сеттер устанавливает таймаут для текущего объекта.
     * 
     * @param {Number} seconds              Количество секунд таймаута.
     * @returns undefined
     */
    set timeout( seconds ) {
        this.#timeout = seconds
    }
    /**
     * Этот геттер возвращает карту общих заголовков запросов для текущего объекта.
     * 
     * @returns {Headers}
     */
    get commonHeaders() {
        return this.#commonHeaders
    }
    /**
     * Этот метод добавляет объекты обработки ответов к прослушиванию результатов выполнения
     * запросов к API для текущего объекта.
     * 
     * @param {Response} ...listeners       Список слушателей ответов от API.
     * @returns undefined
     */
    subscribe( ...listeners ) {
        // Добавить переданные объекты в набор:
        listeners.forEach( listener => this.#listeners.add( listener ) )
    }
    /**
     * Этот метод удаляет объекты обрабтоки ответов из текущего объекта подключения к API.
     * 
     * @param {Response} ...listeners       Список слушателей ответов от API.
     * @returns undefined
     */
    unsubscribe( ...listeners ) {
        // Удалить переданные объекты из набора:
        listeners.forEach( listener => this.#listeners.delete( listener ) )
    }
    /**
     * Этот метод обрабатывает ответ от сервера при помощи установленных объектов обработки.
     * 
     * @param {Request} request             Объект запроса на сервер.
     * @returns {Boolean} 
     */
    async execute( request ) {
        // Подготовить контейнер для обработки:
        const processing = []
        // Обработать зарегистрированные объекты ответов:
        for ( const response of this.#listeners ) {
            // Если этот ответ может быть обработан:
            if ( response.test( request ) == true ) {
                // Обработать метод отправки запроса:
                if ( await response.prepare( request ) == true ) {
                    // Добавить ответ в ожидания:
                    processing.push( response )
                }
            }
        }
        // Если какой-то объект может обработать этот запрос:
        if ( processing.length > 0 ) {
            // Выполнить запрос на сервер:
            const answer = await this.fetch( request )
            // Если статус выполнения запроса успешен:
            if ( answer.ok == true ) {
                // Обработать выбранные ответы:
                processing.map( response => response.process( answer.data, request ) )
                // Ожидать завершения процессинга:
                await Promise.all( processing )
                // Вернуть true при успехе:
                return true
            }
            // Иначе обработать ошибку:
            else {
                // Для всех объектов ошибок:
                for ( const response of processing ) {
                    // Если обработка ошибки вернула true:
                    if ( await response.processError( answer, request ) == true ) {
                        // Прервать обработку:
                        break
                    }
                }
                // Вернуть false при ошибке:
                return false
            }
        }
    }
    /**
     * Этот метод отправляет запрос описанный переданным объектом Request.
     * 
     * @param {Request} request         Экземпляр объекта запроса.
     * @returns {Object}
     */
    async fetch( request ) {
        // Объявить переменную данных:
        let data = null
        // Перехватить объект ошибки:
        try {
            // Если ошибка установки url объекта:
            if ( this.#url === undefined ) {
                // Вывод ошибки 
                throw new Error(
                    "Не установлен url сервера перед вызовом запроса"
                )
            }
            // Создать итоговый объект заголовков:
            const headers = new Headers(
                [ ...this.#commonHeaders
                , ...request.headers
                ]
            )
            // Создать контроллер отмены запроса:
            const controller = new AbortController()
            // Получить объект конфигурации:
            const options = {
                "method"        : request.method
              , "headers"       : headers
              , "body"          : request.body
              , "mode"          : request.mode
              , "credentials"   : request.credentials
              , "cache"         : request.cache
              , "redirect"      : request.redirect
              , "referrer"      : request.referrer
              , "referrerPolicy": request.referrerPolicy
              , "integrity"     : request.integrity
              , "keepalive"     : request.keepalive
              , "signal"        : controller.signal
            }
            // Сохранить контроллер для объекта запроса:
            this.#controllers.set( request, controller )
            // Установить таймаут отмены запроса:
            const connectionTimeout = setTimeout(
                () => controller.abort()
              , 1000 * ( request.timeout || this.#timeout )
            )
            // Выполнить запрос через fetch:
            const response = await globalThis.fetch(
                this.#url.toString() + request.uri
              , options
            )
            // Сбросить таймаут соединения:
            clearTimeout( connectionTimeout )
            // Удалить контроллер отмена запроса:
            this.#controllers.delete( request )
            // Если тело ответа может быть получено:
            if ( response.ok == true ) {
                // Переключение по типу результата:
                switch ( request.responseType ) {
                    // Обработка текста:
                    case "text":
                        // Получение содержания ответа:
                        data = await response.text()
                        break
                    // Обработка формата форм:
                    case "formData":
                        // Получение объекта данных формы:
                        data = await response.formData()
                        break
                    // Обработка бинарных данных:
                    case "blob":
                        // Получение объекта бинарных данных:
                        data = await response.blob()
                        break
                    // Обработка буффера:
                    case "arrayBuffer":
                        // Получение буффера:
                        data = await response.arrayBuffer()
                        break
                    // Обработка стрима ответа:
                    case "stream":
                        // Получение самого body:
                        data = response.body
                        break
                    // Обработка ответа типа json:
                    case "json":
                    default:
                        // Получение объекта из json:
                        data = await response.json()
                        break
                }
            }
            // Вернуть результат:
            return { "ok"        : response.ok
                   , "status"    : response.status
                   , "statusText": response.statusText
                   , "type"      : request.responseType
                   , data
                   }
        }
        // Перехват ошибки выполнения запроса:
        catch ( error ) {
            // Установить статус и объект ошибки для запроса:
            request.error       = true
            request.errorObject = error
            // Вернуть ошибочный результат:
            return { "ok"        : false
                   , "status"    : 0
                   , "statusText": "error"
                   , "type"      : null
                   , data
                   }
        }
    }
    /**
     * Этот метод отменяет запрос на сервер, если для него был установлен активный
     * контроллер отмены. Метод возвращает true в случае успешной отмены.
     * 
     * @param {Request} request         Экземпляр объекта запроса.
     * @returns {Boolean}
     */
    abort( request ) {
        // Проверить, что в карте контроллеров есть объект запроса:
        if ( this.#controllers.has( request ) == true ) {
            // Получить контроллер отмены запроса:
            const controller = this.#controllers.get( request )
            // Выполнить отмену запроса:
            controller.abort()
            // Удалить контроллер отмены:
            this.#controllers.delete( request )
            // Вернуть true при успехе:
            return true
        }
        // Если не найдено, вернуть false:
        return false
    }
}