/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать объект ответов от сервера:
import Authorized from "../authorized.mjs"
// Использовать объект состояния приложения:
import { state } from "../../../main.mjs"

/**
 * Этот класс описывает объект ответов от веб API на запрос:
 * /api/v1/room/unfreeze
 */
export default class RoomUnfreeze
    extends Authorized
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return request.uri === "/room/unfreeze"
    }
    /**
     * Этот метод обрабатывает ответ сервера.
     *
     * @param {Object}  data                    Полученные данные с сервера.
     * @param {Request} request                 Экземпляр объекта запроса.
     * @returns undefined
     */
    async process( data, request ) {
        // Если невозможно:
        if ( data.status == false ) {
            // Передать сообщение выхода:
            globalThis.top.postMessage( { type: "close" }, "*" )
        }
    }
}