/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс входящих сообщений:
import Incoming from "../../incoming.mjs"
// Использовать объект состояния:
import { state } from "../../../../main.mjs"

/**
 * Этот класс описывает входящее сообщение чата. 
 */
export default class AddMessage
    extends Incoming
{
    /**
     * Этот метод тестирует полученное сообщение и возвращает true, если сообщение
     * может быть обработано текущим экземпляром объекта входящего сообщения.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns {Boolean}
     */
    test( message ) {
        return ( message.channel.startsWith( "chat_" ) == true )
            && ( message.event === "addMessage" )
    }
    /**
     * Этот метод обрабатывает получение сообщения из сокета.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns undefined
     */
    process( message ) {
        // Получить данные сообщения:
        const username    = message.data.name
            , messageText = message.data.message
            , dateObject  = new Date( message.data.date )
            , date        = `${ dateObject.getHours() }:${ dateObject.getMinutes() }`
        // Добавить сообщение:
        state.chatMessages.add( { username, messageText, date } )
    }
}