/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/**
 * Этот класс описывает объект обработчика ответа от удаленного сервера API.
 */
export default class Response
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request                 Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return false
    }
    /**
     * Этот метод обрабатывает объект запроса перед отправкой на сервер. Если метод возвращает true,
     * то переданный запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request                 Объект запроса.
     * @returns {Boolean}
     */
    async prepare( request ) {
        return true
    }
    /**
     * Этот метод обрабатывает ответ сервера.
     *
     * @param {Object}  data                    Полученные данные с сервера.
     * @param {Request} request                 Экземпляр объекта запроса.
     * @returns undefined
     */
    async process( data, request ) {
        return
    }
    /**
     * Этот метод обрабатывает ошибку запроса к серверу.
     *
     * @param {Object}  answer                  Объект ответа от сервера.
     * @param {Request} request                 Экземпляр объекта запроса.
     * @returns {Boolean}
     */
    async processError( answer, request ) {
        return false
    }
}