/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс исходящих сообщений:
import Outgoing from "../outgoing.mjs"

/**
 * Этот класс описывает сообщение отписки от канала.
 */
export default class Unsubscribe
    extends Outgoing
{
    /** @property {String}          Описание команды. */
    command = "unsubscribe"
    /** @property {String}          Имя канала для подписки. */
    channel = undefined
    /**
     * Конструктор объекта класса Subscribe.
     * 
     * @param {String} channel      Имя канала для подписки.
     */
    constructor( channel ) {
        super()
        // Установка значений переменных объекта:
        this.channel = channel
    }
}