/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс объектов входящих сообщений:
import Incoming from "./message/incoming.mjs"
// Использовать класс объектов исходящих сообщений:
import Outgoing from "./message/outgoing.mjs"

/**
 * Этот класс описывает подключение по WebSocket к серверу для обработки сообщений.
 */
export default class Socket
{
    /** @property {String}              Адрес соединения с вебсокет. */
    #url              = undefined
    /** @property {WebSocket}           Объект соединения с вебсокет. */
    #connection       = undefined
    /** @property {Boolean}             Текущий статус соединения. */
    #connected        = false
    /** @property {Promise}             Промис ожидания подключения. */
    #connecting       = undefined
    /** @property {Function}            Функция разрешения подключения. */
    #connectingDone   = undefined
    /** @property {Boolean}             Режим переподключения при ошибках. */
    #reconnect        = true
    /** @property {Number}              Таймаут переподключения в секундах. */
    #reconnectTimeout = 1
    /** @property {Error}               Объект ошибки соединения. */
    #error            = undefined
    /** @property {Set}                 Набор обработчиков сообщений от сервера. */
    #listeners        = new Set()
    /** @property {Set}                 Набор живых сообщений (повторяющихся при пересоединении). */
    #live             = new Set()
    /**
     * Этот геттер возвращает url установленный для текущего соединения с вебсокетом.
     * 
     * @returns {String}
     */
    get url() {
        return this.#url
    }
    /**
     * Этот сеттер устанавливает URL для текущего объекта.
     * 
     * @param {URL} url                 Объект URL для подключения сервера.
     * @returns undefined
     */
    set url( url ) {
        this.#url = url
    }
    /**
     * Этот геттер возвращает объект соединения с вебсокетом.
     * 
     * @returns {WebSocket}
     */
    get connection() {
        return this.#connection
    }
    /**
     * Этот геттер возвращает true, если текущий объект подключен.
     * 
     * @returns {Boolean}
     */
    get connected() {
        return this.#connected
    }
    /**
     * Этот геттер возвращает true, если текущий объект имеет режим переподключения при ошибке.
     * 
     * @returns {Boolean}
     */
    get reconnect() {
        return this.#reconnect
    }
    /**
     * Этот сеттер устанавливает режим переподключения к сокету при ошибке.
     * 
     * @param {Boolean} mode                    Если true будет переподключение при ошибке.
     * @returns undefined
     */
    set reconnect( mode ) {
        this.#reconnect = mode
    }
    /**
     * Этот геттер возвращает количество секунд в таймауте передподключения при ошибке.
     * 
     * @returns {Number}
     */
    get reconnectTimeout() {
        return this.#reconnectTimeout
    }
    /**
     * Этот сеттер устанавливает количество секунд в таймауте передподключения при ошибке
     * 
     * @param {Number} seconds                  Секунд перед повторным переподключением.
     * @returns undefined
     */
    set reconnectTimeout( seconds ) {
        this.#reconnectTimeout = seconds
    }
    /**
     * Этот геттер возвращает объект ошибки подключения к сокету.
     * 
     * @returns {Object}
     */
    get error() {
        return this.#error
    }
    /**
     * Этот метод добавляет объекты обработки ответов к прослушиванию сообщений сокета для 
     * текущего объекта.
     * 
     * @param {Incoming} ...listeners           Список слушателей ответов от сокета.
     * @returns undefined
     */
    subscribe( ...listeners ) {
        // Добавить переданные объекты в набор:
        listeners.forEach( listener => this.#listeners.add( listener ) )
    }
    /**
     * Этот метод удаляет объекты обрабтоки ответов из текущего объекта подключения к сокету.
     * 
     * @param {Incoming} ...listeners       Список слушателей ответов от сокета.
     * @returns undefined
     */
    unsubscribe( ...listeners ) {
        // Удалить переданные объекты из набора:
        listeners.forEach( listener => this.#listeners.delete( listener ) )
    }
    /**
     * Этот метод обрабатывает открытие соединения с WebSocket.
     * 
     * @returns undefined
     */
    openCallback() {
        // Если объект еще не подключен:
        if ( this.#connected == false ) {
            // Установить статус соединения:
            this.#connected = true
            // Разрешить промис ожидания соединения:
            this.#connectingDone()
            // Если есть список живых сообщений:
            if ( this.#live.size > 0 ) {
                // Для всех сообщения списка живых:
                for ( const message of this.#live.values() ) {
                    // Повторить отправку:
                    this.transmit( message )
                }
            }
        }
    }
    /**
     * Этот метод обрабатывает сообщение полученное от WebSocket.
     * 
     * @param {String} message              Текстовое содержание сообщения.
     * @returns undefined
     */
    messageCallback( message ) {
        // Перехват ошибок:
        try {
            // Десериализация данных:
            const data = JSON.parse( message.data )
            // Выполнить обработку сообщения:
            this.recieve( data )
        }
        // В случае ошибки:
        catch ( error ) {
            // Сохранить объект ошибки:
            this.#error = error
        }
    }
    /**
     * Этот метод обрабатывает закрытие соединения с WebSocket.
     * 
     * @returns undefined
     */
    closeCallback() {
        // Если текущее соединение активно:
        if ( this.#connected == true ) {
            // Установить статус соединения:
            this.#connected = false
            // Сбросить промис ожидания соединения:
            this.#connectingDone()
        }
    }
    /**
     * Этот метод обрабатывает ошибку соединения с WebSocket.
     * 
     * @returns undefined
     */
    errorCallback() {
        // Выполнить реакцию на отсоединение:
        this.closeCallback()
        // Если установлен режим повторного соединения:
        if ( this.#reconnect == true ) {
            // Установить таймер для повторного соединения:
            setTimeout(
                () => this.open()
              , this.#reconnectTimeout * 1000
            )
        }
    }
    /**
     * Этот метод создает новое подключение вебсокет для текущего объекта.
     * 
     * @returns {Boolean}
     */
    open() {
        // Если соединение в данный момент не активно:
        if ( this.#connection == undefined ) {
            // Сделать перехват ошибки:
            try {
                // Создать новый объект соединения с вебсокетом:
                this.#connection = new WebSocket( this.#url )
                // Установить обработчики событий для объекта:
                this.#connection.onopen    = ( ...args ) => this.openCallback( ...args )
                this.#connection.onmessage = ( ...args ) => this.messageCallback( ...args )
                this.#connection.onclose   = ( ...args ) => this.closeCallback( ...args )
                this.#connection.onerror   = ( ...args ) => this.errorCallback( ...args )
                // Создать промис ожидания подключения:
                this.#connecting = new Promise(
                    resolve => this.#connectingDone = resolve
                )
                // Вернуть true в случае успеха:
                return true
            }
            // Если перехвачено исключение:
            catch ( error ) {
                // Сохранить объект ошибки соединения:
                this.#error = error
                // Вернуть false в случае ошибки:
                return false
            }
        }
    }
    /**
     * Этот метод запускает обработку входящего сообщения из вебсокета при помощи зарегистрированных
     * объектов прослушивания событий.
     * 
     * @param {Object} message          Объект полученного сообщения.
     * @returns {Boolean}
     */
    async recieve( message ) {
        // Подготовить контейнер для обработки:
        const processing = []
        // Обработать зарегистрированные объекты слушателей:
        for ( const listener of this.#listeners ) {
            // Если это сообщение может быть обработано:
            if ( listener.test( message ) == true ) {
                // Обработать этот ответ:
                processing.push( listener.process( message ) )
            }
        }
        // Если какой-то объект может обработать этот запрос:
        if ( processing.length > 0 ) {
            // Ожидать завершения процессинга:
            await Promise.all( processing )
            // Вернуть true при успехе:
            return true
        }
        // Иначе вернуть false:
        return false
    }
    /**
     * Этот метод отправляет в сокет заданное экземпляром объекта Outgoing сообщение.
     * 
     * @param {Outgoing} message        Сообщение для отправки.
     * @param {Boolean}  [live]         Режим повторной отправки при пересоединении.
     * @returns {Boolean}
     */
    async transmit( message, live ) {
        // Ожидать организации подключения:
        await this.#connecting
        // Если подключение активно:
        if ( this.#connected == true ) {
            // Отправить сообщение в сокет:
            this.#connection.send( JSON.stringify( message ) )
            // Если установлен режим повторной отправки:
            if ( live == true ) {
                // Добавить сообщение в список повторяемых:
                this.#live.add( message )
            }
            // Вернуть true при успехе:
            return true
        }
        // Вернуть false в случае провала:
        return false
    }
    /**
     * Этот метод убирает заданное экземпляром Outgoing сообщение из списка повторяемых
     * при переподключении сокета.
     * 
     * @param {Outgoing} message        Сообщение для отмены повторной отправки.
     * @returns {Boolean}
     */
    dismiss( message ) {
        return this.#live.delete( message )
    }
    /**
     * Этот метод убирает все повторяемые запросы сокета.
     * 
     * @returns undefined
     */
    dismissAll() {
        return this.#live.clear()
    }
    /**
     * Этот метод закрывает соединение с сокетом.
     * 
     * @returns undefined
     */
    close() {
        // Выполнить закрытие подключения:
        this.#connection.close()
    }
}