/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/**
 * Этот класс описывает объект сообщения WebSocket.
 */
export default class Message
{}