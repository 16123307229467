/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс объектов роута:
import Route from "../route.mjs"
// Получить объект запроса создания сессии:
import SessionCreateRequest from "../request/sessioncreate.mjs"
// Получить объект сообщения 
import AuthorizeMessage from "../message/outgoing/authorize.mjs"
// Использовать объект состояния:
import { state } from "../../main.mjs"

/**
 * Этот класс представляет объект роута обработки входа в приложение.
 */
export default class Open
    extends Route
{
    /**
     * Этот метод тестирует переданный объект URL и возвращает true, если он может быть обработан
     * текущим объектом роута.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns {Boolean}
     */
    test( url, historyState ) {
        // Проверка запроса пути:
        return url.pathname === "/"
    }
    /**
     * Этот метод обрабатывает открытие роута в приложении.
     * 
     * @param {URL}    url              Объект открываемого адреса в приложении.
     * @param {Object} historyState     Объект состояния из истории.
     * @returns undefined
     */
    async open( url, historyState ) {
        // Получить параметры открытия сервера:
        let token     = url.searchParams.get( "token" )
          , apiUrl    = url.searchParams.get( "api-url" )
          , wsUrl     = url.searchParams.get( "ws-url" )
          , roomId    = url.searchParams.get( "room-id" )
          , userId    = url.searchParams.get( "user-id" )
          , timestamp = url.searchParams.get( "time" )
          , nonce     = url.searchParams.get( "nonce" )
        // Если не передан токен:
        if ( token === null ) {
            // Вывести ошибку и прервать запуск приложения:
            globalThis.alert(
                "Невозможно запустить приложение, не передан уникальный ключ запуска"
            )
            return
        }
        console.log( "CALL" )
        // Получить текущий объект сессии, роутера, сервера и сокета:
        const { session, router, server, socket } = state.client
        // Установка URL для сервера и сокета:
        server.url = new URL( apiUrl )
        socket.url = new URL( wsUrl )
        // Отправить запрос на сервер:
        await server.execute(
            new SessionCreateRequest( userId, timestamp, token, nonce )
        )
        // Если текущая сессия авторизована:
        if ( session.valid ) {
            // Открыть соединение с сокетом:
            socket.open()
            // Отправить сообщение авторизации:
            socket.transmit( new AuthorizeMessage( session.accessToken ), true )
            // Если передан параметр открытия комнаты:
            if ( roomId !== null ) {
                // Переход к странице комнаты:
                router.open( `/room?id=${ roomId }` )
            }
            // Если параметр не передан:
            else {
                // Ошибка подключения к серверу:
                globalThis.alert(
                    "Невозможно запустить приложение, не передан иденитификатор комнаты"
                )
            }
        }
        // Иначе вывести ошибку:
        else {
            // Ошибка подключения к серверу:
            globalThis.alert(
                "Невозможно авторизовать подключение"
            )
        }
    }
}