/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать общий класс компонентов:
import Component from "../component.mjs"

// Объявить переменную хранения компонент:
const draggableComponents = new Set()
/**
 * Этот класс описывает компонент который можно перетаскивать по странице.
 */
export default class DragAndDrop
    extends Component
{
    /**
     * Этот метод выполняется при подключении элемента в документ.
     * 
     * @returns undefined
     */
    connectedCallback() {
        // Если в родителе объявлен отклик:
        if ( super.connectedCallback instanceof Function ) {
            // Выполнить родительский отклик подключения:
            super.connectedCallback()
        }
        // Добавить компонент в контейнер:
        draggableComponents.add( this )
        // Подавить обработку события dragstart:
        this.addEventListener(
            "dragstart"
          , event => {
                // Подавить событие:
                event.stopPropagation()
                event.preventDefault()
                return false
            }
        )
        // Установить обработчик события клика:
        this.addEventListener(
            "mousedown"
          , event => {
                // Если не установлен флаг перемещения:
                if ( ( "drag" in this.dataset == false )
                  || ( this.dataset.drag == "false" )
                   ) {
                    // Выход из обработчика:
                    return
                }
                // Объявить переменную срабатывания первого отклика:
                let start_callback_fired = false
                // Объявить имя отклика перемещения:
                let mouse_move_callback
                  , mouse_up_callback
                  , mouse_leave_callback
                // Установить начальную позицию:
                const originalX = event.clientX
                    , originalY = event.clientY
                // Установить текущие позиции:
                let lastX = originalX
                  , lastY = originalY
                // Получить первый дочерний элемент:
                const draggingElement = this.firstChild
                // Установить класс:
                draggingElement.classList.add( "is-dragging" )
                // Сбросить transition:
                draggingElement.style.transition = "transform .05s linear"
                draggingElement.style.willChange = "transform"
                // Установка обработчика на перемещение в окне:
                globalThis.addEventListener(
                    "mousemove"
                  , mouse_move_callback = event => {
                        // Посчитать сдвиг:
                        const shiftX = Math.round( event.clientX - originalX ) 
                            , shiftY = Math.round( event.clientY - originalY )
                        // Если позиции изменены:
                        if ( ( shiftX != lastX ) && ( shiftY != lastY ) ) {
                            // Стилизовать сдвиг элементу:
                            globalThis.requestAnimationFrame(
                                () => {
                                    draggingElement.style.transform = `translate(${ shiftX }px,${ shiftY }px)`
                                }
                            )
                        }
                        // Если еще не вызывался отклик начала:
                        if ( start_callback_fired == false ) {
                            // Если значение отличается больше чем на 5px:
                            if ( ( Math.abs( originalX - lastX ) > 5 )
                              || ( Math.abs( originalY - lastY ) > 5 )
                               ) {
                                // Обойти все объекты drag'n'drop:
                                for ( const draggableComponent of draggableComponents.values() ) {
                                    // Выполнить обработчик начала перемещения:
                                    draggableComponent.dragStartCallback( this )
                                }
                                // Установить отклик запущенным:
                                start_callback_fired = true
                            }
                        }
                        // Запомнить последние позиции:
                        lastX = event.clientX
                        lastY = event.clientY
                    }
                )
                // Установить обработчик поднятия клавишы мыши:
                globalThis.addEventListener(
                    "mouseup"
                  , mouse_up_callback = event => {
                        // Спрятать элемент:
                        draggingElement.hidden = true
                        // Получаем целевой элемент:
                        const targetElement = globalThis.document.elementFromPoint(
                            event.clientX
                          , event.clientY
                        )
                        // Показать элемент:
                        draggingElement.hidden = false
                        // Сбросить перемещение из следующего кадра:
                        globalThis.requestAnimationFrame(
                            () => {
                                // Сбросить перемещение:
                                draggingElement.style.transform = ""
                                // Сбросить transition:
                                draggingElement.style.transition = ""
                                // Удалить класс:
                                draggingElement.classList.remove( "is-dragging" )
                            }
                        )
                        // Обойти все объекты drag'n'drop:
                        for ( const draggableComponent of draggableComponents.values() ) {
                            // Если элемент содержит целевой элемент:
                            if ( draggableComponent.contains( targetElement ) == true ) {
                                // Если он может принять элемент:
                                if ( ( "drop" in draggableComponent.dataset )
                                  && ( draggableComponent.dataset.drop != "false" )
                                   ) {
                                    // Выполнить обработчик приема элемента:
                                    draggableComponent.dropCallback( this, targetElement )
                                }
                            }
                            // Выполнить обработчик конца перемещения:
                            draggableComponent.dragStopCallback( this )
                        }
                        // Удалить обработчик перемещения:
                        globalThis.removeEventListener( "mousemove", mouse_move_callback )
                        // Удалить себя:
                        globalThis.removeEventListener( "mouseup", mouse_up_callback )
                        // Удалить выход с экрана:
                        globalThis.removeEventListener( "mouseleave", mouse_leave_callback )
                    }
                )
                // Установить обработчик на выход из документа:
                globalThis.addEventListener(
                    "mouseleave"
                  , mouse_leave_callback = event => {
                        // Сбросить перемещение:
                        draggingElement.style.transform = ""
                        // Сбросить transition:
                        draggingElement.style.transition = ""
                        // Удалить класс:
                        draggingElement.classList.remove( "is-dragging" )
                        // Удалить обработчик перемещения:
                        globalThis.removeEventListener( "mousemove", mouse_move_callback )
                        // Удалить обработчик поднятия клавишы:
                        globalThis.removeEventListener( "mouseup", mouse_up_callback )
                        // Удалить себя:
                        globalThis.removeEventListener( "mouseleave", mouse_leave_callback )
                    }
                )
                // Подавить событие:
                event.stopPropagation()
                event.preventDefault()
                return false
            }
        )
        // Установить обработчик события тача:
        this.addEventListener(
            "touchstart"
          , event => {
                // Если не установлен флаг перемещения:
                if ( ( "drag" in this.dataset == false )
                  || ( this.dataset.drag == "false" )
                   ) {
                    // Выход из обработчика:
                    return
                }
                // Объявить имя отклика перемещения:
                let touch_move_callback
                  , touch_up_callback
                  , touch_leave_callback
                // Установить начальную позицию:
                const originalX = event.touches[ 0 ].clientX
                    , originalY = event.touches[ 0 ].clientY
                // Установить текущие позиции:
                let lastX = originalX
                  , lastY = originalY
                // Получить первый дочерний элемент:
                const draggingElement = this.firstChild
                // Установить класс:
                draggingElement.classList.add( "is-dragging" )
                // Сбросить transition:
                draggingElement.style.transition = "transform .05s linear"
                draggingElement.style.willChange = "transform"
                // Установка обработчика на перемещение в окне:
                globalThis.addEventListener(
                    "touchmove"
                  , touch_move_callback = event => {
                        // Посчитать сдвиг:
                        const shiftX = Math.round( event.touches[ 0 ].clientX - originalX ) 
                            , shiftY = Math.round( event.touches[ 0 ].clientY - originalY )
                        // Если позиции изменены:
                        if ( ( shiftX != lastX ) && ( shiftY != lastY ) ) {
                            // Стилизовать сдвиг элементу:
                            globalThis.requestAnimationFrame(
                                () => {
                                    draggingElement.style.transform = `translate(${ shiftX }px,${ shiftY }px)`
                                }
                            )
                        }
                        // Запомнить последние позиции:
                        lastX = event.clientX
                        lastY = event.clientY
                    }
                )
                // Установить обработчик поднятия пальца:
                globalThis.addEventListener(
                    "touchend"
                  , touch_up_callback = event => {
                        // Спрятать элемент:
                        draggingElement.hidden = true
                        // Получаем целевой элемент:
                        const targetElement = globalThis.document.elementFromPoint(
                            event.changedTouches[ 0 ].clientX
                          , event.changedTouches[ 0 ].clientY
                        )
                        // Показать элемент:
                        draggingElement.hidden = false
                        // Сбросить перемещение из следующего кадра:
                        globalThis.requestAnimationFrame(
                            () => {
                                // Сбросить перемещение:
                                draggingElement.style.transform = ""
                                // Сбросить transition:
                                draggingElement.style.transition = ""
                                // Удалить класс:
                                draggingElement.classList.remove( "is-dragging" )
                            }
                        )
                        // Обойти все объекты drag'n'drop:
                        for ( const draggableComponent of draggableComponents.values() ) {
                            // Если элемент содержит целевой элемент:
                            if ( draggableComponent.contains( targetElement ) == true ) {
                                // Если он может принять элемент:
                                if ( ( "drop" in draggableComponent.dataset )
                                  && ( draggableComponent.dataset.drop != "false" )
                                   ) {
                                    // Выполнить обработчик приема элемента:
                                    draggableComponent.dropCallback( this, targetElement )
                                }
                            }
                            // Выполнить обработчик конца перемещения:
                            draggableComponent.dragStopCallback( this )
                        }
                        // Удалить обработчик перемещения:
                        globalThis.removeEventListener( "touchmove", touch_move_callback )
                        // Удалить себя:
                        globalThis.removeEventListener( "touchend", touch_up_callback )
                        // Удалить выход с экрана:
                        globalThis.removeEventListener( "touchcancel", touch_leave_callback )
                    }
                )
                // Установить обработчик на выход из документа:
                globalThis.addEventListener(
                    "touchcancel"
                  , touch_leave_callback = event => {
                        // Сбросить перемещение:
                        draggingElement.style.transform = ""
                        // Сбросить transition:
                        draggingElement.style.transition = ""
                        // Удалить класс:
                        draggingElement.classList.remove( "is-dragging" )
                        // Удалить обработчик перемещения:
                        globalThis.removeEventListener( "touchmove", touch_move_callback )
                        // Удалить обработчик поднятия клавишы:
                        globalThis.removeEventListener( "touchend", touch_up_callback )
                        // Удалить себя:
                        globalThis.removeEventListener( "touchcancel", touch_leave_callback )
                    }
                )
                // Обойти все объекты drag'n'drop:
                for ( const draggableComponent of draggableComponents.values() ) {
                    // Выполнить обработчик начала перемещения:
                    draggableComponent.dragStartCallback( this )
                }
                // Подавить событие:
                event.stopPropagation()
                event.preventDefault()
                return false
            }
        )
    }
    /**
     * Этот метод выполняется при отключении элемента от тела документа.
     * 
     * @returns undefined
     */
    disconnectedCallback() {
        // Если в родителе объявлен отклик:
        if ( super.disconnectedCallback instanceof Function ) {
            // Выполнить родительский отклик подключения:
            super.disconnectedCallback()
        }
        // Убрать объект из контейнера:
        draggableComponents.delete( this )
    }
    /**
     * Этот метод обрабатывает событие начала перемещения любого элемента по экрану.
     * 
     * @param {DragAndDrop} movingComponent     Перемещаемый компонент.
     * @returns undefined
     */
    dragStartCallback( movingComponent ) {}
    /**
     * Этот метод обрабатывает событие конца перемещения любого компонента по экрану.
     * 
     * @param {DragAndDrop} movingComponent     Перемещаемый компонент.
     * @returns undefined
     */
    dragStopCallback( movingComponent ) {}
    /**
     * Этот метод обрабатывает сброс перемещаемого компонента в целевой элемент внутри текущего
     * компонента.
     * 
     * @param {DragAndDrop} movingComponent     Перемещаемый компонент.
     * @param {HTMLElement} targetElement       Целевой элемент.
     * @returns undefined
     */
    dropCallback( movingComponent, targetElement ) {}
}