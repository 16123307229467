/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс сообщения вебсокет:
import Message from "../message.mjs"

/**
 * Этот класс описывает общий обработчик получаемых сообщений.
 */
export default class Incoming
    extends Message
{
    /**
     * Этот метод тестирует полученное сообщение и возвращает true, если сообщение
     * может быть обработано текущим экземпляром объекта входящего сообщения.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns {Boolean}
     */
    test( message ) {
        return false
    }
    /**
     * Этот метод обрабатывает получение сообщения из сокета.
     * 
     * @param {Object} message              Данные сообщения.
     * @returns undefined
     */
    process( message ) {}
}