/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать объект ответов от сервера:
import Authorized from "../authorized.mjs"

/**
 * Этот класс описывает объект ответов от веб API на запрос:
 * /api/v1/room_chat/ping
 */
export default class ChatPing
    extends Authorized
{
    /**
     * Этот метод тестирует переданную строку части URL запроса, ответ которого обрабатывается.
     * Если метод возвращает true, этот запрос будет обработан при помощи текущего объекта ответа.
     * 
     * @param {Request} request             Объект запроса.
     * @returns {Boolean}
     */
    test( request ) {
        return request.uri === "/room_chat/ping"
    }
}