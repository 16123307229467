<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_timer_template">
    <div></div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-timer>.
     */
    class RoomTimer
        extends Templated( Component, "room_timer_template" )
    {
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            
        }
        /**
         * Этот метод выполняется при отключении шаблонизированного элемента от тела документа.
         * 
         * @returns undefined
         */
        templateDisconnectedCallback() {
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-timer", RoomTimer )
</script>
<!-- Стили компонента: -->
<style>
</style>