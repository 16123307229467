/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
const credentials = new Map()

/**
 * Этот объект представляет состояние хранимой пользовательской сессии.
 */
export default class Session
{
    /** @property {String}          Префикс хранилища сессии. */
    #prefix = undefined
    /**
     * Конструктор объекта класса Session.
     * 
     * @param {String} prefix       Префикс хранилища сессии.
     */
    constructor( prefix ) {
        // Установка значений переменных объекта:
        this.#prefix = prefix
    }
    /**
     * Этот геттер возвращает установленный для текущего объекта префикс хранилища.
     * 
     * @returns {String}
     */
    get prefix() {
        return this.#prefix
    }
    /**
     * Этот геттер возвращает токен доступа, который был назначен текущему объекту сессии.
     * 
     * @returns {String}
     */
    get accessToken() {
        //try {
        //    // Получить значение если доступен localStorage:
        //    if ( "localStorage" in globalThis ) {
        //        return globalThis.localStorage.getItem( `${ this.#prefix }.accessToken` )
        //    }
        //    return null
        //}
        //catch ( error ) {
            return credentials.get( "accessToken" )
        //}
    }
    /**
     * Этот сеттер устанавливает токен доступа для текущего объекта сессии.
     * 
     * @param {String} value                Токен доступа.
     * @returns undefined
     */
    set accessToken( value ) {
        //try {
        //    // Установить значение если доступен localStorage:
        //    if ( "localStorage" in globalThis ) {
        //        globalThis.localStorage.setItem( `${ this.#prefix }.accessToken`, value )
        //    }
        //}
        //catch ( error ) {
            credentials.set( "accessToken", value )
        //}
    }
    /**
     * Этот геттер возвращает токен обновления сессии, который был назначен для объекта.
     * 
     * @returns {String}
     */
    get refreshToken() {
        //try {
        //    // Получить значение если доступен localStorage:
        //    if ( "localStorage" in globalThis ) {
        //        return globalThis.localStorage.getItem( `${ this.#prefix }.refreshToken` )
        //    }
        //    return null
        //}
        //catch ( error ) {
            return credentials.get( "refreshToken" )
        //}
    }
    /**
     * Этот сеттер устанавливает токен обновления сессии для текущего объекта сессии.
     * 
     * @param {String} value                Токен обновления.
     * @returns undefined
     */
    set refreshToken( value ) {
        //try {
        //    // Установить значение если доступен localStorage:
        //    if ( "localStorage" in globalThis ) {
        //        globalThis.localStorage.setItem( `${ this.#prefix }.refreshToken`, value )
        //    }
        //}
        //catch ( error ) {
            credentials.set( "refreshToken", value )
        //}
    }
    /**
     * Этот геттер возвращает дату в которую токен доступа считается просроченным.
     * 
     * @returns {Number}
     */
    get expireAt() {
        //try {
        //    // Получить значение если доступен localStorage:
        //    if ( "localStorage" in globalThis ) {
        //        return globalThis.localStorage.getItem( `${ this.#prefix }.expireAt` )
        //    }
        //    return null
        //}
        //catch ( error ) {
            return credentials.get( "expireAt" )
        //}
    }
    /**
     * Этот сеттер устанавливает токен обновления сессии для текущего объекта сессии.
     * 
     * @param {Number} value                Дата (UTC в секундах) просрочки токена доступа.
     * @returns undefined
     */
    set expireAt( value ) {
        //try {
        //    // Установить значение если доступен localStorage:
        //    if ( "localStorage" in globalThis ) {
        //        globalThis.localStorage.setItem( `${ this.#prefix }.expireAt`, value )
        //    }
        //}
        //catch ( error ) {
            credentials.set( "expireAt", value )
        //}
    }
    /**
     * Этот геттер возвращает true, если для текущего объекта сессии был установлен токен доступа
     * и время его экспирации еще не наступило.
     * 
     * @returns {Boolean} 
     */
    get valid() {
        // Получить токен доступа и время жизни:
        const { accessToken, expireAt } = this
        // Если токен установлен:
        if ( accessToken !== null ) {
            // Если дата не прошла:
            if ( Number( expireAt ) > new Date().getTime() ) {
                // Вернуть true:
                return true
            }
        }
        // В ином случае вернуть false:
        return false
    }
    /**
     * Этот метод выполняет сброс текущей сессии.
     * 
     * @returns undefined
     */
    reset() {
        // Установить значение если доступен localStorage:
        //if ( "localStorage" in globalThis ) {
        //    // Удалить хранимые значения сессии:
        //    globalThis.localStorage.removeItem( `${ this.#prefix }.accessToken` )
        //    globalThis.localStorage.removeItem( `${ this.#prefix }.refreshToken` )
        //    globalThis.localStorage.removeItem( `${ this.#prefix }.expireAt` )
        //}
    }
}