<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_board_template">
    <div class="playing-room-board" data-ref-board-container></div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать компонент таскаемого элемента:
    import DragAndDrop from "../modules/component/draganddrop.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"
    // Использовать запрос атаки:
    import RoomAttackRequest from "../modules/request/roomattackrequest.mjs"
    // Использовать запрос защиты:
    import RoomDefendRequest from "../modules/request/roomdefendrequest.mjs"

    /**
     * Этот класс описывает компонент <room-board>.
     */
    class RoomBoard
        extends Templated( DragAndDrop, "room_board_template" )
    {
        /** @property {Set}             Отображаемые карты борда. */
        #showedCards = new Set()
        /** @property {HTMLELement}     Отображаемый дроп. */
        #dropCard = undefined
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomBoard", "roomBoard.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени транзакции:
            switch ( transaction.name ) {
                // Если это первичный вывод борда:
                case "roomBoard":
                    // Разбить транзакцию на события:
                    transaction.expand()
                    break
                // Если это изменение активной карты:
                case "roomBoard.active":
                    // Получить активную карту:
                    var active = transaction.value
                    // Если есть активная карта:
                    if ( ( active !== null )
                      && ( state.roomPlayer.defend == true )
                       ) {
                        // Получить значение и масть карты:
                        var [ activeCardValue, activeCardSuit ] = active.split( "" )
                        // Установить активную карту:
                        this.#setActiveCardOnBoard( activeCardValue, activeCardSuit )
                    }
                    // Если нет активной карты:
                    else {
                        // Сбросить активную карту:
                        this.#resetActiveCardOnBoard()
                    }
                    break
                // Если это модификация карт:
                case "roomBoard.cards":
                    // Получить карты:
                    var cards = transaction.value
                    // Если есть карты:
                    if ( cards.length ) {
                        // Вывести карты:
                        this.#updateCardsOnBoard( cards )
                    }
                    // Если карт нет:
                    else {
                        // Очистить карты:
                        this.#removeCardsFromBoard()
                    }
                    break
            }
        }
        /**
         * Этот метод обрабатывает событие начала перемещения любого элемента по экрану.
         * 
         * @param {DragAndDrop} movingComponent     Перемещаемый компонент.
         * @returns undefined
         */
        dragStartCallback( movingComponent ) {
            // Если перемещается карта:
            if ( movingComponent.nodeName == "ROOM-CARD" ) {
                // Если текущий игрок атакующий:
                if ( state.roomPlayer?.attack == true ) {
                    // Показать дроп:
                    this.#addDropCardOnBoard()
                }
            }
        }
        /**
         * Этот метод обрабатывает событие конца перемещения любого компонента по экрану.
         * 
         * @param {DragAndDrop} movingComponent     Перемещаемый компонент.
         * @returns undefined
         */
        dragStopCallback( movingComponent ) {
            // Если перемещается карта:
            if ( movingComponent.nodeName == "ROOM-CARD" ) {
                // Скрыть дроп:
                this.#removeDropCardOnBoard()
            }
        }
        /**
         * Этот метод обрабатывает сброс перемещаемого компонента в целевой элемент внутри текущего
         * компонента.
         * 
         * @param {DragAndDrop} movingComponent     Перемещаемый компонент.
         * @param {HTMLElement} targetElement       Целевой элемент.
         * @returns undefined
         */
        dropCallback( movingComponent, targetElement ) {
            // Получить имя карты:
            const cardName = movingComponent.dataset.value + movingComponent.dataset.suit
            // Если текущий игрок атакующий:
            if ( state.roomPlayer?.attack == true ) {
                // Отправить запрос атаки:
                state.client.server.execute(
                    new RoomAttackRequest(
                        state.roomId
                      , cardName
                    )
                )
            }
            // Если текущий игрок защищается:
            else {
                // Получить текущие карты:
                const cardComponents = this.querySelectorAll( "room-card" )
                // Обработать каждую карту:
                for ( const card of cardComponents ) {
                    // Если элемент равен или содержит целевой:
                    if ( ( targetElement === card )
                      || ( card.contains( targetElement ) == true )
                       ) {
                        // Получить имя целевой карты:
                        const targetCardName = card.dataset.value + card.dataset.suit
                        // Отправить запрос защиты:
                        state.client.server.execute(
                            new RoomDefendRequest(
                                state.roomId
                              , cardName
                              , targetCardName
                            )
                        )
                    }
                }
            }
            // Установить элементу отправленный класс:
            movingComponent.firstChild.classList.add( "is-dropped" )
            // Установить таймаут снятия класса:
            setTimeout(
                () => {
                    if ( movingComponent.parentNode != null ) {
                        // Снять элементу отправленный класс:
                        movingComponent.firstChild.classList.remove( "is-dropped" )
                    }
                }
              , 1000
            )
        }
        /**
         * Этот метод очищает борд от карт.
         * 
         * @returns undefined
         */
        #removeCardsFromBoard() {
            // Получить элемент контейнера карт на борде:
            const boardContainer = this.querySelector( "[data-ref-board-container]" )
            // Обойти все внутренние узлы:
            for ( const childNode of [ ...boardContainer.childNodes ] ) {
                // Удалить текущий узел:
                boardContainer.removeChild( childNode )
            }
            // Сбросить контейнер текущих карт:
            this.#showedCards.clear()
        }
        /**
         * Этот метод выводит карты из массива карт борда на текущем столе.
         * 
         * @param {Array} cards             Карты борда.
         * @returns undefined
         */
        #drawCardsOnBoard( cards ) {
            // Удалить все прежние карты:
            this.#removeCardsFromBoard()
            // Обход массива карт борда:
            for ( const [ bottom, top ] of cards ) {
                // Добавить карту на борд:
                this.#showedCards.add( bottom )
                                 .add( top )
                // Обработать нижнюю карту:
                const [ bottomCardValue, bottomCardSuit ] = bottom.split( "" )
                // Добавить карту на борд:
                this.#addCardOnBoard( bottomCardValue, bottomCardSuit )
                // Если есть верхняя карта:
                if ( top !== undefined ) {
                    // Обработать верхнюю карту:
                    const [ topCardValue, topCardSuit ] = top.split( "" )
                    // Добавить карту бьющую карту текущего блока:
                    this.#beatCardOnBoard( topCardValue
                                         , topCardSuit
                                         , bottomCardValue
                                         , bottomCardSuit
                                         )
                }
            }
        }
        /**
         * Этот метод выводит карты из массива карт борда на текущем столе.
         * 
         * @param {Array} cards             Карты борда.
         * @returns undefined
         */
        #updateCardsOnBoard( cards ) {
            // Обход массива карт борда:
            for ( const [ bottom, top ] of cards ) {
                // Обработать нижнюю карту:
                const [ bottomCardValue, bottomCardSuit ] = bottom.split( "" )
                // Если нет нижней:
                if ( this.#showedCards.has( bottom ) == false ) {
                    // Добавить карту на борд:
                    this.#addCardOnBoard( bottomCardValue, bottomCardSuit )
                    // Запомнить карту:
                    this.#showedCards.add( bottom )
                }
                // Если есть верхняя карта:
                if ( top !== undefined ) {
                    // Обработать верхнюю карту:
                    const [ topCardValue, topCardSuit ] = top.split( "" )
                    // Если нет верхней:
                    if ( this.#showedCards.has( top ) == false ) {
                        // Добавить карту бьющую карту текущего блока:
                        this.#beatCardOnBoard( topCardValue
                                             , topCardSuit
                                             , bottomCardValue
                                             , bottomCardSuit
                                             )
                        // Запомнить карту:
                        this.#showedCards.add( top )
                    }
                }
            }
        }
        /**
         * Этот метод добавляет карту на борд.
         * 
         * @param {String} value            Значение карты.
         * @param {String} suit             Масть карты.
         * @returns undefined
         */
        #addCardOnBoard( value, suit ) {
            // Получить элемент контейнера карт на борде:
            const boardContainer = this.querySelector( "[data-ref-board-container]" )
            // Создать новый блок карт:
            const block = globalThis.document.createElement( "div" )
            // Установить класс элемента:
            block.classList.add( "playing-room-board__block" )
            // Добавить блок в контейнер:
            boardContainer.appendChild( block )
            // Создать элемент карты:
            const cardComponent = globalThis.document.createElement( "room-card" )
            // Установить масть и значение карты:
            cardComponent.dataset.value = value
            cardComponent.dataset.suit = suit
            // Установить дополнительный класс карты:
            cardComponent.dataset.class = "playing-card_board"
            // Установить позицию карты:
            cardComponent.dataset.position = "bottom"
            // Добавить карту в блок:
            block.appendChild( cardComponent )
        }
        /**
         * Этот метод добавляет карту на борд как бьющую последнюю карту.
         * 
         * @param {String} value            Значение карты.
         * @param {String} suit             Масть карты.
         * @param {String} targetValue      Значение целевой карты.
         * @param {String} targetSuit       Масть целевой карты.
         * @returns undefined
         */
        #beatCardOnBoard( value, suit, targetValue, targetSuit ) {
            // Получить целевую карту борда:
            const targetCard = this.querySelector(
                `room-card[data-value="${ targetValue }"][data-suit="${ targetSuit }"]`
            )
            // Если карта найдена:
            if ( targetCard !== null ) {
                // Создать элемент карты:
                const cardComponent = globalThis.document.createElement( "room-card" )
                // Установить масть и значение карты:
                cardComponent.dataset.value = value
                cardComponent.dataset.suit = suit
                // Установить дополнительный класс карты:
                cardComponent.dataset.class = "playing-card_board"
                // Установить позицию карты:
                cardComponent.dataset.position = "top"
                // Получить блок целевой карты:
                const targetCardBlock = targetCard.parentNode
                // Добавить карту в блок:
                targetCardBlock.appendChild( cardComponent )
                // Установить битой карте режим ghost:
                targetCard.dataset.ghost = true
            }
        }
        /**
         * Этот метод устанавливает текущую активную карту борда.
         * 
         * @param {String} value            Значение карты.
         * @param {String} suit             Масть карты.
         * @returns undefined
         */
        #setActiveCardOnBoard( value, suit ) {
            // Сбросить активную карту борда:
            this.#resetActiveCardOnBoard()
            // Получить целевую карту борда:
            const targetCard = this.querySelector(
                `room-card[data-value="${ value }"][data-suit="${ suit }"]`
            )
            // Установить карту активной:
            targetCard.dataset.active = true
        }
        /**
         * Этот метод сбрасывает текущую активную карту борда.
         * 
         * @returns undefined
         */
        #resetActiveCardOnBoard() {
            // Получить текущую активную карту:
            const previousActiveCard = this.querySelector( "room-card[data-active]" )
            // Если активная карта есть:
            if ( previousActiveCard !== null ) {
                // Сбросить статус активности:
                delete previousActiveCard.dataset.active
            }
        }
        /**
         * Этот метод добавляет таргет для дропа карты на борд.
         * 
         * @returns {HTMLElement}
         */
        #addDropCardOnBoard() {
            // Если установлен дроп кард:
            if ( this.#dropCard == undefined ) {
                // Получить элемент контейнера карт на борде:
                const boardContainer = this.querySelector( "[data-ref-board-container]" )
                // Создать новый блок карт:
                const block = globalThis.document.createElement( "div" )
                // Установить класс элемента:
                block.classList.add( "playing-room-board__block" )
                block.classList.add( "is-drop-area" )
                // Добавить блок в контейнер:
                boardContainer.appendChild( block )
                // Сохранить блок дропа:
                this.#dropCard = block
            }
        }
        /**
         * Этот метод удаляет таргет для дропа карты с борда.
         * 
         * @returns undefined
         */
        #removeDropCardOnBoard() {
            // Если установлен дроп кард:
            if ( this.#dropCard !== undefined ) {
                // Удалить элемент:
                this.#dropCard.parentNode.removeChild( this.#dropCard )
                // Сбросить переменную:
                this.#dropCard = undefined
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-board", RoomBoard )
</script>