<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_enemies_template">
    <div class="playing-room-enemy" data-ref-room-enemies></div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-enemies>.
     */
    class RoomEnemies
        extends Templated( Component, "room_enemies_template" )
    {
        /** @property {Number}                  Текущее отображаемое количество игроков. */
        #playersCount = 0
        /** @property {Number}                  Текущая позиция игрока за столом. */
        #playerSeat   = 0
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomInfo", "roomPlayer", "roomPlayer.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени свойства:
            switch ( transaction.name ) {
                // Если изменена информация комнаты:
                case "roomInfo":
                    // Установить игроков:
                    this.#setPlayersCount( Number( transaction.value.size ) )
                    break
                // Если изменена информация игрока:
                case "roomPlayer":
                    // Если у игрока есть место:
                    if ( transaction.value.seat !== null ) {
                        // Установить позицию текущего игрока:
                        this.#setPlayerSeat( Number( transaction.value.seat ) )
                    }
                    break
                case "roomPlayer.seat":
                    // Если у игрока есть место:
                    if ( transaction.value !== null ) {
                        // Установить позицию текущего игрока:
                        this.#setPlayerSeat( Number( transaction.value ) )
                    }
                    break
            }
        }
        /**
         * Этот метод обновляет текущее количество игроков за столом.
         * 
         * @param {Number} playersCount         Новое количество игроков за столом.
         * @returns undefined
         */
        #setPlayersCount( playersCount ) {
            // Если количество изменено:
            if ( playersCount !== this.#playersCount ) {
                // Установить новое количество:
                this.#playersCount = playersCount
                // Получить контейнер вывода игроков:
                const enemiesContainer = this.querySelector( "[data-ref-room-enemies]" )
                // Очистить контейнер:
                enemiesContainer.innerHTML = ""
                // Переключение по размеру комнаты:
                switch ( playersCount ) {
                    // Если два противника:
                    case 2:
                        // Вставить позиции игроков:
                        enemiesContainer.insertAdjacentHTML(
                            "beforeend"
                          , `<room-enemy data-position="3" data-seat="1"></room-enemy>`
                          + `<room-enemy data-position="6" data-seat="2"></room-enemy>`
                        )
                        break
                    // Если три противника:
                    case 3:
                        // Вставить позиции игроков:
                        enemiesContainer.insertAdjacentHTML(
                            "beforeend"
                          , `<room-enemy data-position="2" data-seat="1"></room-enemy>`
                          + `<room-enemy data-position="4" data-seat="2"></room-enemy>`
                          + `<room-enemy data-position="6" data-seat="3"></room-enemy>`
                        )
                        break
                    // Если четыре противника:
                    case 4:
                        // Вставить позиции игроков:
                        enemiesContainer.insertAdjacentHTML(
                            "beforeend"
                          , `<room-enemy data-position="2" data-seat="1"></room-enemy>`
                          + `<room-enemy data-position="3" data-seat="2"></room-enemy>`
                          + `<room-enemy data-position="4" data-seat="3"></room-enemy>`
                          + `<room-enemy data-position="6" data-seat="4"></room-enemy>`
                        )
                        break
                    // Если пять игроков:
                    case 5:
                        // Вставить позиции игроков:
                        enemiesContainer.insertAdjacentHTML(
                            "beforeend"
                          , `<room-enemy data-position="2" data-seat="1"></room-enemy>`
                          + `<room-enemy data-position="3" data-seat="2"></room-enemy>`
                          + `<room-enemy data-position="4" data-seat="3"></room-enemy>`
                          + `<room-enemy data-position="5" data-seat="4"></room-enemy>`
                          + `<room-enemy data-position="6" data-seat="5"></room-enemy>`
                        )
                        break
                    // Если шесть игроков:
                    case 6:
                        // Вставить позиции игроков:
                        enemiesContainer.insertAdjacentHTML(
                            "beforeend"
                          , `<room-enemy data-position="1" data-seat="1"></room-enemy>`
                          + `<room-enemy data-position="2" data-seat="2"></room-enemy>`
                          + `<room-enemy data-position="3" data-seat="3"></room-enemy>`
                          + `<room-enemy data-position="4" data-seat="4"></room-enemy>`
                          + `<room-enemy data-position="5" data-seat="5"></room-enemy>`
                          + `<room-enemy data-position="6" data-seat="6"></room-enemy>`
                        )
                        break
                }
            }
        }
        /**
         * Этот метод обновляет текущую позицию игрока за столом.
         * 
         * @param {Number} playerSeat       Позиция игрока за столом.
         * @returns undefined
         */
        #setPlayerSeat( playerSeat ) {
            // Если позиция изменена:
            if ( playerSeat !== this.#playerSeat ) {
                // Сохранить позицию:
                this.#playerSeat = playerSeat
                // Сохраняем оригинальный порядок:
                const seats     = []
                    , positions = {}
                // Для каждого места:
                for ( let index = 1; index <= this.#playersCount; index++ ) {
                    // Добавляем индекс:
                    seats.push( index )
                    // Получаем позицию:
                    positions[ index ] = this.querySelector( `room-enemy[data-seat="${ index }"]` ).dataset.position
                }
                // Считаем сдвиг:
                const shift = playerSeat - this.#playersCount
                // Считаем новые места:
                const updatedSeats = [ ...seats.slice( shift ), ...seats.slice( 0, shift ) ]
                // Обойти места:
                for ( const [ index, oldSeat ] of Object.entries( seats ) ) {
                    // Получить новое место:
                    const newSeat = updatedSeats[ index ]
                    // Получить элемент старого места:
                    const seatElement = this.querySelector( `room-enemy[data-seat="${ newSeat }"]` )
                    // Установить позицию нового места:
                    seatElement.dataset.position = positions[ oldSeat ]
                }
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-enemies", RoomEnemies )
</script>