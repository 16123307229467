/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс запроса:
import Request from "../request.mjs"

/**
 * Этот класс описывает объект запроса к эндпоинту "/room/leave".
 */
export default class RoomLeave
    extends Request
{
    /**
     * Конструктор объекта класса RoomLeave.
     * 
     * @param {Number} roomId           Идентификатор комнаты.
     */
    constructor( roomId ) {
        super( "/room/leave", "POST" )
        // Установка значений переменных объекта:
        this.body = { "room_id": roomId }
    }
}