/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
// Использовать класс объектов web api:
import Server from "./server.mjs"
// Использовать класс объектов web socket:
import Socket from "./socket.mjs"
// Использовать класс объектов сессии:
import Session from "./session.mjs"
// Использовать класс объектов роутера:
import Router from "./router.mjs"

/**
 * Этот класс описывает объект текущего клиента. 
 */
export default class Client
{
    /** @property {Component}               Корневой компонент клиента приложения. */
    #root      = undefined
    /** @property {Server}                  Экземпляр объекта описания соединения с API. */
    #server    = undefined
    /** @property {Socket}                  Экземпляр объекта подключения к веб-сокету. */
    #socket    = undefined
    /** @property {Session}                 Экземпляр объекта текущей сессии пользователя. */
    #session   = undefined
    /** @property {Router}                  Экземпляр объекта роутера приложения. */
    #router    = undefined
    /**
     * Этот геттер возвращает текущий корневой элемент приложения.
     * 
     * @returns {HTMLElement}
     */
    get root() {
        // Если корневой элемент был успешно установлен:
        if ( this.#root !== undefined ) {
            // Вернуть сохраненный элемент:
            return this.#root
        }
        // Иначе выбросить исключение:
        throw new Error(
            "Client: Попытка получить объект корневого элемента приложения до его установки."
        )
    }
    /**
     * Этот сеттер устанавливает текущий инстанс объекта соединения с API.
     * 
     * @param {Server} instance             Экземпляр объекта описания соединения с API.
     */
    set server( instance ) {
        // Установка веб API объекта на уровне объекта клиента:
        this.#server = instance
    }
    /**
     * Этот геттер возвращает текущий объект подключения к API или выбрасывает исключение,
     * если объект не был установлен для объекта клиента.
     * 
     * @returns {Server}
     */
    get server() {
        // Если объект API был успешно установлен:
        if ( this.#server !== undefined ) {
            // Вернуть инстанс объекта:
            return this.#server
        }
        // Иначе выбросить исключение:
        throw new Error(
            "Client: Попытка получить объект соединения с API до его установки."
        )
    }
    /**
     * Этот сеттер устанавливает текущий инстанс объекта соединения с Socket.
     * 
     * @param {Socket} instance             Экземпляр объекта описания соединения с Socket.
     */
    set socket( instance ) {
        // Установка вебсокет объект на уровне объекта клиента:
        this.#socket = instance
    }
    /**
     * Этот геттер возвращает текущий объект подключения к Socket или выбрасывает исключение,
     * если объект не был установлен для объекта клиента.
     * 
     * @returns {Socket}
     */
    get socket() {
        // Если объект вебсокета был успешно установлен:
        if ( this.#socket !== undefined ) {
            // Вернуть инстанс объекта:
            return this.#socket
        }
        // Иначе выбросить исключение:
        throw new Error(
            "Client: Попытка получить объект соединения с Socket до его установки."
        )
    }
    /**
     * Этот сеттер устанавливает текущий инстанс объекта сессии.
     * 
     * @param {Session} instance             Экземпляр объекта описания сессии.
     */
    set session( instance ) {
        // Установка объекта сессии на уровне объекта клиента:
        this.#session = instance
    }
    /**
     * Этот геттер возвращает текущий объект пользовательской сессии или выбрасывает исключение,
     * если объект не был установлен для объекта клиента.
     * 
     * @returns {Session}
     */
    get session() {
        // Если объект сессии был успешно установлен:
        if ( this.#session !== undefined ) {
            // Вернуть инстанс объекта:
            return this.#session
        }
        // Иначе выбросить исключение:
        throw new Error(
            "Client: Попытка получить объект сессии до его установки."
        )
    }
    /**
     * Этот сеттер устанавливает текущий инстанс объекта роутера.
     * 
     * @param {Router} instance             Экземпляр объекта описания роутера.
     */
    set router( instance ) {
        // Установка объекта роутера на уровне объекта клиента:
        this.#router = instance
    }
    /**
     * Этот геттер возвращает текущий объект пользовательской роутера или выбрасывает исключение,
     * если объект не был установлен для объекта клиента.
     * 
     * @returns {Router}
     */
    get router() {
        // Если объект роутера был успешно установлен:
        if ( this.#router !== undefined ) {
            // Вернуть инстанс объекта:
            return this.#router
        }
        // Иначе выбросить исключение:
        throw new Error(
            "Client: Попытка получить объект роутера до его установки."
        )
    }
    /**
     * Этот метод отображает корневой элемент приложения внутри переданного узла документа.
     * 
     * @param {HTMLElement} container           Контейнер корневого узла приложения.
     * @returns undefined
     */
    draw( container ) {
        // Создать корневой элемент приложения:
        const rootComponent = globalThis.document.createElement( "application-root" )
        // Установить корневой элемент для текущего клиента:
        this.#root = rootComponent
        // Вставить элемент в переданный узел документа:
        container.appendChild( rootComponent )
    }
}