<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    
    /**
     * Этот класс описывает кастомный элемент <application-overlay>.
     */
    class ApplicationOverlay
        extends Component
    {
        /** @property {HTMLElement}             Элемент отображаемого модального окна. */
        #innerComponent = undefined
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "currentOverlay" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени свойства:
            switch ( transaction.name ) {
                // Если изменен текущий оверлей:
                case "currentOverlay":
                    // Если оверлей закрыт:
                    if ( transaction.value === undefined ) {
                        // Скрыть внутренний компонент:
                        this.#hideComponent()
                    }
                    // Если экран открыт:
                    else {
                        // Открыть его внутри текущего компонента:
                        this.#showComponent( transaction.value )
                    }
                    break
            }
        }
        /**
         * Этот метод создает новый элемент и устанавливает его в качестве текущего открытого модального окна.
         *
         * @param {String} tagName              Имя тега элемента.
         * @param {Object} [attributes]         Опциональные атрибуты элемента.
         * @returns {HTMLElement}
         */
        #showComponent( tagName, attributes = {} ) {
            // Скрыть текущий внутренний компонент, если он установлен:
            this.#hideComponent()
            // Создать элемент:
            const element = globalThis.document.createElement( tagName )
            // Обойти атрибуты элемента:
            for ( const [ attributeName, attributeValue ] of Object.entries( attributes ) ) {
                // Установить атрибут:
                element.setAttribute( attributeName, attributeValue )
            }
            // Установить элемент, как открытый экран:
            this.#innerComponent = element
            // Вставить элемент в текущий узел:
            this.appendChild( element )
            // Убраем скролл страницы:
            globalThis.document.body.style.overflow = "hidden"
            globalThis.document.documentElement.style.overflow = "hidden"
            // Получаем модальное окно:
            const modalWindow = this.querySelector( ".modal" )
            // Вешаем обрабочик клика на модалку
            modalWindow.addEventListener(
                "click"
              , event => {
                    // Если элемент имеет атрибут закрытия:
                    if ( "close" in event.target.dataset ) {
                        // Закрывает модальное окно:
                        this.#hideComponent()
                    }
                }
            )
            // Открываем по короткому таймеру для проигрывания анимаций:
            globalThis.setTimeout(
                () => {
                    // Показываем модалку
                    modalWindow.classList.add( "is-active" )
                }
              , 50
            )
            // Вернуть созданный элемент компонента:
            return element
        }
        /**
         * Этот метод скрывает отображенный внутри элемента компонент.
         * 
         * @returns undefined
         */
        #hideComponent() {
            // Если компонент отображается внутри оверлея:
            if ( this.#innerComponent !== undefined ) {
                // Получаем модальное окно:
                const modalWindow = this.querySelector( ".modal" )
                // Скрываем модалку:
                modalWindow.classList.remove( "is-active" )
                // Возвращаем скролл страницы
                globalThis.document.body.style.overflow = ""
                globalThis.document.documentElement.style.overflow = ""
                // Удалить этот элемент:
                this.removeChild( this.#innerComponent )
                // Сбросить текущий внутренний компонент:
                this.#innerComponent = undefined
            }
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "application-overlay", ApplicationOverlay )
</script>