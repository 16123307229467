<!--
 Durak app.
 
 @license commerce
 @author slepozavr.ru
 -->
<!-- Шаблон элемента компонента: -->
<template id="room_controls_template">
    <div class="playing-room-panel">
        <room-hand></room-hand>
        <div class="playing-room-panel__buttons" data-ref-controls-buttons>
            <form action="/room/take" 
                  method="post"
                  data-request-disable="initiator"
                  data-request-json
                  >
                <input type="hidden" data-ref-controls-id-input name="room_id" value="">
                <button class="button_blue playing-room-panel__button_blue" data-ref-controls-button data-ref-take-button>
                    Взять
                </button>
            </form>
            <form action="/room/pass" 
                  method="post"
                  data-request-disable="initiator"
                  data-request-json
                  >
                <input type="hidden" data-ref-controls-id-input name="room_id" value="">
                <button class="button_gold playing-room-panel__button_gold" data-ref-controls-button data-ref-pass-button>
                    Пас
                </button>
            </form>
        </div>
    </div>
</template>
<!-- Модуль компонента: -->
<script type="module">
    // Использовать объект компонента приложения:
    import Component from "../modules/component.mjs"
    // Использовать подмиксовку для автоматических шаблонов:
    import { Templated } from "../modules/template.mjs"

    /**
     * Этот класс описывает компонент <room-controls>.
     */
    class RoomControls
        extends Templated( Component, "room_controls_template" )
    {
        /**
         * Этот геттер определяет свойства состояния за которыми будет следить элемент.
         * 
         * @returns {Array}
         */
        static get observedState() {
            return [ "roomId", "roomPlayer", "roomPlayer.*", "roomBoard.*" ]
        }
        /**
         * Этот метод обрабатывает изменение значения свойства объекта состояния.
         * 
         * @param {Proxy}            state          Проксированный объект состояния.
         * @param {StateTransaction} transaction    Транзакция.
         * @returns undefined
         */
        stateChangedCallback( state, transaction ) {
            // Переключение по имени свойства:
            switch ( transaction.name ) {
                // Если изменен идентификатор комнаты:
                case "roomId":
                    // Установка содержания поля ввода:
                    this.#setRoomId( transaction.value )
                    break
                // Если обновлен сам объект игрока:
                case "roomPlayer":
                // Если обновлено одно из значений:
                case "roomPlayer.active":
                case "roomPlayer.attack":
                case "roomPlayer.defend":
                case "roomPlayer.state":
                case "roomBoard.cards":
                    // Получить текущий игровой статус:
                    var game_state = state.roomPlayer?.game_state
                      , active     = state.roomPlayer?.active
                      , attack     = state.roomPlayer?.attack
                      , defend     = state.roomPlayer?.defend
                      , cards      = state.roomBoard?.cards
                    // Если статус игрока нормальный:
                    if ( game_state != "out" ) {
                        // Показать кнопки действий:
                        this.#showControlButtons()
                        // Переключение по условию:
                        switch ( true ) {
                            // Если игрок в атаке:
                            case ( active == true ) 
                              && ( attack == true )
                               :
                                // Скрыть "взять":
                                this.#hideTakeControlButton()
                                // Если есть борд и карты:
                                if ( ( cards !== undefined )
                                  && ( cards.length > 0 )
                                   ) {
                                    // Показать "пас":
                                    this.#showPassControlButton()
                                }
                                // Если нет борда:
                                else {
                                    // Сбросить пас:
                                    this.#hidePassControlButton()
                                }
                                break
                            // Если игрок в защите:
                            case ( active == true ) 
                              && ( defend == true )
                               :
                                // Скрыть "пас":
                                this.#hidePassControlButton()
                                // Показать "взять":
                                this.#showTakeControlButton()
                                break
                            // Если другое состояние:
                            default:
                                // Скрыть обе:
                                this.#hideTakeControlButton()
                                this.#hidePassControlButton()
                                break
                        }
                    }
                    // Если статус игрока не нормальный:
                    else {
                        // Скрыть кнопки действий:
                        this.#hideControlButtons()
                    }
                    break
            }
        }
        /**
         * Этот метод выполняется при подключении шаблонизированного элемента в тело документа.
         * 
         * @returns undefined
         */
        templateConnectedCallback() {
            // Скрыть кнопки по умолчанию:
            this.#hideControlButtons()
        }
        /**
         * Этот метод устанавливает идентификатор текущей комнаты.
         * 
         * @param {String} id           Идентификатор (номер) комнаты.
         * @returns undefined
         */
        #setRoomId( id ) {
            // Установка содержания поля ввода:
            for ( const input of this.querySelectorAll( "[data-ref-controls-id-input]" ) ) {
                input.value = id
            }
        }
        /**
         * Этот метод скрывает кнопки действий.
         * 
         * @returns undefined
         */
        #hideControlButtons() {
            // Получить элемент контейнера кнопок:
            const buttonContainerElement = this.querySelector( "[data-ref-controls-buttons]" )
            // Установить класс скрытого элемента:
            buttonContainerElement.classList.add( "is-hidden" )
        }
        /**
         * Этот метод скрывает кнопки действий.
         * 
         * @returns undefined
         */
        #showControlButtons() {
            // Получить элемент контейнера кнопок:
            const buttonContainerElement = this.querySelector( "[data-ref-controls-buttons]" )
            // Установить класс скрытого элемента:
            buttonContainerElement.classList.remove( "is-hidden" )
        }
        /**
         * Этот метод скрывает кнопку "взять".
         * 
         * @returns undefined
         */
        #hideTakeControlButton() {
            // Получить элемент кнопки:
            const buttonElement = this.querySelector( "[data-ref-take-button]" )
            // Снять класс скрытого элемента:
            buttonElement.classList.add( "is-hidden" )
        }
        /**
         * Этот метод показывает кнопку "взять".
         * 
         * @returns undefined
         */
        #showTakeControlButton() {
            // Получить элемент кнопки:
            const buttonElement = this.querySelector( "[data-ref-take-button]" )
            // Снять класс скрытого элемента:
            buttonElement.classList.remove( "is-hidden" )
            // Показать контейнер кнопок:
            this.#showControlButtons()
        }
        /**
         * Этот метод скрывает кнопку "бито".
         * 
         * @returns undefined
         */
        #hidePassControlButton() {
            // Получить элемент кнопки:
            const buttonElement = this.querySelector( "[data-ref-pass-button]" )
            // Снять класс скрытого элемента:
            buttonElement.classList.add( "is-hidden" )
        }
        /**
         * Этот метод показывает кнопку "бито".
         * 
         * @returns undefined
         */
        #showPassControlButton() {
            // Получить элемент кнопки:
            const buttonElement = this.querySelector( "[data-ref-pass-button]" )
            // Снять класс скрытого элемента:
            buttonElement.classList.remove( "is-hidden" )
            // Показать контейнер кнопок:
            this.#showControlButtons()
        }
    }
    // Определение элемента:
    globalThis.customElements.define( "room-controls", RoomControls )
</script>