/**
 * Durak app.
 * 
 * @license commerce
 * @author slepozavr.ru
 */
/**
 * Объект Evented реализует методы базового управления и выполнения обработки событий.
 */
export default class Evented
{
    /** @property {Map}                 Карта слушателей событий. */
    #listeners   = new Map()
    /** @property {Set}                 Список прикрепленных объектов событий. */
    #subscribers = new Set()
    /**
     * Этот метод добавляет подписчика на текущий объект событий, который будет автоматически
     * получать события текущего объекта.
     * 
     * @param {Evented} evented         Экземлпяр событейного объекта.
     * @returns undefined
     */
    subscribeEventedObject( evented ) {
        this.#subscribers.add( evented )
    }
    /**
     * Этот метод удаляет подписчика текущего объекта событий.
     * 
     * @param {Evented} evented         Экземлпяр событейного объекта.
     * @returns {Boolean}
     */
    unsubscribeEventedObject( evented ) {
        return this.#subscribers.delete( evented )
    }
    /**
     * Этот метод возвращает установленные слушатели событий по имени.
     * 
     * @param {String} eventName        Имя события.
     * @returns {Set} 
     */
    getEventListeners( eventName ) {
        // Если обработчики событий установлены:
        if ( this.#listeners.has( eventName ) == true ) {
            // Вернуть обработчики:
            return new Set( this.#listeners.get( eventName ) )
        }
        // Иначе вернуть пустой список:
        return new Set()
    }
    /**
     * Этот метод устанавливает обработчик события по имени.
     * 
     * @param {String} eventName        Имя события.
     * @param {Object} listener         Функция или объект слушателя.
     * @returns undefined
     */
    addEventListener( eventName, listener ) {
        // Если обработчики событий не устанавливались:
        if ( this.#listeners.has( eventName ) == false ) {
            // Создать контейнер списка обработчиков:
            this.#listeners.set( eventName, new Set() )
        }
        // Установить обработчик события:
        this.#listeners.get( eventName ).add( listener )
    }
    /**
     * Этот метод удаляет обработчик события.
     * 
     * @param {String} eventName        Имя события.
     * @param {Object} listener         Функция или объект слушателя.
     * @returns {Boolean}
     */
    removeEventListener( eventName, listener ) {
        // Проверить, что обработчики события установлены:
        if ( this.#listeners.has( eventName ) == true ) {
            // Удалить прослушиватель события:
            return this.#listeners.get( eventName ).delete( listener )
        }
        // Иначе вернуть false:
        return false
    }
    /**
     * Этот метод удаляет все обработчики события по имени.
     * 
     * @param {String} eventName        Имя события.
     * @returns {Boolean}
     */
    removeEventListeners( eventName ) {
        return this.#listeners.delete( eventName )
    }
    /**
     * Этот метод удаляет все обработчики события.
     * 
     * @returns {Boolean}
     */
    removeEventListeners() {
        return this.#listeners.clear()
    }
    /**
     * Этот метод выполняет обработку события на текущем объекте (и связанных объектах).
     * 
     * @param {String} eventName        Имя события.
     * @param {Object} eventData        Данные события.
     * @param {Set}    [path]           Путь выполнения события.
     * @returns undefined
     */
    runEventListeners( eventName, eventData, path = new Set() ) {
        // Если текущий объект уже присутствует в пути выполнения:
        if ( path.has( this ) == true ) {
            // Прервать выполнение события:
            return
        }
        // Добавить текущий объект в путь выполнения события:
        path.add( this )
        // Если у текущего объекта есть обработчики этого события:
        if ( this.#listeners.has( eventName ) == true ) {
            // Обработать все обработчики:
            for ( const listener of this.#listeners.get( eventName ).values() ) {
                // Если обработчик это функция:
                if ( listener instanceof Function ) {
                    // Выполнить обработчик:
                    listener.call( this, eventData )
                }
                // Если обработчик это не функция:
                else {
                    // Вызвать метод handleEvent:
                    listener.handleEvent( eventName, eventData, this )
                }
            }
        }
        // Обойти все прикрепленные объекты:
        for ( const subscriber of this.#subscribers.values() ) {
            // Выполнить обработчики на прикрепленном объекте:
            subscriber.runEventListeners( eventName, eventData, path )
        }
    }
    /**
     * Этот метод выполняет обработку события на текущем объекте (и связанных объектах).
     * 
     * @param {String} eventName        Имя события.
     * @param {Object} eventData        Данные события.
     * @returns undefined
     */
    emit( eventName, eventData ) {
        // Выполнить обработчики события:
        setTimeout( () => this.runEventListeners( eventName, eventData ) )
    }
}